/*========================
  4.4  tap to top style start
==========================*/
.scroll-to-top {
  z-index: 4;
  position: fixed;
  right: 20px;
  bottom: 30px;
  width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
  height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 10px;
  background-color: rgba(var(--dark-text), 1);
  border: none;
  font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
  transition: 0.4s ease;
  transform: scale(0);
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  [dir="rtl"] & {
    right: unset;
    left: 20px;
  }

  @media (max-width: 767px) {
    bottom: 90px;
  }

  .arrow {
    color: rgba(var(--white), 1);
  }

  &.menu-page {
    @media (max-width: 991px) {
      bottom: 70px;
    }
  }
}