/*========================
   5.7 banner style start
==========================*/
.banner-section {
  background-color: rgba(var(--box-bg), 1);

  .banner-img {
    border-radius: 12px;
    object-fit: cover;
    border: 1px solid rgba(var(--dark-text), 0.15);
  }
}

.product-banner {
  display: inline-block;

  .banner-img {
    border-radius: 12px;

    @media (max-width: 1200px) {
      display: none;
    }
  }
}