/*=============================
  5.10  Location style start
===============================*/
.location-map {
  border: 0;
  height: 100vh;
  width: 100%;
}

.delivery-root {
  position: relative;
  border: 0;
  height: 100%;
  width: 100%;
  margin-bottom: -16px;

  > div {
    height: 100%;
  }

  @media (max-width: 1199px) {
    height: 50vh;
  }
}

.location-popup {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  max-width: 600px;
  background: linear-gradient(180deg, #414449 1.31%, #1f1f1f 101.06%);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
  border-radius: 20px 20px 0px 0px;
  height: auto;
  padding: 20px;

  .change-location {
    display: flex;
    justify-content: space-between;

    h2 {
      font-weight: 500;
      color: rgba(var(--white), 1);
    }

    i {
      color: rgba(var(--theme-color), 1);
      font-size: 18px;
    }
  }

  .address {
    font-size: 14px;
    font-weight: 500;
  }

  .delivery-btn {
    font-size: 16px;
  }
}

.order-status-content {
  padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: 0;

  h4 {
    margin-bottom: 15px;
    font-weight: 500;
    color: rgba(var(--dark-text), 1);
  }

  .driver-details-box {
    display: flex;
    align-items: center;
    gap: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--theme-color), 0.05);
    border: 1px solid rgba(var(--theme-color), 0.2);
    border-radius: 6px;

    .driver-image {
      .img {
        width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
        height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
        object-fit: contain;
        border-radius: 100%;
      }
    }

    .driver-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
      width: calc(
        100% - calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320))) -
          calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
      );

      @media (max-width: 440px) {
        display: block;
      }

      .driver-info {
        @media (max-width: 440px) {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
        }

        h6 {
          font-weight: 400;
          color: rgba(var(--content-color), 1);
        }

        h5 {
          line-height: 1;
          font-weight: 500;
          color: rgba(var(--dark-text), 1);
          margin-top: 5px;
        }
      }
    }
  }

  .shipping-details {
    margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));

    h4 {
      margin-bottom: 15px;
      font-weight: 500;
      color: rgba(var(--dark-text), 1);
    }

    .delivery-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      margin-top: 10px;
      border: 1px solid rgba(var(--dashed-line), 1);
      border-radius: 4px;

      li {
        border-bottom: 1px dashed rgba(var(--dashed-line), 1);
        padding-block: calc(
          10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))
        );

        &:last-child {
          border-bottom: none;
        }

        h6 {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 400;
          color: rgba(var(--content-color), 1);
          margin-top: 3px;
        }

        h5 {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
          color: rgba(var(--dark-text), 1);
        }

        .order-address {
          display: flex;
          align-items: center;
          gap: 10px;

          .place-icon {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
}

.order-details-content {
  margin-top: 20px;

  h4 {
    margin-bottom: 15px;
    font-weight: 500;
    color: rgba(var(--dark-text), 1);
  }

  .order-content-main-box {
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    border: 1px solid rgba(var(--dark-text), 0.15);
    border-radius: 6px;

    .order-list {
      li {
        width: 100%;
        padding-block: 10px;
        border-bottom: 1px dashed rgba(var(--dark-text), 0.15);

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 10px;
          border-bottom: 1px dashed rgba(var(--dark-text), 0.15);
        }

        .order-box-content {
          display: flex;
          align-items: center;
          gap: 10px;

          .product-img {
            .img {
              width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
              height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
              object-fit: contain;
            }
          }

          .order-details {
            width: 100%;

            h6 {
              font-weight: 500;
              font-size: calc(
                14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))
              );
              color: rgba(var(--dark-text), 1);
            }

            p {
              font-weight: 400;
              font-size: 14px;
              color: rgba(var(--content-color), 1);
            }
          }
        }
      }
    }

    .total-amount {
      margin-top: 10px;

      .grand-amount {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba(var(--dark-text), 0.15);
      }
    }
  }
}
