/*========================
  1.2 Variable Style start
==========================*/
/* font family */
$poppins: "Poppins", sans-serif;
$gradient-fill: linear-gradient(
  to right,
  rgba(var(--theme-color), 1),
  rgba(var(--theme-color2), 1)
);
$light-gradient: linear-gradient(
    180deg,
    rgba(226, 232, 255, 0) 0%,
    rgba(226, 232, 255, 0.1) 100%
  ),
  rgba(226, 232, 255, 0.06);

/* color variables */
:root {
  --theme-color: 242, 169, 62;
  --theme-color2: 240, 112, 84;
  --light-theme-color: 255, 240, 227;
  --white: 255, 255, 255;
  --black: 0, 0, 0;
  --dark-text: 61, 61, 61;
  --content-color: 116, 116, 116;
  --box-bg: 249, 249, 249;
  --auth-bg: 240, 242, 241;
  --success-color: 39, 125, 42;
  --error-color: 245, 58, 58;
  --accent-color: 58, 109, 229;
  --rate-color: 255, 185, 49;
  --secondary-color: 250, 177, 69;
  --dashed-line: 232, 232, 232;
}

.dark {
  --white: 40, 40, 40;
  --black: 230, 230, 230;
  --dark-text: 230, 230, 230;
  --content-color: 215, 215, 215;
  --light-text: 163, 163, 163;
  --box-bg: 60, 60, 60;
  --dashed-line: 90, 90, 90;
}

.color-2 {
  --theme-color: 97, 56, 156;
  --theme-color2: 97, 56, 156;
}

@mixin gradient-text {
  background: $gradient-fill;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
}
