/*========================
  1.1 Animation style start
==========================*/
@keyframes fireworkLine {
  0% {
    right: 20%;
    transform: scale(0, 0);
  }

  25% {
    right: 20%;
    width: 6px;
    transform: scale(1, 1);
  }

  35% {
    right: 0;
    width: 35%;
  }

  70% {
    right: 0;
    width: 4px;
    transform: scale(1, 1);
  }

  100% {
    right: 0;
    transform: scale(0, 0);
  }
}

@keyframes fireworkPoint {
  30% {
    transform: scale(0, 0);
  }

  60% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0, 0);
  }
}

@keyframes rotating-design {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes bike {
  0% {
    margin-top: 5%;
  }

  100% {
    margin-top: 4%;
  }
}

@keyframes marquee-discount {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@keyframes marquee-discount-reverse {
  0% {
    right: 0;
  }

  100% {
    right: -100%;
  }
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes lineAnimReverse {
  0% {
    right: -40%;
  }

  50% {
    right: 20%;
    width: 60%;
  }

  100% {
    right: 100%;
    width: 100%;
  }
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 60%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

@keyframes rotateCircles {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes smoke {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50px);
  }
}

@keyframes marquee-reverse {
  0% {
    right: 0;
  }

  100% {
    right: -100%;
  }
}

@keyframes slide {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -1000px 0;
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@keyframes drop {
  0% {
    top: -50%;
  }

  100% {
    top: 110%;
  }
}

@keyframes shimmy {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 15px);
  }
}

@keyframes icofont-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
