/*-----------------------------------------------------------------------------------

    Template Name:zomo - Online Food Delivery
    Template URI: https://themes.pixelstrap.net/zomo
    Description: This is Food Ordering Html Template
    Author: Pixelstrap
    Author URL: https://themeforest.net/user/pixelstrap

----------------------------------------------------------------------------------- */
/* 1.1 animation CSS  */
/* 1.2 variable CSS  */
/* 2.1 reset CSS  */
/* 2.2 typography CSS */
/* 3.1 accordion CSS  */
/* 3.2 button CSS  */
/* 3.3 category CSS  */
/* 3.4 form CSS  */
/* 3.5 modal CSS  */
/* 3.6 nav CSS */
/* 3.7 offcanvas CSS */
/* 3.8 product-box CSS */
/* 3.9 ratio CSS */
/* 3.10 tabs CSS */
/* 3.11 swiper-slider CSS */
/* 3.12 title CSS */
/* 3.13 loader CSS */
/* 4.1 footer css */
/* 4.2 header CSS */
/* 4.3 mode-button CSS */
/* 4.4 tap-to-top CSS */
/* 5.1 about CSS */
/* 5.2 authentication CSS */
/* 5.3 blog-page CSS*/
/* 5.4 cart CSS */
/* 5.5 checkout CSS */
/* 5.6 coupon CSS */
/* 5.7 banner CSS */
/* 5.8 home CSS */
/* 5.9 menu CSS */
/* 5.10 order-tracking CSS */
/* 5.11 product-details CSS */
/* 5.12 product-page CSS */
/* 5.13 profile CSS */
/* 5.14 shop-page CSS */
/* 5.15 sub-page CSS */
/* 6.1 dark CSS */
/*utils scss files */
/*========================
  1.1 Animation style start
==========================*/
@keyframes fireworkLine {
  0% {
    right: 20%;
    transform: scale(0, 0);
  }
  25% {
    right: 20%;
    width: 6px;
    transform: scale(1, 1);
  }
  35% {
    right: 0;
    width: 35%;
  }
  70% {
    right: 0;
    width: 4px;
    transform: scale(1, 1);
  }
  100% {
    right: 0;
    transform: scale(0, 0);
  }
}
@keyframes fireworkPoint {
  30% {
    transform: scale(0, 0);
  }
  60% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
}
@keyframes rotating-design {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bike {
  0% {
    margin-top: 5%;
  }
  100% {
    margin-top: 4%;
  }
}
@keyframes marquee-discount {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes marquee-discount-reverse {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}
@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lineAnimReverse {
  0% {
    right: -40%;
  }
  50% {
    right: 20%;
    width: 60%;
  }
  100% {
    right: 100%;
    width: 100%;
  }
}
@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 60%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
@keyframes rotateCircles {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes smoke {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50px);
  }
}
@keyframes marquee-reverse {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}
@keyframes slide {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -1000px 0;
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
@keyframes shimmy {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 15px);
  }
}
@keyframes icofont-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/*========================
  1.2 Variable Style start
==========================*/
/* font family */
/* color variables */
:root {
  --theme-color: 242, 169, 62;
  --theme-color2: 240, 112, 84;
  --light-theme-color: 255, 240, 227;
  --white: 255, 255, 255;
  --black: 0, 0, 0;
  --dark-text: 61, 61, 61;
  --content-color: 116, 116, 116;
  --box-bg: 249, 249, 249;
  --auth-bg: 240, 242, 241;
  --success-color: 39, 125, 42;
  --error-color: 245, 58, 58;
  --accent-color: 58, 109, 229;
  --rate-color: 255, 185, 49;
  --secondary-color: 250, 177, 69;
  --dashed-line: 232, 232, 232;
}

.dark {
  --white: 40, 40, 40;
  --black: 230, 230, 230;
  --dark-text: 230, 230, 230;
  --content-color: 215, 215, 215;
  --light-text: 163, 163, 163;
  --box-bg: 60, 60, 60;
  --dashed-line: 90, 90, 90;
}

.color-2 {
  --theme-color: 97, 56, 156;
  --theme-color2: 97, 56, 156;
}

/* base scss files */
/*=====================
  2.1  Reset Style start
==========================*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

section,
.section-t-space {
  padding-top: calc(30px + 50 * (100vw - 320px) / 1600);
}

.section-b-space {
  padding-bottom: calc(30px + 50 * (100vw - 320px) / 1600);
}

.section-md-t-space {
  padding-top: calc(30px + 10 * (100vw - 320px) / 1600);
}

.section-md-b-space {
  padding-bottom: calc(20px + 20 * (100vw - 320px) / 1600);
}

@media (min-width: 1540px) {
  .container {
    padding: 0 20px;
    max-width: 1500px;
  }
}
.custom-container {
  padding: 0 calc(20px + 60 * (100vw - 320px) / 1600);
}

.panel-space {
  padding-top: 85px !important;
}
@media (min-width: 786px) {
  .panel-space {
    display: none;
  }
}

.theme-color {
  color: rgba(var(--theme-color), 1) !important;
}

.dark-text {
  color: rgba(var(--dark-text), 1) !important;
}

.content-color {
  color: rgba(var(--content-color), 1) !important;
}

.bg-color {
  background-color: rgba(var(--box-bg), 1) !important;
}

.bg-theme {
  background-color: rgba(var(--theme-color), 1) !important;
}

.white-bg {
  background-color: rgba(var(--white), 1) !important;
}

.auth-bg {
  background-color: rgba(var(--auth-bg), 1) !important;
}

.error-color {
  color: rgba(var(--error-color), 1) !important;
}

.success-color {
  color: rgba(var(--success-color), 1) !important;
}

.rate-color {
  color: rgba(var(--rate-color), 1) !important;
}

.error-bg {
  background-color: rgba(var(--error-color), 1) !important;
}

.secondary-bg {
  background-color: rgba(var(--secondary-color), 1) !important;
}

.theme-border {
  border: 1px solid rgba(var(--theme-color), 1) !important;
}

[dir=rtl] .swiper {
  direction: ltr;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 767px) {
  .responsive-space {
    height: calc(70px + 2 * (100vw - 320px) / 1600);
  }
}

.custom-row .col {
  width: 20%;
  flex: none;
}
@media (max-width: 1578px) {
  .custom-row .col {
    width: 25%;
  }
}
@media (max-width: 1310px) {
  .custom-row .col {
    width: 33.33%;
  }
}
@media (max-width: 1040px) and (min-width: 992px) {
  .custom-row .col {
    width: 50%;
  }
}
@media (max-width: 695px) {
  .custom-row .col {
    width: 50%;
  }
}
@media (max-width: 455px) {
  .custom-row .col {
    width: 100%;
  }
}

@media (max-width: 416px) {
  .custom-sm-100 > * {
    flex: 0 0 auto;
    width: 100%;
  }
}

[dir=rtl] [type=email],
[dir=rtl] [type=number],
[dir=rtl] [type=tel],
[dir=rtl] [type=url] {
  direction: rtl;
}

.dir-rtl {
  direction: rtl;
}
[dir=rtl] .dir-rtl {
  direction: rtl;
}

/*========================
  2.2  Typography Style start
==========================*/
body {
  position: relative;
  font-family: "Poppins", sans-serif;
  background-blend-mode: screen;
  background: rgba(var(--white), 1) !important;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  scrollbar-width: none;
  scrollbar-color: #000000;
  width: 0px;
}
body::-webkit-scrollbar-track {
  background-color: rgba(var(--box-bg), 1);
}
body::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 0px;
  border: 3px solid rgba(var(--theme-color), 1);
}
body.noice-background {
  background: url("../images/noice.jpg") !important;
}
body.noice-background::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f1eee9;
  opacity: 0.8;
  z-index: -1;
}

.space-family {
  font-family: "Space Grotesk", sans-serif;
}

h1 {
  font-weight: 700;
  font-size: calc(25px + 55 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}

h2 {
  font-weight: 600;
  font-size: calc(20px + 10 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}

h3 {
  font-weight: 500;
  font-size: calc(18px + 6 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}

h4 {
  font-weight: 400;
  font-size: calc(16px + 6 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}

h5 {
  font-weight: 400;
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  line-height: 1.5;
  margin-bottom: 0;
}

h6 {
  font-weight: 400;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  line-height: 1.5;
  margin-bottom: 0;
}

p {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin-bottom: 0;
}

li {
  list-style: none;
  display: inline-block;
  font-size: 14px;
}

.scroll-bar {
  /* Chrome, Edge, and Safari */
}
.scroll-bar {
  scrollbar-width: auto;
  scrollbar-color: rgba(var(--theme), 1);
}
.scroll-bar::-webkit-scrollbar {
  width: 3px;
}
.scroll-bar::-webkit-scrollbar-track {
  background: rgba(var(--theme), 0.3);
  border-radius: 20px;
}
.scroll-bar::-webkit-scrollbar-thumb {
  background-color: rgba(var(--theme), 1);
  border-radius: 10px;
}

/* components scss files*/
/*=====================
  3.1 accordion Style start
==========================*/
.payment-accordion .accordion-item {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  border-top: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 6px;
  border: 1px solid rgba(var(--dashed-line), 1);
  background-color: transparent;
  overflow: hidden;
}
.payment-accordion .accordion-item .accordion-header .accordion-button {
  padding: calc(5px + 10 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  line-height: 1;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  box-shadow: none;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 500;
  border-radius: 6px 6px 0 0;
}
.payment-accordion .accordion-item .accordion-header .accordion-button:focus-visible {
  outline: none;
}
.payment-accordion .accordion-item .accordion-header .accordion-button::after {
  filter: brightness(1) invert(0);
}
.payment-accordion .accordion-item .accordion-header .accordion-button.collapsed {
  background-color: rgba(var(--white), 1);
  border-bottom: none;
  border-radius: 6px;
}
.payment-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(0);
}
.payment-accordion .accordion-item .accordion-collapse:focus-visible,
.payment-accordion .accordion-item .accordion-body:focus-visible,
.payment-accordion .accordion-item form:focus-visible {
  outline: none;
}
.payment-accordion .accordion-item .accordion-body {
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.payment-accordion .accordion-item .accordion-body form .form-label {
  color: rgba(var(--content-color), 1);
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 500;
}
.payment-accordion .accordion-item .accordion-body form .form-control {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 400;
  background-color: rgba(var(--box-bg), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
  padding: 10px 10px;
}
.payment-accordion .accordion-item .accordion-body form .form-control:focus {
  box-shadow: none;
}
.payment-accordion .accordion-item .accordion-body form .form-control::placeholder {
  color: rgb(136, 136, 136);
}
.payment-accordion .accordion-item .accordion-body form .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: calc(10px + 10 * (100vw - 320px) / 1600) 10px;
  width: 100%;
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  margin-top: 24px;
}
.payment-accordion .accordion-item .accordion-body .card-list li {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: rgba(var(--box-bg), 1);
  padding: calc(8px + 7 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.payment-accordion .accordion-item .accordion-body .card-list li:first-child {
  margin-top: 0;
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse .form-check-label {
  display: flex;
  align-items: center;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  width: 100%;
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse .form-check-label .img {
  width: calc(20px + 10 * (100vw - 320px) / 1600);
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse .form-check-label .card-name {
  font-weight: 400;
  font-size: calc(15px + 1 * (100vw - 320px) / 1600);
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse .form-check-label .card-name span {
  font-weight: 500;
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse .form-check-input {
  width: calc(18px + 7 * (100vw - 320px) / 1600);
  height: calc(18px + 7 * (100vw - 320px) / 1600);
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(var(--dashed-line), 1);
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse .form-check-input:checked {
  background-color: rgba(var(--white), 1);
  border-color: rgba(var(--dashed-line), 1);
  background-image: none;
  width: calc(18px + 7 * (100vw - 320px) / 1600);
  height: calc(18px + 7 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-accordion .accordion-item .accordion-body .card-list li .form-check-reverse .form-check-input:checked::after {
  content: "\eb7c";
  font-family: remixicon !important;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 5 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
  margin-top: 1px;
}

.help-accordion .accordion-item {
  border: 1px solid rgba(var(--dashed-line), 1);
  border: none;
  background: transparent;
  margin-top: 10px;
}
.help-accordion .accordion-item:first-child {
  margin-top: 0;
}
.help-accordion .accordion-item .accordion-header {
  border-radius: 0;
}
.help-accordion .accordion-item .accordion-header .accordion-button {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--dashed-line), 1);
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 0;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  box-shadow: none;
  border-radius: 6px 6px 0 0;
  background-color: rgba(var(--white), 1);
}
.help-accordion .accordion-item .accordion-header .accordion-button.collapsed {
  border-radius: 6px;
}
.help-accordion .accordion-item .accordion-header .accordion-button::after {
  margin-left: auto;
  margin-right: 0;
}
[dir=rtl] .help-accordion .accordion-item .accordion-header .accordion-button::after {
  margin-left: 0;
  margin-right: auto;
}
.help-accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}
.help-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  filter: grayscale(1);
}
.help-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):focus {
  box-shadow: none;
}
.help-accordion .accordion-item .accordion-body {
  text-align: justify;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  line-height: 1.5;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
  border-top: none;
  border-radius: 0 0 6px 6px;
  background-color: rgba(var(--white), 1);
}
.help-accordion .accordion-item .accordion-body ul {
  padding-inline-start: 10px;
}
.help-accordion .accordion-item .accordion-body ul li {
  display: list-item;
  list-style-type: decimal;
  padding-bottom: 10px;
  margin-left: 10px;
  text-align: justify;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  line-height: 1.5;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .help-accordion .accordion-item .accordion-body ul li {
  margin-left: 0;
  margin-right: 10px;
}

.sidebar-accordion {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.sidebar-accordion .accordion-item {
  width: 100%;
  background: transparent;
  border: none;
}
.sidebar-accordion .accordion-item .accordion-header .accordion-button {
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
  color: rgba(var(--dark-text), 1);
  box-shadow: none;
}
.sidebar-accordion .accordion-item .accordion-header .accordion-button::after {
  filter: invert(1) grayscale(1) brightness(0);
}
.sidebar-accordion .accordion-item .accordion-header.collapsed {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  box-shadow: none;
}
.sidebar-accordion .accordion-item .accordion-header::after {
  filter: invert(1) grayscale(1) brightness(0);
}
.sidebar-accordion .accordion-item .accordion-body {
  padding: 15px 0 0;
  background-color: rgba(var(--white), 1);
}

/*=====================
  3.2  Button Style start
==========================*/
.btn {
  padding: calc(8px + 2 * (100vw - 320px) / 1600) calc(10px + 15 * (100vw - 320px) / 1600);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 500;
  white-space: nowrap;
}
.btn:active, .btn:focus-visible {
  border-color: transparent !important;
  color: #fff !important;
}
.btn.btn-sm {
  padding: calc(4px + 3 * (100vw - 320px) / 1600) calc(10px + 8 * (100vw - 320px) / 1600);
}
.btn-inline {
  padding-inline: 15px;
}

.theme-btn {
  color: #fff;
  background-color: rgba(var(--theme-color), 1);
  border-radius: 4px;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  border: none;
  border-radius: 100px;
}
.theme-btn:active {
  color: rgba(var(--white), 1) !important;
  background-color: rgba(var(--theme-color), 1) !important;
}
.theme-btn:hover {
  color: #fff;
  background-color: rgba(var(--theme-color), 1);
}

.theme-outline {
  border: 1px solid rgba(var(--theme-color), 1);
  color: rgba(var(--theme-color), 1);
  border-radius: 100px;
  transition: 0.4s ease;
}
.theme-outline:hover {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  color: #fff;
}

.gray-btn {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--content-color), 1);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.gray-btn:hover {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--light-text), 1);
}

.border-btn {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: 1px solid rgba(var(--theme-color), 1);
  border-radius: 4px;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: rgba(var(--light-text), 1);
}
.btn-link:hover {
  color: rgba(var(--light-text), 1);
}

/*=========================
  3.3  Category Style start
==========================*/
.categories-section {
  position: relative;
}
.categories-section .category-top {
  background-color: rgba(var(--white), 1);
  z-index: 1;
  padding: calc(15px + 5 * (100vw - 320px) / 1600);
  border-radius: 13px;
  box-shadow: 0 15px 60px -20px rgba(0, 0, 0, 0.1);
  margin-top: calc(-45px + -25 * (100vw - 320px) / 1600);
}
.categories-section .item-2 {
  position: absolute;
  top: 30px;
  right: -40px;
}
@media (max-width: 1250px) {
  .categories-section .item-2 {
    display: none;
  }
}
.categories-section .categories-style {
  position: relative;
  margin-top: -10px;
}
.categories-section .categories-style .food-categories {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: calc(10px + 10 * (100vw - 320px) / 1600) calc(8px + 7 * (100vw - 320px) / 1600);
  margin-top: 10px;
  border-radius: 8px;
  background-color: rgba(var(--box-bg), 1);
  transition: 0.4s ease;
  border: 1px solid transparent;
}
.categories-section .categories-style .food-categories:hover {
  transform: translateY(-5px);
  transition: 0.4s ease;
  background: rgba(var(--theme-color), 0.08);
  border: 1px solid rgba(var(--theme-color), 0.3);
}
.categories-section .categories-style .food-categories:hover h4 {
  color: rgba(var(--theme-color), 1) !important;
  transition: 0.4s ease;
  font-weight: 500;
}
@media (max-width: 420px) {
  .categories-section .categories-style .food-categories {
    height: 100%;
    width: 100%;
  }
}
.categories-section .categories-style .food-categories.food {
  background: rgba(var(--white), 1);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.categories-section .categories-style .food-categories .categories-img {
  width: 100%;
  object-fit: contain;
  height: 55px;
}
.categories-section .categories-style .food-categories h4 {
  display: block;
  margin-top: 10px;
  transition: 0.4s ease;
}
.categories-section .categories-style h6 {
  color: rgba(var(--dark-text), 1);
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.categories-section .categories-style.category-inline .food-categories {
  align-items: center;
  gap: calc(8px + 2 * (100vw - 320px) / 1600);
}
.categories-section .categories-style.category-inline .food-categories .categories-img {
  object-fit: contain;
  height: calc(35px + 10 * (100vw - 320px) / 1600);
  width: calc(35px + 10 * (100vw - 320px) / 1600);
}
.categories-section .categories-style.category-inline .food-categories h4 {
  display: block;
  margin-top: 0;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 500;
}
.categories-section.category-outer .categories-style .food-categories {
  margin-top: calc(30px + 20 * (100vw - 320px) / 1600);
  padding-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.categories-section.category-outer .categories-style .food-categories .categories-img {
  margin-top: -40px;
  margin-bottom: 0px;
}

.scooter-img {
  position: absolute;
  top: calc(-30px + -163 * (100vw - 320px) / 1600);
  z-index: 12;
  left: 40px;
  width: 9%;
  animation: 1s linear infinite alternate bike;
  z-index: 1;
}
[dir=rtl] .scooter-img {
  left: unset;
  right: 40px;
  transform: scaleX(-1);
}

.popcorn-img {
  position: absolute;
  top: -40px;
  z-index: 1;
  left: 0;
}
[dir=rtl] .popcorn-img {
  right: 0;
  left: unset;
  transform: scaleX(-1);
}
@media (max-width: 1299px) {
  .popcorn-img {
    display: none;
  }
}

/*=====================
  3.4  form Style start
==========================*/
.auth-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
[dir=rtl] .auth-form {
  text-align: right;
}
.auth-form h2 {
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
  margin-top: -6px;
}
.auth-form h5 {
  margin-top: 5px;
  margin-bottom: calc(20px + 20 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.auth-form p {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1600);
}
[dir=rtl] .auth-form p {
  text-align: right;
}
.auth-form .form-label {
  color: rgba(var(--content-color), 1);
}
.auth-form .form-input {
  position: relative;
  width: 100%;
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
}
.auth-form .form-input i {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 24px;
  padding-right: 10px;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .auth-form .form-input i {
  padding-left: 10px;
  padding-right: unset;
  left: unset;
  right: 15px;
}
.auth-form .form-input .form-control {
  color: rgba(var(--content-color), 1);
  background-color: rgba(var(--box-bg), 1);
  border: none;
  box-shadow: none;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  padding: 20px 20px 20px 60px;
}
[dir=rtl] .auth-form .form-input .form-control {
  padding: 20px 60px 20px 20px;
  text-align: right;
}
.auth-form .form-input .form-control:focus {
  box-shadow: none;
  border-color: none;
}
.auth-form .form-input .form-control::placeholder {
  color: rgb(136, 136, 136);
}
.auth-form .form-input .form-control.search {
  width: 596px;
  padding: calc(10px + 7 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600) calc(10px + 7 * (100vw - 320px) / 1600) calc(40px + 10 * (100vw - 320px) / 1600);
  border-radius: 4px;
  background-color: rgb(17, 17, 17);
  color: rgba(var(--white), 1);
  background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, 0.1) 100%), rgba(226, 232, 255, 0.06);
  border: 1px solid rgba(226, 232, 255, 0.1);
  border-radius: 100px;
}
[dir=rtl] .auth-form .form-input .form-control.search {
  padding: calc(10px + 7 * (100vw - 320px) / 1600) calc(40px + 10 * (100vw - 320px) / 1600) calc(10px + 7 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
}
.auth-form .form-input .form-control.search::placeholder {
  color: rgba(var(--white), 0.6);
}
@media (max-width: 991px) {
  .auth-form .form-input .form-control.search {
    width: 100%;
  }
}

.otp-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: unset;
  flex-direction: column;
  width: 90%;
}
[dir=rtl] .otp-form {
  text-align: right;
}
.otp-form h2 {
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
}
.otp-form h6 {
  margin-bottom: 5px;
  color: rgba(var(--content-color), 1);
  font-weight: 400;
  margin-top: 10px;
}
.otp-form .otp-number {
  color: rgb(61, 61, 61);
  font-weight: 500;
}
@media (max-width: 600px) {
  .otp-form {
    gap: calc(10px + 10 * (100vw - 320px) / 1600);
  }
}
.otp-form .form-input::-webkit-outer-spin-button, .otp-form .form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-form .form-input .form-control {
  background: rgba(var(--box-bg), 1);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  border: none;
  color: rgba(var(--dark-text), 1);
  text-align: center;
  height: calc(40px + 10 * (100vw - 320px) / 1600);
  width: calc(50px + 40 * (100vw - 320px) / 1600);
  padding: calc(5px + 10 * (100vw - 320px) / 1600);
}
.otp-form .form-input .form-control::placeholder {
  color: rgb(136, 136, 136);
}
.otp-form .form-input .form-control:focus {
  box-shadow: none;
  border: 1px solid rgba(var(--theme-color), 1);
}

.profile-form {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding-top: 20px;
}

.contact-detail {
  margin-bottom: 30px;
}
.contact-detail .contact-detail-box {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: rgba(var(--white), 1);
  padding: 14px;
  border-radius: 8px;
}
.contact-detail .contact-detail-box .contact-detail-title h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
  color: rgba(var(--dark-text), 1);
}
.contact-detail .contact-detail-box .contact-detail-contain p {
  font-size: 15px;
  color: rgba(var(--content-color), 1);
}
.contact-detail .contact-detail-box .contact-icon {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  padding: 13px;
  border-radius: 7px;
  display: inline-flex;
  color: #fff;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.contact-form {
  background-color: rgba(var(--white), 1);
  padding: calc(20px + 20 * (100vw - 320px) / 1600);
}
.contact-form .form-label {
  margin-top: calc(5px + 10 * (100vw - 320px) / 1600);
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.contact-form .form-control {
  border: none;
  box-shadow: none;
  padding: calc(6px + 6 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
}
[dir=rtl] .contact-form .form-control {
  text-align: right;
}
.contact-form .form-control:focus {
  box-shadow: none;
  border: none;
}
.contact-form .form-control::placeholder {
  color: rgb(136, 136, 136);
}
.contact-form .buttons {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-top: calc(10px + 10 * (100vw - 320px) / 1600);
  border-top: 1px dashed rgba(var(--dashed-line), 1);
}

/*=====================
  3.5  modal Style start
==========================*/
.address-details-modal .modal-dialog .modal-content .modal-header {
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.address-details-modal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  color: rgba(var(--dark-text), 1);
}
.address-details-modal .modal-dialog .modal-content .modal-header .btn-close {
  box-shadow: none;
}
.address-details-modal .modal-dialog .modal-content .modal-body p {
  font-weight: 400;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
}
.address-details-modal .modal-dialog .modal-content .modal-body .form-label {
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.address-details-modal .modal-dialog .modal-content .modal-body .form-control {
  padding: calc(10px + 2 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--dashed-line), 1);
  color: rgba(var(--dark-text), 0.8);
}
.address-details-modal .modal-dialog .modal-content .modal-body .form-control:focus {
  box-shadow: none;
}
.address-details-modal .modal-dialog .modal-content .modal-body .form-control::placeholder {
  color: rgba(var(--content-color), 1);
}
.address-details-modal .modal-dialog .modal-content .modal-footer {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
}

.modal-open {
  padding-right: 0 !important;
}

.location-modal .modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: rgba(var(--dashed-line), 1);
}
.location-modal .modal-header .modal-title {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--white), 1);
}
.location-modal .modal-header .modal-title i {
  font-size: 20px;
}
.location-modal .modal-header .modal-title .btn-close {
  margin-left: auto;
}
.location-modal .modal-header .modal-title .btn-close:focus {
  box-shadow: none;
}
.location-modal .modal-body {
  padding: calc(15px + 5 * (100vw - 320px) / 1600) 15px;
}
.location-modal .modal-body .search-section .form_search input {
  width: 100%;
  background-color: rgba(var(--box-bg), 1);
  border: none;
  padding: calc(10px + 2 * (100vw - 320px) / 1600);
  line-height: 1;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 5px;
}
.location-modal .modal-body .search-section .form_search input:focus-visible {
  outline: none;
}
.location-modal .modal-body .current-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(15px + 5 * (100vw - 320px) / 1600) 0 calc(10px + 5 * (100vw - 320px) / 1600);
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.location-modal .modal-body .current-location .current-address {
  display: flex;
  gap: 10px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
}
.location-modal .modal-body .current-location .current-address .focus {
  font-weight: 600;
  color: rgba(var(--theme-color), 1);
}
.location-modal .modal-body .current-location .current-address h5 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 600;
  line-height: 1;
  color: rgba(var(--theme-color), 1);
}
.location-modal .modal-body .current-location .current-address h6 {
  width: 100%;
  font-size: 14px;
  margin-top: 4px;
  color: rgba(var(--content-color), 1);
}
.location-modal .modal-body .current-location .arrow {
  font-size: 20px;
  color: rgba(var(--dark-text), 1);
}
.location-modal .modal-body .recent-title {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
}
.location-modal .modal-body .recent-location {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 0 0;
}
.location-modal .modal-body .recent-location .location-icon {
  background-color: rgba(var(--theme-color), 1);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: rgba(var(--white), 1);
}
.location-modal .modal-body .recent-location .location-icon i {
  color: rgb(255, 255, 255);
}
.location-modal .modal-body .recent-location .recant-address {
  display: flex;
  gap: 10px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
}
.location-modal .modal-body .recent-location .recant-address h5 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  line-height: 1;
  color: rgba(var(--dark-text), 1);
}
.location-modal .modal-body .recent-location .recant-address h6 {
  margin-top: 4px;
  font-size: 14px;
  color: rgba(var(--content-color), 1);
}
.location-modal .modal-body .recent-location .modal-footer {
  border-color: rgba(var(--dashed-line), 1);
}

/*=====================
  3.6  nav Style start
==========================*/
.navbar-style-1 {
  border-top: 1px solid rgba(var(--dark-text), 0.15);
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.navbar-style-1 .navbar {
  padding: 12px 0;
}
.navbar-style-1 .navbar .navbar-toggler {
  padding: 0;
  border: 0;
}
.navbar-style-1 .navbar .navbar-toggler .navbar-toggler-icon {
  width: 30px;
  height: 30px;
  color: rgba(var(--dark-text), 0.3);
  border: 1px solid rgba(var(--dark-text), 0.3);
  border-radius: 6px;
  margin-right: calc(10px + 10 * (100vw - 320px) / 1600);
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
[dir=rtl] .navbar-style-1 .navbar .navbar-toggler .navbar-toggler-icon {
  margin-left: calc(10px + 10 * (100vw - 320px) / 1600);
  margin-right: unset;
}
.navbar-style-1 .navbar .navbar-toggler .navbar-toggler-icon i {
  padding: 3px;
}
.navbar-style-1 .navbar .navbar-toggler:focus {
  box-shadow: none;
}
@media (max-width: 767px) {
  .navbar-style-1 .navbar .navbar-collapse {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    padding: 10px 15px;
    margin-top: 10px;
    color: rgba(var(--white), 1);
    background-color: rgba(var(--white), 1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  [dir=rtl] .navbar-style-1 .navbar .navbar-collapse {
    left: unset;
    right: 0;
  }
}
@media (min-width: 767px) {
  .navbar-style-1 .navbar .navbar-collapse {
    display: flex;
    justify-content: flex-start;
  }
}
.navbar-style-1 .navbar .navbar-collapse.show .navbar-nav {
  margin-left: 0;
}
[dir=rtl] .navbar-style-1 .navbar .navbar-collapse.show .navbar-nav {
  margin-left: unset;
  margin-right: 0;
}
.navbar-style-1 .navbar .navbar-nav {
  display: flex;
  gap: calc(5px + 25 * (100vw - 320px) / 1600);
}
.navbar-style-1 .navbar .navbar-nav .nav-item .nav-link {
  color: rgba(var(--content-color), 1);
  font-weight: 400;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
  padding: 0;
  white-space: nowrap;
}
.navbar-style-1 .navbar .navbar-nav .nav-item .nav-link.active {
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
}
.navbar-style-1 .navbar .special-deal {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  padding: 10px 15px;
  margin-left: auto;
  color: rgba(var(--error-color), 1);
  background-color: rgba(var(--error-color), 0.1);
  border-radius: 4px;
  white-space: nowrap;
}

.navbar-style-2 {
  background-color: rgba(var(--theme-color), 1);
}
.navbar-style-2 .navbar {
  padding: 12px 0;
}
.navbar-style-2 .navbar .navbar-toggler {
  padding: 0;
  border: 0;
}
.navbar-style-2 .navbar .navbar-toggler .navbar-toggler-icon {
  width: auto;
  height: auto;
  color: rgba(var(--white), 0.3);
  border: 1px solid rgba(var(--white), 0.3);
  border-radius: 6px;
  margin-right: calc(10px + 10 * (100vw - 320px) / 1600);
  background-image: none;
}
[dir=rtl] .navbar-style-2 .navbar .navbar-toggler .navbar-toggler-icon {
  margin-left: calc(10px + 10 * (100vw - 320px) / 1600);
  margin-right: unset;
}
.navbar-style-2 .navbar .navbar-toggler .navbar-toggler-icon i {
  padding: 3px;
}
.navbar-style-2 .navbar .navbar-toggler:focus {
  box-shadow: none;
}
@media (max-width: 992px) {
  .navbar-style-2 .navbar .navbar-collapse {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    padding: 10px 15px;
    margin-top: 40px;
    color: rgba(var(--white), 1);
    background-color: rgba(var(--theme-color), 1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  [dir=rtl] .navbar-style-2 .navbar .navbar-collapse {
    left: unset;
    right: 0;
  }
}
@media (min-width: 992px) {
  .navbar-style-2 .navbar .navbar-collapse {
    display: flex;
    justify-content: flex-start !important;
  }
}
.navbar-style-2 .navbar .navbar-collapse.show .navbar-nav {
  margin-left: 0;
}
[dir=rtl] .navbar-style-2 .navbar .navbar-collapse.show .navbar-nav {
  margin-left: unset;
  margin-right: 0;
}
.navbar-style-2 .navbar .navbar-nav {
  display: flex;
  gap: calc(5px + 25 * (100vw - 320px) / 1600);
}
.navbar-style-2 .navbar .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
  padding: 0;
  white-space: nowrap;
}
.navbar-style-2 .navbar .navbar-nav .nav-item .nav-link.active {
  color: rgba(var(--white), 1);
  font-weight: 500;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
}
.navbar-style-2 .navbar .special-deal {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  padding: 10px 15px;
  margin-left: auto;
  color: rgba(var(--error-color), 1);
  background-color: rgba(var(--error-color), 0.1);
  border-radius: 4px;
  white-space: nowrap;
}
[dir=rtl] .navbar-style-2 .navbar .special-deal {
  margin-left: unset;
  margin-right: auto;
}
@media (max-width: 767px) {
  .navbar-style-2 .navbar .special-deal {
    display: none;
  }
}
.navbar-style-2 .navbar .hot-deals {
  white-space: nowrap;
  padding: 0 calc(12px + 12 * (100vw - 320px) / 1600) 0 0;
}
[dir=rtl] .navbar-style-2 .navbar .hot-deals {
  padding: 0 0 0 calc(12px + 12 * (100vw - 320px) / 1600);
}
.navbar-style-2 .navbar .supports {
  white-space: nowrap;
  padding-left: calc(12px + 12 * (100vw - 320px) / 1600);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
[dir=rtl] .navbar-style-2 .navbar .supports {
  padding-left: unset;
  padding-right: calc(12px + 12 * (100vw - 320px) / 1600);
  border-left: unset;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.navbar-style-2 .navbar .supports h5 {
  font-weight: 400;
}
.navbar-style-2 .navbar .supports h6 {
  font-weight: 400;
}

.mobile-menu {
  position: fixed;
  bottom: 0;
  padding: calc(10px + 5 * (100vw - 320px) / 1600) 0 calc(10px + 5 * (100vw - 320px) / 1600) 0;
  width: 100%;
  background-color: rgba(var(--white), 1);
  box-shadow: 0 -3px 10px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  z-index: 2;
}
.mobile-menu ul {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .mobile-menu ul {
    width: 100%;
  }
}
.mobile-menu ul li {
  width: 100%;
  text-align: center;
}
.mobile-menu ul li.active {
  transition: 0.3s ease;
  position: relative;
}
.mobile-menu ul li.active .menu-box {
  color: rgba(var(--theme-color), 1);
}
.mobile-menu ul li.active .menu-box i {
  color: rgba(var(--theme-color), 1);
}
.mobile-menu ul li::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 4px;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(var(--white), 1);
  border-radius: 6px;
}
.mobile-menu ul li .menu-box {
  color: rgba(var(--dark-text), 1);
  transition: 0.5s ease;
  text-decoration: none;
  display: grid;
}
.mobile-menu ul li .menu-box i {
  display: inline-block;
  margin: 0 auto;
  font-size: calc(20px + 6 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}

.navbar .nav-item {
  position: relative;
}
.navbar .dropdown-toggle::after {
  content: "\ea4e";
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  border: none;
}
.navbar .dropdown-menu {
  background-color: rgba(var(--white), 1);
  box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.08);
}
@media (max-width: 991px) {
  .navbar .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
  }
}
.navbar .dropdown-menu li {
  display: block;
}
.navbar .dropdown-menu li:focus a, .navbar .dropdown-menu li:hover a {
  background-color: transparent;
  color: rgba(var(--theme-color2), 1);
}
.navbar .dropdown-menu li:focus a::after, .navbar .dropdown-menu li:hover a::after {
  width: 20px;
}
.navbar .dropdown-menu li .dropdown-item {
  transition: 0.4s ease;
  font-size: 16px;
  color: rgba(var(--dark-text), 1);
}
.navbar .dropdown-menu li .dropdown-item::after {
  content: "";
  width: 0;
  height: 1px;
  background: rgba(var(--theme-color2), 1);
  transition: 0.4s ease;
  display: block;
}
.navbar .dropdown-menu li .dropdown-item.active {
  background-color: transparent;
  color: rgba(var(--theme-color2), 1);
}
.navbar .dropdown-menu li .dropdown-item.active::after {
  width: 20px;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.mega-menu {
  position: unset !important;
}
.mega-menu .dropdown-menu .demo-box {
  transition: 0.5s ease;
  display: block;
}
.mega-menu .dropdown-menu .demo-box:hover {
  transform: translateY(-5px);
}
.mega-menu .dropdown-menu .demo-box:hover .dropdown-item {
  color: rgba(var(--theme-color2), 1);
}
@media (min-width: 991px) {
  .mega-menu:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transition: 0.5s ease;
    transform: translateY(10px);
  }
}
.mega-menu .dropdown-menu img {
  border-radius: 10px;
  border: calc(8px + 4 * (100vw - 991px) / 929) solid #f7f7f7;
}
.mega-menu .dropdown-menu .dropdown-item {
  text-align: center;
  padding: calc(2px + 4 * (100vw - 991px) / 929) 0 0 0;
  transition: 0.5s ease;
  font-size: calc(15px + 2 * (100vw - 991px) / 929);
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
@media (min-width: 991px) {
  .mega-menu .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: 0.4s ease;
    transform: translateY(20px);
    width: 100%;
    left: 0;
    position: absolute;
    padding: calc(15px + 10 * (100vw - 991px) / 929);
  }
}

@media (min-width: 991px) {
  ul.navbar-nav li > ul.dropdown-menu {
    opacity: 0;
    visibility: hidden;
    display: block;
    transition: 0.5s ease;
    transform: translateY(20px);
    box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.08);
  }
  ul.navbar-nav li:hover > ul.dropdown-menu {
    opacity: 1;
    visibility: visible;
    transition: 0.4s ease;
    transform: translateY(10px);
  }
}
/*=====================
  3.7 offcanvas Style start
==========================*/
.customized-modal .filter-header {
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.customized-modal .filter-header .title {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
  margin-top: 0;
}
.customized-modal .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: rgba(var(--dark-text), 1);
  box-shadow: none;
}
[dir=rtl] .customized-modal .btn-close {
  left: 20px;
  right: unset;
}
.customized-modal .filter-body {
  padding: 0;
}
.customized-modal .filter-body .filter-title h5 {
  margin-top: 15px;
}
.customized-modal .filter-body .filter-list {
  margin-top: 10px;
  border-bottom: 1.5px dashed rgba(var(--dark-text), 0.15);
}
.customized-modal .filter-body .filter-list li {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.customized-modal .filter-body .filter-list li .product-size {
  color: rgba(var(--dark-text), 1);
}
.customized-modal .filter-body .filter-list li .product-price {
  margin-left: auto;
  display: flex;
}
[dir=rtl] .customized-modal .filter-body .filter-list li .product-price {
  margin-left: unset;
  margin-right: auto;
}
.customized-modal .filter-body .filter-list li .product-price .form-check-label {
  margin-right: 30px;
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .customized-modal .filter-body .filter-list li .product-price .form-check-label {
  margin-right: unset;
  margin-left: 30px;
}
.customized-modal .filter-body .filter-list li .product-price .form-check-input {
  border: 1px solid rgba(var(--dark-text), 0.15);
  box-shadow: none;
  padding-right: 0;
}
[dir=rtl] .customized-modal .filter-body .filter-list li .product-price .form-check-input {
  padding-right: unset;
  padding-left: 0;
}
.customized-modal .filter-body .filter-list li .product-price .form-check-input:checked {
  background-color: rgba(var(--theme-color), 1);
  border: none;
}
.customized-modal .filter-footer {
  padding: 20px 0;
  padding-bottom: 20px;
}

.profile-modal .modal-dialog .modal-content .modal-header {
  border-bottom-color: rgba(var(--dashed-line), 1);
}
.profile-modal .modal-dialog .modal-content .modal-header .modal-title {
  font-weight: 500;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.profile-modal .modal-dialog .modal-content .modal-body .form-group .form-label {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.profile-modal .modal-dialog .modal-content .modal-body .form-group .form-control {
  background-color: rgba(var(--box-bg), 1);
  box-shadow: none;
  border-color: rgba(var(--dashed-line), 1);
  padding: calc(10px + 2 * (100vw - 320px) / 1600);
}
.profile-modal .modal-dialog .modal-content .modal-body .form-group .form-control:focus {
  border-color: rgba(var(--dashed-line), 1);
}
.profile-modal .modal-dialog .modal-content .modal-body .form-group .form-control::placeholder {
  color: rgba(var(--content-color), 1);
}
.profile-modal .modal-dialog .modal-content .modal-footer {
  padding-top: 0;
  border-top: none;
}

.order-details-modal .modal-dialog .modal-content .modal-header {
  border-bottom-color: rgba(var(--dashed-line), 1);
}
.order-details-modal .modal-dialog .modal-content .modal-header .btn-close:focus {
  box-shadow: none;
}
.order-details-modal .modal-dialog .modal-content .modal-header .modal-title {
  font-weight: 500;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-details-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-details-box .order-icon .icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-details-box .order-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-details-box .order-content .order-deliver-label {
  color: rgba(var(--success-color), 1);
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--success-color), 1);
  padding: 5px 10px;
  border-radius: 33px;
  font-size: 14px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-address p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(var(--dark-text), 1);
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-address .deliver-place {
  margin-top: 10px;
  border: 0;
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
@media (max-width: 450px) {
  .order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going {
    display: block;
  }
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin-top: 10px;
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 4px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list li {
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  padding-block: 10px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list li:last-child {
  border-bottom: none;
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list li h6 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list li h5 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list li .order-address {
  display: flex;
  align-items: center;
  gap: 5px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list li .order-address .place-icon {
  width: 25px;
  height: 25px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list li .delivery-place {
  padding-left: 30px;
}
[dir=rtl] .order-details-modal .modal-dialog .modal-content .modal-body .delivery-on-going .delivery-list li .delivery-place {
  padding-left: unset;
  padding-right: 30px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-title {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  margin-right: 0;
  margin-bottom: calc(5px + 5 * (100vw - 320px) / 1600);
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .order-details-modal .modal-dialog .modal-content .modal-body .order-title {
  margin-left: 0;
  margin-right: unset;
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-list {
  margin-top: 10px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-list li {
  width: 100%;
  margin-bottom: 10px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-list li .order-content-box h6 {
  font-weight: 500;
  font-size: 16px;
  color: rgba(var(--dark-text), 1);
}
.order-details-modal .modal-dialog .modal-content .modal-body .order-list li .order-content-box p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(var(--content-color), 1);
}
.order-details-modal .modal-dialog .modal-content .modal-body .total-amount {
  margin-top: 5px;
}
.order-details-modal .modal-dialog .modal-content .modal-body .total-amount .grand-amount {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid rgba(var(--dashed-line), 1);
}

/*=====================
  3.8  Product-box Style start
==========================*/
.product-section {
  background-color: rgba(var(--box-bg), 1);
}
.product-section .product-box {
  position: relative;
  border: 1px solid rgba(var(--black), 0.12);
  border-radius: 8px;
  overflow: visible;
}
.product-section .product-box:hover {
  border: 1px solid rgba(var(--theme-color), 1);
}
.product-section .product-box:hover .product-box-detail .bottom-panel {
  opacity: 1;
  visibility: visible;
}
.product-section .product-box-bg {
  padding: 20px;
  background-color: rgba(var(--white), 1);
}
.product-section .product-box-bg a {
  border-radius: 8px;
  margin-bottom: 8px;
}
.product-section .product-box-bg .product-box-detail {
  background-color: transparent !important;
  padding: 0 !important;
}
.product-section .product-box .product-box-img {
  display: block;
}
.product-section .product-box .product-box-detail h5 {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product-section .product-box .product-box-detail .product-content {
  color: rgba(var(--content-color), 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product-section .product-box .product-box-detail .rating-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
}
.product-section .product-box .product-box-detail .rating-price .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.product-section .product-box .product-box-detail .rating-price .star i {
  color: rgb(225, 225, 225);
  font-size: 12px;
}
.product-section .product-box .product-box-detail .bottom-panel {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -33px;
  background-color: #fff;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-section .product-box .product-box-detail .bottom-panel:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100%;
  padding: 1px;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 35%, rgb(252, 128, 25) 50%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.product-section .product-box .product-box-detail .bottom-panel .price {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}
.product-section .product-box .product-box-detail .bottom-panel .cart {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(var(--theme-color), 0.2);
  border-radius: 100%;
}
.product-section .product-box .product-box-detail .bottom-panel .cart i {
  color: rgba(var(--theme-color), 1);
  font-size: 20px;
}

.vertical-product-box {
  padding: 12px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 10px;
  transition: 0.4s ease;
  position: relative;
  overflow: hidden;
}
[dir=rtl] .vertical-product-box {
  direction: rtl;
}
.vertical-product-box .details-list {
  margin-top: 4px;
}
.vertical-product-box .details-list li {
  color: rgba(var(--content-color), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1.6;
  overflow: hidden;
}
.vertical-product-box .details-list li + li {
  margin-top: 3px;
}
.vertical-product-box .details-list li:last-child {
  border-top: 1px dashed rgba(var(--dashed-line), 1);
  padding-top: 7px;
  margin-top: 7px;
}
.vertical-product-box .details-list li .marquee-discount {
  display: flex;
  align-items: center;
  gap: 15px;
  white-space: nowrap;
  position: relative;
  transition: 0.4s ease;
}
.vertical-product-box .details-list li .marquee-discount li + li {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease;
}
.vertical-product-box .details-list li .marquee-discount .discount-info {
  color: rgba(var(--theme-color2), 1);
}
.vertical-product-box .details-list li .marquee-discount .discount-info i {
  color: rgba(var(--theme-color2), 1);
  animation: animName 2s linear infinite;
}
.vertical-product-box.product-style-2 {
  padding: 0;
}
.vertical-product-box.product-style-2 .seller-badge {
  right: 11px;
  top: 11px;
}
.vertical-product-box.product-style-2 .vertical-product-box-img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vertical-product-box.product-style-2 .vertical-product-body {
  padding: 12px;
  position: relative;
  padding-top: 22px;
}
.vertical-product-box .brand-label {
  position: absolute;
  border: 0;
  width: 60px;
  height: 60px;
  background-color: rgba(var(--white), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 10px;
  top: -40px;
  box-shadow: 2px 6px 5px #e9e9e9;
}
.vertical-product-box .brand-label img {
  border-radius: 100%;
}
.vertical-product-box .seller-badge {
  border-radius: 33px;
  position: absolute;
  z-index: 1;
  right: 22px;
  top: 21px;
  border-radius: 4px;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  padding: 5px calc(5px + 5 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  gap: 3px;
}
[dir=rtl] .vertical-product-box .seller-badge {
  right: unset;
  left: 22px;
}
.vertical-product-box .seller-badge.exclusive-badge {
  background: linear-gradient(to right, #00ad91, #007a66);
}
.vertical-product-box .seller-badge.new-badge {
  background: linear-gradient(to right, #3b6797, #223c58);
}
.vertical-product-box .seller-badge .badge {
  display: block;
  padding: 0;
  width: 15px;
}
.vertical-product-box .seller-badge h6 {
  font-size: calc(14px + 1 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: #fff;
}
.vertical-product-box .seller-badge.wishlist-icon .fill-icon {
  font-size: calc(16px + 6 * (100vw - 320px) / 1600);
}
.vertical-product-box .vertical-product-box-img {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}
.vertical-product-box .vertical-product-box-img .bg-size {
  transform: scale(1);
  transition: 0.4s ease;
}
.vertical-product-box .vertical-product-box-img .wishlist-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: rgba(var(--white), 1);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--theme-color), 1);
  border-radius: 6px;
}
[dir=rtl] .vertical-product-box .vertical-product-box-img .wishlist-close {
  right: unset;
  left: 10px;
}
.vertical-product-box .vertical-product-box-img .wishlist-close i {
  font-size: 18px;
  line-height: 1;
}
.vertical-product-box .vertical-product-box-img .offers {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: #fff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 43.87%, rgba(0, 0, 0, 0) 94.57%);
  width: 100%;
}
[dir=rtl] .vertical-product-box .vertical-product-box-img .offers {
  left: unset;
  right: 0;
}
.vertical-product-box .vertical-product-box-img .offers h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: #fff;
}
.vertical-product-box .vertical-product-box-img .offers h4 {
  font-weight: 600;
  color: #fff;
}
.vertical-product-box .vertical-product-box-img .offers .seller-badge {
  border-radius: 33px;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  padding: 5px calc(5px + 5 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  gap: 3px;
}
.vertical-product-box .vertical-product-box-img .offers .seller-badge .badge {
  display: block;
  padding: 0;
}
.vertical-product-box .vertical-product-box-img .offers .seller-badge h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: white;
}
.vertical-product-box .vertical-product-box-img .offers .seller-badge.wishlist-icon .fill-icon {
  font-size: calc(16px + 6 * (100vw - 320px) / 1600);
}
.vertical-product-box .vertical-product-body .vertical-product-title {
  color: rgba(var(--dark-text), 1);
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  font-weight: 600;
  line-height: normal;
  transition: 0.4s ease;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.vertical-product-box .vertical-product-body .rating-star {
  padding-bottom: 6px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(var(--dark-text), 1);
  margin-bottom: 0;
}
.vertical-product-box .vertical-product-body .rating-star .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.vertical-product-box .vertical-product-body .rating-star .star i {
  color: #fff;
  font-size: 12px;
}
.vertical-product-box .vertical-product-body .product-items {
  color: rgba(var(--content-color), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  line-height: 1;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  margin-top: 4px;
  padding-bottom: calc(8px + 8 * (100vw - 320px) / 1600);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.vertical-product-box .vertical-product-body .location-distance {
  flex-wrap: wrap;
  gap: calc(8px + 2 * (100vw - 320px) / 1600);
}
.vertical-product-box .vertical-product-body .location-distance .place {
  position: relative;
  padding-left: 12px;
  color: rgba(var(--dark-text), 1);
  border-bottom: none;
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
[dir=rtl] .vertical-product-box .vertical-product-body .location-distance .place {
  padding-left: unset;
  padding-right: 12px;
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .vertical-product-box .vertical-product-body .location-distance .place {
    max-width: 56px;
  }
}
@media (max-width: 768px) and (min-width: 576px) {
  .vertical-product-box .vertical-product-body .location-distance .place {
    max-width: 56px;
  }
}
.vertical-product-box .vertical-product-body .location-distance .place::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--black), 1);
  border-radius: 100%;
}
[dir=rtl] .vertical-product-box .vertical-product-body .location-distance .place::after {
  left: unset;
  right: 0;
}
.vertical-product-box .vertical-product-body .location-distance .distance {
  display: flex;
}
.vertical-product-box .vertical-product-body .location-distance .distance li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  padding: 0px calc(8px + 7 * (100vw - 320px) / 1600);
  border-right: 1px solid rgba(var(--black), 0.12);
}
[dir=rtl] .vertical-product-box .vertical-product-body .location-distance .distance li {
  border-left: 1px solid rgba(var(--black), 0.12);
  border-right: unset;
}
.vertical-product-box .vertical-product-body .location-distance .distance li:first-child {
  padding-left: 0;
}
[dir=rtl] .vertical-product-box .vertical-product-body .location-distance .distance li:first-child {
  padding-right: 0;
  padding-left: 15px;
}
.vertical-product-box .vertical-product-body .location-distance .distance li:last-child {
  padding-right: 0;
  border-right: none;
}
[dir=rtl] .vertical-product-box .vertical-product-body .location-distance .distance li:last-child {
  padding-left: 0;
  padding-right: 15px;
  border-left: none;
  border-right: unset;
}
.vertical-product-box .vertical-product-body .location-distance .distance li .icon {
  height: calc(18px + 2 * (100vw - 320px) / 1600);
  width: calc(18px + 2 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
  color: #fff;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  border-radius: 100%;
}
.vertical-product-box.product-style-3 {
  padding: 0;
  border: none;
  border-radius: 15px;
  box-shadow: none !important;
}
.vertical-product-box.product-style-3 .seller-badge {
  left: 16px;
  bottom: 127px;
  top: unset;
  right: unset;
}
.vertical-product-box.product-style-3 .vertical-product-body {
  padding: 12px;
}
.vertical-product-box.product-style-3 .vertical-product-body .rating-star {
  padding-bottom: 0;
}
.vertical-product-box.product-style-3 .vertical-product-body .product-items {
  margin-top: 7px;
  padding-bottom: calc(6px + 6 * (100vw - 320px) / 1600);
}
.vertical-product-box.product-style-3 .vertical-product-box-img {
  border-radius: 15px;
}
.vertical-product-box.product-style-3 .vertical-product-box-img .offers {
  bottom: unset;
  top: 0;
  background: none;
  text-align: right;
  z-index: 1;
  padding: calc(4px + 6 * (100vw - 320px) / 1600);
}
[dir=rtl] .vertical-product-box.product-style-3 .vertical-product-box-img .offers {
  text-align: left;
}
.vertical-product-box.product-style-3 .vertical-product-box-img .offers h4,
.vertical-product-box.product-style-3 .vertical-product-box-img .offers h6 {
  color: rgba(var(--dark-text), 1);
}
.vertical-product-box.product-style-3 .vertical-product-box-img .offers img {
  position: absolute;
  right: 0px;
  top: 0px;
  width: calc(100px + 40 * (100vw - 320px) / 1600);
  z-index: -1;
}
[dir=rtl] .vertical-product-box.product-style-3 .vertical-product-box-img .offers img {
  right: unset;
  left: 0;
  transform: scaleX(-1);
}
.vertical-product-box:hover {
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
}
.vertical-product-box:hover .vertical-product-box-img .bg-size {
  transform: scale(1.1);
  transition: 0.4s ease;
}
.vertical-product-box:hover .vertical-product-body .details-list .marquee-discount {
  animation: marquee-discount 8s linear infinite;
}
[dir=rtl] .vertical-product-box:hover .vertical-product-body .details-list .marquee-discount {
  animation: marquee-discount-reverse 8s linear infinite;
}
.vertical-product-box:hover .vertical-product-body .details-list .marquee-discount li + li {
  opacity: 1;
  visibility: visible;
}
.vertical-product-box.clip-path-box {
  padding: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
}
.vertical-product-box.clip-path-box .clip-path-shape {
  clip-path: polygon(84% 0, 100% 7%, 100% 100%, 0 100%, 0 0);
  width: 100%;
  height: 100%;
  background-color: red;
  padding: 1px;
}
.vertical-product-box.clip-path-box .vertical-product-box-img {
  clip-path: polygon(83% 0, 100% 12%, 100% 100%, 0 100%, 0 0);
  border-radius: 0;
}
.vertical-product-box.clip-path-box .vertical-product-body {
  padding: 15px 15px 15px;
  background-color: rgba(var(--white), 1);
}

.vertical-product-box-2 {
  display: flex;
  align-items: center;
  border: 1px solid rgba(var(--black), 0.05);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  height: 100%;
}
.vertical-product-box-2 .like-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
[dir=rtl] .vertical-product-box-2 .like-icon {
  right: unset;
  left: 5px;
}
.vertical-product-box-2 .like-icon i {
  padding: 5px;
  color: rgba(var(--error-color), 1);
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-product-box-2 .like-icon .fill-icon {
  display: none;
}
.vertical-product-box-2 .like-icon.active .outline-icon {
  display: none;
}
.vertical-product-box-2 .like-icon.active .fill-icon {
  display: flex;
  color: rgba(var(--error-color), 1);
}
.vertical-product-box-2 .like-icon.active .effect:before {
  animation: fireworkLine 0.5s linear 0.1s;
}
.vertical-product-box-2 .like-icon.active .effect:after {
  animation: fireworkPoint 0.5s linear 0.1s;
}
.vertical-product-box-2 .vertical-box-img {
  position: relative;
  width: 40%;
  height: 100%;
  border-radius: 12px 0 0 12px;
  overflow: hidden;
}
[dir=rtl] .vertical-product-box-2 .vertical-box-img {
  border-radius: 0 12px 12px 0;
}
.vertical-product-box-2 .vertical-box-img .img {
  height: 100%;
  object-fit: cover;
}
.vertical-product-box-2 .vertical-box-img .offers {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: rgba(var(--white), 1);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.83) 43.87%, rgba(0, 0, 0, 0) 94.57%);
  width: 100%;
}
.vertical-product-box-2 .vertical-box-img .offers h2 {
  font-size: 16px;
  color: rgba(var(--white), 1);
}
.vertical-product-box-2 .vertical-box-img .offers h6 {
  color: rgba(var(--white), 1);
}
[dir=rtl] .vertical-product-box-2 .vertical-box-img .offers {
  left: unset;
  right: 0;
}
.vertical-product-box-2 .vertical-box-details {
  width: 60%;
}
.vertical-product-box-2 .vertical-box-details .vertical-box-head {
  padding: 10px 10px 0 10px;
}
.vertical-product-box-2 .vertical-box-details .vertical-box-head .restaurant {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vertical-product-box-2 .vertical-box-details .vertical-box-head .restaurant h5 {
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.vertical-product-box-2 .vertical-box-details .vertical-box-head .restaurant h6 {
  padding: calc(2px + 3 * (100vw - 320px) / 1600) calc(5px + 5 * (100vw - 320px) / 1600);
  border-radius: 33px;
  display: flex;
  align-items: center;
  gap: calc(3px + 2 * (100vw - 320px) / 1600);
  font-size: 13px;
  font-weight: 600;
  color: rgba(var(--accent-color), 1);
  background-color: rgba(var(--accent-color), 0.08);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.vertical-product-box-2 .vertical-box-details .vertical-box-head .restaurant h6 i {
  font-size: 12px;
  line-height: 1;
  display: flex;
}
.vertical-product-box-2 .vertical-box-details .vertical-box-head h5 {
  color: rgba(var(--dark-text), 1);
}
.vertical-product-box-2 .vertical-box-details .food-items {
  border: none;
  padding: 0;
  margin-top: 5px;
  color: rgba(var(--light-text), 1);
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
[dir=rtl] .vertical-product-box-2 .vertical-box-details .food-items {
  text-align: right;
}
.vertical-product-box-2 .vertical-box-details .rating-star {
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  padding-bottom: 6px;
  text-align: left;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .vertical-product-box-2 .vertical-box-details .rating-star {
  text-align: right;
}
.vertical-product-box-2 .vertical-box-details .rating-star .star {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.vertical-product-box-2 .vertical-box-details .rating-star .star i {
  color: rgba(var(--white), 1);
}
.vertical-product-box-2 .vertical-box-details .location-distance {
  display: flex;
  gap: 4px;
  padding: 5px 10px;
}
.vertical-product-box-2 .vertical-box-details .location-distance .location {
  position: relative;
  width: 60%;
  text-align: left;
  border-right: 1px solid rgba(var(--dashed-line), 1);
  margin-right: calc(5px + 10 * (100vw - 320px) / 1600);
  padding-right: calc(5px + 10 * (100vw - 320px) / 1600);
}
[dir=rtl] .vertical-product-box-2 .vertical-box-details .location-distance .location {
  text-align: right;
  border-right: unset;
  margin-right: unset;
  padding-right: unset;
  border-left: 1px solid rgba(var(--dashed-line), 1);
  margin-left: calc(5px + 10 * (100vw - 320px) / 1600);
  padding-left: calc(5px + 10 * (100vw - 320px) / 1600);
}
.vertical-product-box-2 .vertical-box-details .location-distance .location .place {
  position: relative;
  text-align: left;
  padding: 6px;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .vertical-product-box-2 .vertical-box-details .location-distance .location .place {
  text-align: right;
}
.vertical-product-box-2 .vertical-box-details .location-distance .location .place::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(var(--dashed-line), 1);
  width: 80%;
  height: 1px;
}
[dir=rtl] .vertical-product-box-2 .vertical-box-details .location-distance .location .place::before {
  left: unset;
  right: 0;
}
.vertical-product-box-2 .vertical-box-details .location-distance .location .place::after {
  content: "";
  position: absolute;
  left: 0;
  top: 45%;
  transform: translate(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--black), 1);
  border-radius: 100%;
}
[dir=rtl] .vertical-product-box-2 .vertical-box-details .location-distance .location .place::after {
  left: unset;
  right: -5px;
}
.vertical-product-box-2 .vertical-box-details .location-distance .location h6 {
  color: rgba(var(--dark-text), 1);
}
.vertical-product-box-2 .vertical-box-details .location-distance .distance {
  position: relative;
  width: 40%;
  border: none;
}
.vertical-product-box-2 .vertical-box-details .location-distance .distance::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  width: 1px;
  height: 40%;
  border-left: 1px dashed rgba(var(--theme-color), 1);
  transform: translateY(-50%);
}
[dir=rtl] .vertical-product-box-2 .vertical-box-details .location-distance .distance::after {
  left: unset;
  right: 6px;
}
.vertical-product-box-2 .vertical-box-details .location-distance .distance ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 10px;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}

.pharmacy-product-box {
  position: relative;
  border-radius: 4px;
  background: rgba(var(--white), 1);
  width: 100%;
  overflow: hidden;
  border: 1px solid transparent !important;
}
[dir=rtl] .pharmacy-product-box {
  direction: rtl;
}
.pharmacy-product-box > div,
.pharmacy-product-box a {
  overflow: hidden;
}
.pharmacy-product-box:hover {
  border: 1px solid rgba(var(--theme-color), 1);
}
.pharmacy-product-box:hover .pharmacy-product-img {
  transform: scale(1.1);
}
.pharmacy-product-box:hover .pharmacy-product-details .add-btn1 {
  color: rgba(var(--white), 1);
  background-color: rgba(var(--theme-color), 1);
  display: block;
  white-space: nowrap;
}
.pharmacy-product-box:hover .pharmacy-product-details .add-btn1 .icon-white {
  padding-right: 5px;
}
[dir=rtl] .pharmacy-product-box:hover .pharmacy-product-details .add-btn1 .icon-white {
  padding-left: 5px;
}
@media (max-width: 375px) {
  .pharmacy-product-box:hover .pharmacy-product-details .add-btn1 .icon-white {
    display: none;
  }
}
.pharmacy-product-box:hover .pharmacy-product-details .add-btn {
  display: none;
}
.pharmacy-product-box .offer-tag {
  position: absolute;
  top: 12px;
  right: 12px;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  color: rgba(var(--error-color), 1);
  background-color: rgba(var(--error-color), 0.15);
  border-radius: 10px;
  z-index: 1;
}
@media (max-width: 576px) {
  .pharmacy-product-box .offer-tag {
    display: none;
  }
}
[dir=rtl] .pharmacy-product-box .offer-tag {
  right: 12px;
  left: unset;
}
.pharmacy-product-box .offer-tag.wo-bg {
  left: 12px;
  background-color: transparent;
  z-index: 1;
}
[dir=rtl] .pharmacy-product-box .offer-tag.wo-bg {
  right: 12px;
  left: unset;
}
.pharmacy-product-box .pharmacy-product-img {
  position: relative;
  width: 100%;
  background-color: rgba(var(--box-bg), 1);
  transition: 0.4s ease;
  overflow: hidden;
  cursor: pointer;
}
.pharmacy-product-box .pharmacy-product-img.bg-img {
  transform: scale(1);
}
.pharmacy-product-box .pharmacy-product-img .img {
  width: 100%;
  height: calc(150px + 150 * (100vw - 320px) / 1600);
  object-fit: contain;
  padding: calc(15px + 20 * (100vw - 320px) / 1600);
}
.pharmacy-product-box .pharmacy-product-img .timer {
  position: absolute;
  bottom: -20px;
  right: 10px;
  text-align: center;
  padding: 5px;
  color: rgba(var(--white), 1);
  background-color: rgba(var(--error-color), 1);
}
[dir=rtl] .pharmacy-product-box .pharmacy-product-img .timer {
  right: unset;
  left: 10px;
}
@media (max-width: 425px) {
  .pharmacy-product-box .pharmacy-product-img .timer {
    display: none;
  }
}
.pharmacy-product-box .pharmacy-product-img .timer h5 {
  font-weight: 500;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
}
.pharmacy-product-box .pharmacy-product-img .timer h6 {
  font-size: 12px;
  font-weight: 500;
}
.pharmacy-product-box .pharmacy-product-details {
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
}
.pharmacy-product-box .pharmacy-product-details .product-name {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pharmacy-product-box .pharmacy-product-details h6 {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pharmacy-product-box .pharmacy-product-details .price {
  padding-top: 0;
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
}
.pharmacy-product-box .pharmacy-product-details .price span {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.pharmacy-product-box .pharmacy-product-details .rating {
  display: flex;
  align-items: center;
  gap: 5px;
}
.pharmacy-product-box .pharmacy-product-details .rating h6 {
  font-weight: 500;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.pharmacy-product-box .pharmacy-product-details .add-btn {
  color: rgba(var(--theme-color), 1);
  background-color: rgba(var(--theme-color), 0.1);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pharmacy-product-box .pharmacy-product-details .add-btn .icon {
  padding-right: 5px;
}
@media (max-width: 375px) {
  .pharmacy-product-box .pharmacy-product-details .add-btn .icon {
    display: none;
  }
}
[dir=rtl] .pharmacy-product-box .pharmacy-product-details .add-btn .icon {
  padding-right: unset;
  padding-left: 5px;
}
.pharmacy-product-box .pharmacy-product-details .add-btn1 {
  display: none;
}
.pharmacy-product-box .pharmacy-product-details .plus-minus {
  border: 1px solid rgba(var(--dark-text), 0.15);
  padding: 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: rgba(var(--box-bg), 1);
  text-align: center;
}
.pharmacy-product-box .pharmacy-product-details .plus-minus input {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: calc(15px + 20 * (100vw - 320px) / 1600);
  text-align: center;
}
.pharmacy-product-box .pharmacy-product-details .plus-minus i {
  color: rgba(var(--dark-text), 1);
}
.pharmacy-product-box .pharmacy-product-details .plus-minus i.sub {
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
  cursor: pointer;
}
[dir=rtl] .pharmacy-product-box .pharmacy-product-details .plus-minus i.sub {
  padding-right: unset;
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
  border-right: unset;
}
.pharmacy-product-box .pharmacy-product-details .plus-minus i.add {
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
  cursor: pointer;
}
[dir=rtl] .pharmacy-product-box .pharmacy-product-details .plus-minus i.add {
  padding-left: unset;
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
  border-left: unset;
}

.restaurant-wrapper .restaurant-img {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}
.restaurant-wrapper .restaurant-img a {
  transition: all 0.5s ease;
}
.restaurant-wrapper .offers {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: #fff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 43.87%, rgba(0, 0, 0, 0) 94.57%);
  width: 100%;
}
[dir=rtl] .restaurant-wrapper .offers {
  left: unset;
  right: 0;
}
.restaurant-wrapper .offers h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: #fff;
}
.restaurant-wrapper .offers h4 {
  font-weight: 600;
  color: #fff;
}
.restaurant-wrapper .offers .seller-badge {
  border-radius: 33px;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  padding: 5px calc(5px + 5 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  gap: 3px;
}
.restaurant-wrapper .offers .seller-badge .badge {
  display: block;
  padding: 0;
}
.restaurant-wrapper .offers .seller-badge h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--white), 1);
}
.restaurant-wrapper .offers .seller-badge.wishlist-icon .fill-icon {
  font-size: calc(16px + 6 * (100vw - 320px) / 1600);
}
.restaurant-wrapper .restaurant-content {
  text-align: center;
  padding-top: calc(12px + 8 * (100vw - 320px) / 1600);
}
.restaurant-wrapper .restaurant-content h4 {
  color: rgba(var(--dark-text), 1);
  font-size: calc(16px + 6 * (100vw - 320px) / 1600);
  font-weight: 600;
  transition: 0.4s ease;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.restaurant-wrapper .restaurant-content h5 {
  color: rgba(var(--content-color), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  line-height: 1;
  border-bottom: none;
  margin-top: calc(5px + 3 * (100vw - 320px) / 1600);
  padding-bottom: calc(8px + 5 * (100vw - 320px) / 1600);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.restaurant-wrapper .restaurant-content .restaurant-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  text-transform: uppercase;
  color: #999;
}
.restaurant-wrapper .restaurant-content .restaurant-details li {
  position: relative;
}
.restaurant-wrapper .restaurant-content .restaurant-details li + li {
  padding-left: 20px;
}
[dir=rtl] .restaurant-wrapper .restaurant-content .restaurant-details li + li {
  padding-left: 0;
  padding-right: 20px;
}
.restaurant-wrapper .restaurant-content .restaurant-details li + li::after {
  content: "";
  position: absolute;
  left: -3px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 1px;
  background-color: rgba(var(--black), 1);
}
[dir=rtl] .restaurant-wrapper .restaurant-content .restaurant-details li + li::after {
  left: unset;
  right: -3px;
}
.restaurant-wrapper:hover .restaurant-img a {
  transform: scale(1.1);
}
.restaurant-wrapper:hover .restaurant-content a h4 {
  color: rgba(var(--theme-color2), 1);
}

/*=====================
  3.9  Ratio Style start
==========================*/
.ratio_30 .bg-size:before {
  padding-top: 30%;
  content: "";
  display: block;
}

.ratio_40 .bg-size:before {
  padding-top: 40%;
  content: "";
  display: block;
}

.ratio_45 .bg-size:before {
  padding-top: 45%;
  content: "";
  display: block;
}

.ratio50 .bg-size:before {
  padding-top: 50%;
  content: "";
  display: block;
}

.ratio_55 .bg-size:before {
  padding-top: 55%;
  content: "";
  display: block;
}

.ratio2_3 .bg-size:before {
  padding-top: 60%;
  content: "";
  display: block;
}

.ratio3_2 .bg-size:before {
  padding-top: 66.66%;
  content: "";
  display: block;
}

.ratio_landscape .bg-size:before {
  padding-top: 75%;
  content: "";
  display: block;
}

.ratio_square .bg-size:before {
  padding-top: 100%;
  content: "";
  display: block;
}

.ratio_110 .bg-size:before {
  padding-top: 110%;
  content: "";
  display: block;
}

.ratio_asos .bg-size:before {
  padding-top: 127.7777778%;
  content: "";
  display: block;
}

.ratio_portrait .bg-size:before {
  padding-top: 150%;
  content: "";
  display: block;
}

.ratio1_2 .bg-size:before {
  padding-top: 200%;
  content: "";
  display: block;
}

.b-top {
  background-position: top !important;
}

.b-bottom {
  background-position: bottom !important;
}

.b-center {
  background-position: center !important;
}

.b-left {
  background-position: left !important;
}

.b-right {
  background-position: right !important;
}

.b_size_content {
  background-size: contain !important;
}

/*=====================
  3.10 tab Style start
==========================*/
.tab-style1 {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  border-bottom: none;
  overflow-x: auto;
  background-color: rgba(var(--white), 1);
}
.tab-style1 .nav-item {
  color: rgba(var(--content-color), 1);
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 0;
  border-bottom: 1px solid rgba(var(--white), 0.1);
}
.tab-style1 .nav-item .nav-link {
  width: 100%;
  font-weight: 400;
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  white-space: nowrap;
  color: rgba(var(--content-color), 1);
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid rgba(var(--box-bg), 0.1);
  padding: calc(10px + 10 * (100vw - 320px) / 1600) calc(20px + 15 * (100vw - 320px) / 1600);
}
.tab-style1 .nav-item .nav-link.active {
  font-weight: 500;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid rgba(var(--theme-color), 1);
  border-radius: 0;
}
.tab-style1 .nav-item .nav-link:hover {
  border-left: none;
  border-right: none;
  border-top: none;
}

.tab-style2 {
  display: flex;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  flex-wrap: nowrap;
  width: 100%;
  margin: 30px auto 0;
  border-radius: 6px;
  overflow: auto;
}
.tab-style2.restaurant-tab {
  justify-content: end;
}
.tab-style2.restaurant-tab .nav-item {
  white-space: nowrap;
}
@media (max-width: 992px) {
  .tab-style2.restaurant-tab {
    justify-content: flex-start;
  }
  [dir=rtl] .tab-style2.restaurant-tab {
    justify-content: flex-end;
  }
}
@media (max-width: 768px) {
  [dir=rtl] .tab-style2.restaurant-tab {
    justify-content: flex-start;
  }
}
.tab-style2 .nav-item {
  border-radius: 10px;
  background-color: transparent;
}
.tab-style2 .nav-item .nav-link {
  color: rgba(var(--content-color), 1);
  font-weight: 400;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
  border: 1px solid rgba(var(--content-color), 0.3);
}
.tab-style2 .nav-item .nav-link.active {
  background-color: transparent;
  color: rgba(var(--theme-color), 1);
  border: 1px solid rgba(var(--theme-color), 1);
}

.tab-style3 {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  border-bottom: none;
  overflow-x: auto;
}
@media (max-width: 1199px) {
  .tab-style3 {
    margin-top: 40px;
  }
}
.tab-style3 .nav-item {
  color: rgba(var(--content-color), 1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  cursor: pointer;
  border-radius: 0px;
  transition: all 0.3s ease;
  border-radius: 0;
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.tab-style3 .nav-item .nav-link {
  width: 100%;
  padding-top: 0;
  font-weight: 400;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  white-space: nowrap;
  color: rgba(var(--light-text), 1);
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.tab-style3 .nav-item .nav-link:hover {
  border-left: none;
  border-right: none;
  border-top: none;
}
.tab-style3 .nav-item .nav-link.active {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid rgba(var(--theme-color), 1);
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0;
  width: 100%;
  font-weight: 500;
}

/*========================
   3.11 Swiper custom Style start
==========================*/
.theme-arrow {
  position: relative;
}
.theme-arrow .swiper-button-next,
.theme-arrow .swiper-button-prev {
  position: absolute;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
  border: 1px solid rgba(var(--dashed-line), 1);
  bottom: 42px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.07));
  z-index: 1;
  height: calc(32px + 10 * (100vw - 320px) / 1600);
  width: calc(32px + 10 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease;
}
.theme-arrow .swiper-button-next::after,
.theme-arrow .swiper-button-prev::after {
  font-family: remixicon !important;
  font-size: 24px;
}
.theme-arrow .swiper-button-next:hover,
.theme-arrow .swiper-button-prev:hover {
  background-color: rgba(var(--theme-color), 1);
  color: #fff;
  transition: 0.4s ease;
  box-shadow: none;
}
.theme-arrow .swiper-button-next {
  right: 0px;
  top: -50px;
}
[dir=rtl] .theme-arrow .swiper-button-next {
  right: unset;
  left: calc(40px + 10 * (100vw - 320px) / 1600);
}
@media (max-width: 767px) {
  .theme-arrow .swiper-button-next {
    top: -60px;
  }
}
@media (max-width: 493px) {
  .theme-arrow .swiper-button-next {
    top: -65px;
  }
}
@media (max-width: 465px) {
  .theme-arrow .swiper-button-next {
    top: -75px;
  }
}
.theme-arrow .swiper-button-next::after {
  content: "\ea6e";
}
.theme-arrow .swiper-button-prev {
  top: -50px;
  right: 50px;
  left: unset;
}
[dir=rtl] .theme-arrow .swiper-button-prev {
  right: unset;
  left: 0;
}
@media (max-width: 767px) {
  .theme-arrow .swiper-button-prev {
    top: -60px;
  }
}
@media (max-width: 493px) {
  .theme-arrow .swiper-button-prev {
    top: -65px;
    right: 40px;
  }
}
@media (max-width: 465px) {
  .theme-arrow .swiper-button-prev {
    top: -75px;
  }
}
.theme-arrow .swiper-button-prev::after {
  content: "\ea64";
}

/*=====================
  3.12  Title Style start
==========================*/
.title {
  margin-bottom: calc(20px + 10 * (100vw - 320px) / 1600);
  margin-top: 11px;
  position: relative;
}
.title.w-border {
  padding-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.title.title-sm h2 {
  font-size: 24px;
}
.title.restaurant-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 768px) {
  .title.restaurant-title h2 {
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .title.restaurant-title {
    display: block;
  }
}
.title.restaurant-title .loader-line {
  width: 90px;
  height: 2px;
  overflow: hidden;
  background-color: #eee;
  border-radius: 20px;
  position: absolute;
  top: -10px;
}
.dark .title.restaurant-title .loader-line {
  background-color: #424242;
}
.title.restaurant-title .loader-line::before {
  content: "";
  position: absolute;
  width: 90px;
  height: 3px;
  top: 0px;
  left: -60px;
  background-color: rgba(var(--box-bg), 1);
}
[dir=rtl] .title.restaurant-title .loader-line::before {
  left: unset;
  right: 0;
}
.title .d-flex > div:first-child {
  width: 70%;
}
@media (max-width: 500px) {
  .title .d-flex > div:first-child {
    width: 100%;
  }
}
.title .dropdown .language-btn {
  justify-content: space-between;
  padding: 10px;
  gap: calc(10px + 30 * (100vw - 320px) / 1600);
  border-radius: 6px;
  background: rgba(var(--white), 0.06);
  backdrop-filter: blur(2px);
  color: rgba(var(--white), 1);
}
.title .dropdown .coupon-btn {
  justify-content: space-between;
  padding: 10px;
  gap: calc(10px + 30 * (100vw - 320px) / 1600);
  border-radius: 6px;
  background-color: rgba(var(--white), 1);
  color: rgba(var(--dark-text), 1);
  border: 1px solid rgba(var(--black), 0.12);
}
.title .dropdown .dropdown-menu {
  width: 100%;
  background-color: rgb(55, 55, 55);
}
.title .dropdown .dropdown-menu.coupon-menu {
  padding: 0 calc(5px + 5 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
}
.title .dropdown .dropdown-menu.coupon-menu li .dropdown-item {
  color: rgba(var(--dark-text), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  padding: 10px 0;
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
}
.title .dropdown .dropdown-menu.coupon-menu li .dropdown-item:hover {
  background-color: rgba(var(--white), 0.08);
}
.title .dropdown .dropdown-menu.show {
  display: block;
}
.title .dropdown .dropdown-menu li {
  width: 100%;
}
.title .dropdown .dropdown-menu li .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px 10px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--white), 1);
}
.title .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: rgba(var(--white), 0.08);
}
.title .tab-style2 {
  margin-bottom: -3px !important;
}
.title .form-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: calc(10px + 30 * (100vw - 320px) / 1600);
  border-radius: 6px;
  background: rgba(var(--white), 0.06);
  backdrop-filter: blur(2px);
  color: rgba(var(--white), 1);
  border: none;
}
.title .form-select.coupons {
  width: 170px;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--dark-text), 0.15);
  border-radius: 4px;
}
.title .form-select.coupons option {
  background-color: rgba(var(--white), 1);
  width: 100%;
  border: none;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.title .form-select.coupons option:hover {
  background-color: rgba(var(--box-bg), 1);
}
.title .form-select:focus {
  box-shadow: none;
  border: none;
}
.title h2 {
  position: relative;
  color: rgba(var(--dark-text), 1);
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 5px;
}
.title h3 {
  position: relative;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.title h6 {
  font-weight: 400;
  letter-spacing: 0.32px;
  margin-top: 4px;
  color: rgba(var(--content-color), 1);
}
.title p {
  width: 100%;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
@media (max-width: 768px) {
  .title p {
    width: 100%;
  }
}
.title .sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}
.title .sub-title h5 {
  font-weight: 400;
  color: rgba(var(--content-color), 1);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.title .sub-title a h4 {
  font-weight: 500;
  color: rgba(var(--theme-color), 1);
  white-space: nowrap;
}
.title.animated-title {
  position: relative;
}
.title.animated-title .loader-line {
  width: 90px;
  height: 2px;
  overflow: hidden;
  background-color: #eee;
  border-radius: 20px;
  position: relative;
  top: -8px;
  left: 0;
  z-index: 1;
}
.dark .title.animated-title .loader-line {
  background-color: #424242;
}
.title.animated-title .loader-line::before {
  content: "";
  position: absolute;
  width: 90px;
  height: 3px;
  top: 0px;
  left: -60px;
  background-color: rgba(var(--box-bg), 1);
}
[dir=rtl] .title.animated-title .loader-line::before {
  left: unset;
  right: 0;
}
.title.animated-title p {
  line-height: 1.5;
  font-weight: 400;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
}
.title.title-border {
  margin-top: 0;
}
.title.title-border h2 {
  position: relative;
  margin-bottom: 11px;
  padding-bottom: 4px;
}
.title.title-border h2::after {
  content: "";
  position: absolute;
  width: 65px;
  height: 6px;
  background-image: url(../images/svg/highlight-sm.svg);
  bottom: -5px;
  left: 0;
}
[dir=rtl] .title.title-border h2::after {
  left: unset;
  right: 0;
}

.page-head-section {
  position: relative;
  padding: calc(80px + 50 * (100vw - 320px) / 1600) 0 calc(30px + 20 * (100vw - 320px) / 1600);
}
.page-head-section::before {
  content: "";
  background-image: url(https://themes.pixelstrap.com/zomo-app-landing/assets/images/background/home-bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
[dir=rtl] .page-head-section::before {
  transform: scaleX(-1);
  filter: FlipH;
}
.page-head-section .page-heading {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.page-head-section .page-heading .breadcrumb {
  margin-bottom: -3px;
}
.page-head-section .page-heading .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  gap: 9px;
}
.page-head-section .page-heading .breadcrumb-item.active {
  color: #fff;
}
.page-head-section .page-heading .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.42);
}
.page-head-section .page-heading.page-heading2 {
  padding: calc(20px + 55 * (100vw - 320px) / 1600) 0;
}
.page-head-section .page-heading h2 {
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  color: rgba(var(--white), 1);
  margin-top: -7px;
}
.page-head-section .page-heading h6 {
  margin-top: calc(2px + 8 * (100vw - 320px) / 1600);
  color: rgba(255, 255, 255, 0.5);
}
.page-head-section .page-heading .deals-img {
  width: calc(85px + 110 * (100vw - 320px) / 1600);
  opacity: 0.5;
}

.faq-title {
  text-align: center;
  margin-bottom: calc(15px + 15 * (100vw - 320px) / 1600);
}
.faq-title h2 {
  font-weight: 700;
  font-size: calc(25px + 25 * (100vw - 320px) / 1600);
  line-height: 1.2;
  color: rgba(var(--dark-text), 1);
  margin-top: -6px;
}

.loader-line {
  width: calc(50px + 30 * (100vw - 320px) / 1600);
  height: 2px;
  overflow: hidden;
  background-color: #eee;
  border-radius: 20px;
  position: absolute;
  top: -8px;
}
.dark .loader-line {
  background-color: #424242;
}
.loader-line::before {
  content: "";
  position: absolute;
  left: -50%;
  height: 2px;
  width: 30%;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  animation: lineAnim 2s linear infinite;
  border-radius: 20px;
}
[dir=rtl] .loader-line::before {
  right: -50%;
  left: unset;
  animation: lineAnimReverse 2s linear infinite;
}

.title-center {
  text-align: center;
}
.title-center h2 {
  font-size: calc(18px + 44 * (100vw - 320px) / 1600);
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: calc(-6px + -11 * (100vw - 320px) / 1600);
  margin-top: calc(-6px + -5 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.title-center h2 span {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  display: block;
}

/*=====================
  3.13 loader Style start
==========================*/
.skeleton-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--white), 1);
  overflow: auto;
  z-index: 10;
}
.skeleton-loader .placeholder {
  background: #e1e1e1;
  min-height: calc(16px + 6 * (100vw - 320px) / 1600);
}
.skeleton-loader .home-wrapper {
  background-image: none;
  background-color: #232220;
  height: calc(296px + 332 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  padding: 0 !important;
}
.skeleton-loader .home-wrapper .loader-gif {
  width: calc(80px + 20 * (100vw - 320px) / 1600);
  height: calc(80px + 20 * (100vw - 320px) / 1600);
  border-radius: 100%;
  background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, 0.02) 100%), rgba(226, 232, 255, 0.02);
  border: 1px solid rgba(226, 232, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
}
.skeleton-loader .home-wrapper .loader-gif img {
  width: calc(45px + 20 * (100vw - 320px) / 1600);
  object-fit: contain;
}
.skeleton-loader .home-wrapper h2 {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1600);
  font-size: calc(18px + 12 * (100vw - 320px) / 1600);
}
.skeleton-loader .title {
  display: flex;
  flex-direction: column;
  gap: calc(8px + 4 * (100vw - 320px) / 1600);
}
.skeleton-loader .title .loader-line::before {
  display: none;
}
@media (max-width: 768px) {
  .skeleton-loader .title p {
    width: 100% !important;
  }
}
.skeleton-loader .banner-part {
  width: calc(142px + 140 * (100vw - 320px) / 1600);
  height: calc(132px + 132 * (100vw - 320px) / 1600);
}
.skeleton-loader .banner-part a {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.skeleton-loader .loader-custom-height {
  width: calc(35px + 10 * (100vw - 320px) / 1600);
  height: calc(35px + 10 * (100vw - 320px) / 1600);
}
.skeleton-loader .shop-sidebar-demo .top-section {
  background: #f8f8f8;
  height: calc(172px + 116 * (100vw - 320px) / 1600);
}
@media (max-width: 576px) {
  .skeleton-loader .shop-sidebar-demo .top-section .banner-content {
    width: 100% !important;
  }
}
.skeleton-loader .shop-sidebar-demo .shop-sidebar ul li a {
  gap: 16px;
}
.skeleton-loader .shop-sidebar-demo .shop-sidebar ul li a .placeholder {
  background: #474747;
}
.skeleton-loader .shop-sidebar-demo .popular-restaurant .product-style-2 .vertical-product-box-img {
  width: 100%;
  height: calc(161px + 26 * (100vw - 320px) / 1600);
}
.skeleton-loader .shop-sidebar-demo .popular-restaurant .product-style-2 .vertical-product-body {
  padding-top: 32px;
}
.skeleton-loader .shop-sidebar-demo .popular-restaurant .product-style-2 .vertical-product-body .icon {
  width: 16px;
  min-height: 16px;
}
.skeleton-loader .home3 {
  height: calc(250px + 288 * (100vw - 320px) / 1600);
}
.skeleton-loader .home3 .home-content .home-left-content .placeholder {
  background-color: #474747;
}
.skeleton-loader .home3 .home-content .home-left-content .auth-form {
  height: calc(39px + 21 * (100vw - 320px) / 1600);
  border-radius: 40px;
}
.skeleton-loader .home3 .home-content .home-left-content .home-btn {
  height: calc(40px + 11 * (100vw - 320px) / 1600);
  border-radius: 30px;
}
.skeleton-loader .service-section .home-features-list .custom-height-img {
  height: calc(50px + 24 * (100vw - 320px) / 1600);
  width: calc(50px + 24 * (100vw - 320px) / 1600);
}
.skeleton-loader .popular-restaurant .product-style-3 .vertical-product-box-img {
  height: 231px;
  width: 100%;
}
.skeleton-loader .popular-restaurant .product-style-3 .vertical-product-body .border-skeleton-bottom {
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
.skeleton-loader .home4 .home-content .placeholder {
  background-color: #474747;
}
.skeleton-loader .home4 .home-content .form-auth {
  height: calc(46px + 10 * (100vw - 320px) / 1600);
  border-radius: 10px;
}
.skeleton-loader .home4 .home-content .home-btn {
  height: calc(41px + 11 * (100vw - 320px) / 1600);
  border-radius: 5px;
}
.skeleton-loader .title-border h2 {
  margin-bottom: 0;
}
.skeleton-loader .title-border h2::after {
  display: none;
}
.skeleton-loader .deal-section .deal-box .deal-content .coupon-code {
  background: #e9e9e9;
  height: 47px;
}

/* layout scss files */
/*========================
 4.1 Footer Style start
==========================*/
.footer-section {
  position: relative;
  background-color: rgb(43, 43, 43);
  overflow: hidden;
}
.footer-section .item-3 {
  width: 280px;
  position: absolute;
  top: 0;
  right: -100px;
  opacity: 0.1;
}
[dir=rtl] .footer-section .item-3 {
  left: -100px;
  right: unset;
}
@media (max-width: 1150px) {
  .footer-section .item-3 {
    display: none;
  }
}
.footer-section .subscribe-section {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: calc(20px + 60 * (100vw - 320px) / 1600);
}
.footer-section .subscribe-section .subscribe-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc(40px + 200 * (100vw - 320px) / 1600);
}
@media (max-width: 991px) {
  .footer-section .subscribe-section .subscribe-part {
    display: block;
  }
}
.footer-section .subscribe-section .subscribe-part h5 {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  line-height: 1.7;
  width: 90%;
}
@media (max-width: 991px) {
  .footer-section .subscribe-section .subscribe-part h5 {
    width: 100%;
    margin-bottom: 15px;
  }
}
.footer-section .subscribe-section .subscribe-part .subscribe-form-control {
  position: relative;
  padding: calc(6px + 9 * (100vw - 320px) / 1600);
  border-radius: 6px;
  box-shadow: none;
  border-color: transparent;
  background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, 0.1) 100%), rgba(226, 232, 255, 0.06);
  color: rgba(255, 255, 255, 0.8);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.footer-section .subscribe-section .subscribe-part .subscribe-form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
[dir=rtl] .footer-section .subscribe-section .subscribe-part .subscribe-form-control {
  text-align: right;
}
.footer-section .subscribe-section .subscribe-part .subscribe-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
  padding: calc(8px + 7 * (100vw - 320px) / 1600);
  border-radius: 6px;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
}
[dir=rtl] .footer-section .subscribe-section .subscribe-part .subscribe-btn {
  left: 0;
  right: unset;
}
.footer-section .subscribe-section .dropdown .language-btn {
  justify-content: space-between;
  padding: 10px;
  gap: calc(10px + 30 * (100vw - 320px) / 1600);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(2px);
  color: rgba(var(--white), 1);
}
.footer-section .subscribe-section .dropdown .dropdown-menu {
  width: 100%;
  background-color: rgb(55, 55, 55);
}
.footer-section .subscribe-section .dropdown .dropdown-menu.show {
  display: block;
}
.footer-section .subscribe-section .dropdown .dropdown-menu li {
  width: 100%;
}
.footer-section .subscribe-section .dropdown .dropdown-menu li .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px 10px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--white), 1);
}
[dir=rtl] .footer-section .subscribe-section .dropdown .dropdown-menu li .dropdown-item {
  padding: 0 0 10px 10px;
}
.footer-section .subscribe-section .dropdown .dropdown-menu li .dropdown-item:last-child {
  padding-bottom: 0;
}
.footer-section .subscribe-section .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.footer-section .subscribe-section .form-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: calc(10px + 30 * (100vw - 320px) / 1600);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(2px);
  color: rgba(var(--white), 1);
  border: none;
}
.footer-section .subscribe-section .form-select.language-btn {
  width: 150px;
}
.footer-section .subscribe-section .form-select.coupons {
  width: 170px;
}
.footer-section .subscribe-section .form-select option {
  width: 100%;
  background-color: rgb(55, 55, 55);
  border: none;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.footer-section .subscribe-section .form-select option:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.footer-section .subscribe-section .form-select:focus {
  box-shadow: none;
  border: none;
}
.footer-section .main-footer {
  padding: calc(20px + 60 * (100vw - 320px) / 1600) 0;
}
.footer-section .main-footer .footer-logo-part .logo {
  height: calc(35px + 17 * (100vw - 320px) / 1600);
  padding-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  cursor: pointer;
}
.footer-section .main-footer .footer-logo-part p {
  width: 80%;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.6;
}
@media (max-width: 1200px) {
  .footer-section .main-footer .footer-logo-part p {
    width: 100%;
  }
}
.footer-section .main-footer .footer-logo-part .social-media-part {
  padding: calc(10px + 20 * (100vw - 320px) / 1600) 0 0;
}
@media (max-width: 576px) {
  .footer-section .main-footer .footer-logo-part .social-media-part {
    margin-bottom: 10px;
  }
}
.footer-section .main-footer .footer-logo-part .social-media-part .social-icon {
  display: flex;
  align-items: center;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
}
[dir=rtl] .footer-section .main-footer .footer-logo-part .social-media-part .social-icon {
  padding: 0;
}
.footer-section .main-footer .footer-logo-part .social-media-part .social-icon li {
  transition: 0.4s ease;
}
.footer-section .main-footer .footer-logo-part .social-media-part .social-icon li:hover {
  transform: translateY(-10px);
}
.footer-section .main-footer .footer-logo-part .social-media-part .social-icon li .icon {
  display: block;
  width: calc(25px + 15 * (100vw - 320px) / 1600);
  height: calc(25px + 15 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  font-size: calc(13px + 8 * (100vw - 320px) / 1600);
}
.footer-section .main-footer .footer-title {
  position: relative;
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  color: #fff;
  margin-bottom: calc(10px + 15 * (100vw - 320px) / 1600);
  padding-left: 10px;
}
[dir=rtl] .footer-section .main-footer .footer-title {
  padding-left: unset;
  padding-right: 10px;
}
@media (max-width: 576px) {
  .footer-section .main-footer .footer-title:after {
    content: "\ea4e";
    font-family: remixicon !important;
    font-style: normal;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease;
  }
  [dir=rtl] .footer-section .main-footer .footer-title:after {
    right: unset;
    left: 0;
  }
}
.footer-section .main-footer .footer-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(15px + 15 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--theme-color), 1);
}
[dir=rtl] .footer-section .main-footer .footer-title::before {
  left: unset;
  right: 0;
}
.footer-section .main-footer h5 {
  color: rgba(var(--white), 1);
}
.footer-section .main-footer .open-footer-content .content {
  display: block;
}
.footer-section .main-footer .open-footer-content .footer-title::after {
  content: "\ea78";
  transition: all 0.5s ease;
}
[dir=rtl] .footer-section .main-footer .content {
  padding: 0;
}
@media (max-width: 576px) {
  .footer-section .main-footer .content {
    display: none;
  }
}
.footer-section .main-footer .content li {
  display: block;
  padding-bottom: calc(8px + 12 * (100vw - 320px) / 1600);
}
.footer-section .main-footer .content li:last-child {
  padding-bottom: 0;
}
.footer-section .main-footer .content li a h6 {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.4s ease;
}
.footer-section .main-footer .content li a h6:hover {
  margin-left: 5px;
  color: rgba(var(--theme-color), 1);
}
[dir=rtl] .footer-section .main-footer .content li a h6:hover {
  margin-left: unset;
  margin-right: 5px;
}
.footer-section .bottom-footer-part {
  padding: calc(15px + 15 * (100vw - 320px) / 1600) 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-section .bottom-footer-part h6 {
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
}
.footer-section .bottom-footer-part .cards {
  cursor: pointer;
}
.footer-section.footer-sm .footer-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: blur(9px);
}
.footer-section.footer-sm .custom-container {
  z-index: 1;
  position: relative;
}

.border-top-cls {
  border-top: 1px solid rgba(var(--white), 0.2);
}

/*=====================
  4.2  Header Style start
==========================*/
header {
  position: absolute;
  width: 100%;
  padding-top: calc(10px + 20 * (100vw - 320px) / 1600);
  z-index: 2;
}
@media (max-width: 767px) {
  header .navbar .location-btn {
    margin-left: auto !important;
  }
  [dir=rtl] header .navbar .location-btn {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
}
@media (max-width: 400px) {
  header .navbar .location-btn span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 131px;
  }
}
header.header2 {
  position: fixed;
  padding: calc(15px + 5 * (100vw - 320px) / 1600) calc(12px + 13 * (100vw - 320px) / 671);
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  width: calc(100vw - (300px + 20 * (100vw - 991px) / 929));
  left: calc(300px + 20 * (100vw - 991px) / 929);
  background-color: rgba(var(--white), 1);
}
@media (max-width: 991px) {
  header.header2 {
    width: 100%;
    left: 0;
  }
}
@media (min-width: 767px) {
  header.header2 .collapse-logo .logo {
    display: none;
  }
}
header.header2 .navbar-toggler .navbar-toggler-icon {
  border: 1px solid rgba(var(--dark-text), 0.5);
}
header.header2 .navbar-toggler .navbar-toggler-icon i {
  color: rgba(var(--dark-text), 0.5);
}
header.header2 .form_search input {
  background-color: rgba(var(--box-bg), 1);
  border: none;
  padding: calc(8px + 4 * (100vw - 320px) / 1600) 12px;
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 5px;
  width: calc(210px + 330 * (100vw - 767px) / 1153);
  color: rgba(var(--content-color), 1);
}
header.header2 .form_search input:focus {
  outline: none;
}
@media (max-width: 767px) {
  header.header2 .form_search {
    display: none;
  }
}
header.header2 .location-flex {
  display: flex;
  align-items: center;
  gap: calc(12px + 3 * (100vw - 767px) / 1153);
  margin-left: auto;
}
[dir=rtl] header.header2 .location-flex {
  margin-left: 0;
  margin-right: auto;
}
header.header2 .navbar .profile-part .profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}
header.header2 .navbar .profile-part h6 {
  color: rgba(var(--dark-text), 1);
}
header.header2 .navbar .profile-part h5 {
  color: rgba(var(--content-color), 1);
}
header.header2 .navbar .nav-option .cart-bag {
  border-right: 1px solid rgba(var(--black), 0.1);
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] header.header2 .navbar .nav-option .cart-bag {
  border-right: none;
  border-left: 1px solid rgba(var(--black), 0.1);
}
@media (max-width: 1199px) {
  header.header2 .navbar .nav-option .profile-part h5,
  header.header2 .navbar .nav-option .profile-part h6 {
    display: none;
  }
}

.header-style-1 {
  padding: 20px 0;
  background-color: rgba(var(--white), 1);
  position: relative;
}
.header-style-1 .navbar .navbar-nav .nav-item .nav-link {
  color: rgba(var(--dark-text), 1);
}
.header-style-1 .navbar .navbar-nav .nav-item .nav-link.active {
  color: rgba(var(--theme-color), 1);
  font-weight: 600;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
}

.nav-option .dropdown-button {
  position: relative;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.nav-option .dropdown-button ::-webkit-scrollbar {
  width: 3px;
}
.nav-option .dropdown-button ::-webkit-scrollbar-track {
  background: #e7e7e7;
}
.nav-option .dropdown-button ::-webkit-scrollbar-thumb {
  background: #adadad;
}
.nav-option .dropdown-button ::-webkit-scrollbar-thumb:hover {
  background: #747474;
}
.nav-option .dropdown-button .cart-button span {
  position: absolute;
  left: 13px;
  color: #fff;
  top: -6px;
  font-weight: 500;
  font-size: 10px;
  width: 15px;
  height: 15px;
  background-color: rgba(var(--theme-color), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.nav-option .dropdown-button:hover .onhover-box {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.nav-option .dropdown-button:hover .onhover-box.onhover-sm {
  transform: translateY(15px);
}
.nav-option .dropdown-button .onhover-box {
  position: absolute;
  top: 40px;
  right: -10px;
  background-color: rgba(var(--box-bg), 1);
  z-index: 5;
  width: 320px;
  border-radius: 8px;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  box-shadow: -1px 0 10px 0 rgba(61, 61, 61, 0.07), 5px 20px 40px 0 rgba(61, 61, 61, 0.04);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  transform: translateY(25px);
}
.nav-option .dropdown-button .onhover-box.onhover-sm {
  width: 180px;
  padding: 10px 15px;
}
[dir=rtl] .nav-option .dropdown-button .onhover-box {
  right: unset;
  left: -10px;
}
.nav-option .dropdown-button .onhover-box .menu-list li {
  display: block;
  font-size: 16px;
}
.nav-option .dropdown-button .onhover-box .menu-list li .dropdown-item {
  color: rgba(var(--dark-text), 1);
  transition: 0.4s ease;
}
.nav-option .dropdown-button .onhover-box .menu-list li:hover .dropdown-item {
  color: rgba(var(--theme-color), 1);
}
.nav-option .dropdown-button .onhover-box .menu-list li + li {
  margin-top: 6px;
}
.nav-option .dropdown-button .onhover-box .bottom-btn {
  padding-top: 10px;
  border-top: 1px solid rgba(var(--dashed-line), 1);
  margin-top: 10px;
}
.nav-option .dropdown-button .onhover-box .cart-list {
  display: flex;
  flex-wrap: wrap;
  gap: calc(5px + 9 * (100vw - 320px) / 1600);
  max-height: 210px;
  overflow: auto;
}
.nav-option .dropdown-button .onhover-box .cart-list li {
  margin: 0 !important;
}
.nav-option .dropdown-button .onhover-box .cart-list li::after {
  content: none !important;
}
.nav-option .dropdown-button .onhover-box .cart-list li .drop-cart {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 15px;
}
.nav-option .dropdown-button .onhover-box .cart-list li .drop-cart::after {
  content: none;
}
.nav-option .dropdown-button .onhover-box .cart-list li .drop-cart .drop-image img {
  width: 87px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 8px;
}
.nav-option .dropdown-button .onhover-box .cart-list li .drop-cart .drop-contain h5 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 0;
  font-size: calc(15px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.nav-option .dropdown-button .onhover-box .cart-list li .drop-cart .drop-contain h6 {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  gap: 5px;
  color: rgba(var(--content-color), 1);
  font-size: 16px;
  margin-top: 2px;
}
.nav-option .dropdown-button .onhover-box .cart-list li .drop-cart .drop-contain h6 span {
  margin-top: -2px;
  color: rgba(var(--content-color), 1);
}
.nav-option .dropdown-button .onhover-box .cart-list li .drop-cart .drop-contain .close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .nav-option .dropdown-button .onhover-box .cart-list li .drop-cart .drop-contain .close-button {
  right: unset;
  left: 0;
}
.nav-option .dropdown-button .onhover-box .button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.nav-option .dropdown-button .onhover-box .price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(8px + 7 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  border-top: 1px solid rgba(var(--dashed-line), 1);
  padding-top: calc(8px + 7 * (100vw - 320px) / 1600);
}
.nav-option .dropdown-button .onhover-box .price-box h5 {
  font-weight: 400;
}
.nav-option .dropdown-button .onhover-box .price-box h4 {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
}
@media (max-width: 991px) {
  .nav-option .profile-part h6 {
    display: none;
  }
}
@media (max-width: 991px) {
  .nav-option .profile-part h5 {
    display: none;
  }
}

.navbar {
  justify-content: unset;
  align-items: center;
  gap: 5px;
}
.navbar .profile-part {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 991px) {
  .navbar .profile-part {
    gap: 0;
  }
}
.navbar .profile-part .profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.navbar .profile-part h6 {
  font-size: 14px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.7);
}
.navbar .profile-part h5 {
  margin-top: 4px;
  line-height: 1.3;
  font-size: 16px;
  color: rgb(255, 255, 255);
}
.navbar .logo {
  height: calc(16px + 14 * (100vw - 320px) / 1600);
}
.navbar .navbar-toggler {
  padding: 0;
  border: 0;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 6px;
  margin-right: calc(4px + 16 * (100vw - 320px) / 671);
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
[dir=rtl] .navbar .navbar-toggler .navbar-toggler-icon {
  margin-right: unset;
  margin-left: calc(10px + 10 * (100vw - 320px) / 1600);
}
.navbar .navbar-toggler .navbar-toggler-icon i {
  padding: 3px;
  color: #fff;
}
.navbar .navbar-toggler:focus {
  box-shadow: none;
}
@media (max-width: 767px) {
  .navbar .navbar-collapse {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    padding: 10px 15px;
    margin-top: 10px;
    color: rgba(var(--white), 1);
    background-color: rgba(var(--white), 1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  [dir=rtl] .navbar .navbar-collapse {
    right: 0;
    left: unset;
  }
}
@media (min-width: 767px) {
  .navbar .navbar-collapse {
    display: flex;
    justify-content: center;
  }
}
.navbar .navbar-collapse.show .navbar-nav {
  margin-left: 0;
}
[dir=rtl] .navbar .navbar-collapse.show .navbar-nav {
  margin-left: unset;
  margin-right: 0;
}
.navbar .nav-option {
  display: flex;
  align-items: center;
  gap: calc(8px + 17 * (100vw - 320px) / 1600);
  margin-left: auto;
}
@media (max-width: 767px) {
  .navbar .nav-option {
    display: none;
  }
}
[dir=rtl] .navbar .nav-option {
  margin-left: unset;
  margin-right: auto;
}
.navbar .nav-option .cart-bag {
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
  padding-right: calc(10px + 15 * (100vw - 320px) / 1600);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
[dir=rtl] .navbar .nav-option .cart-bag {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: unset;
  padding-right: unset;
  padding-left: calc(10px + 15 * (100vw - 320px) / 1600);
}
.navbar .nav-option .cart-button:hover .onhover-box {
  opacity: 1;
  top: 50px;
  visibility: visible;
}
.navbar .navbar-nav {
  display: flex;
  gap: calc(5px + 20 * (100vw - 320px) / 1600);
}
.navbar .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-weight: 400;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
  padding: 0;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .navbar .navbar-nav .nav-item .nav-link {
    color: rgba(var(--dark-text), 1);
    justify-content: space-between;
    margin-bottom: 8px;
  }
}
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: rgba(var(--theme-color), 1);
}
.navbar .navbar-nav .nav-item .nav-link.active {
  color: rgba(var(--theme-color), 1);
  font-weight: 400;
  font-size: calc(14px + 6 * (100vw - 320px) / 1600);
}
@media (max-width: 991px) {
  .navbar .offcanvas {
    width: 300px;
    background-color: rgba(var(--white), 1);
  }
  .navbar .offcanvas .offcanvas-header {
    border-bottom: 1px solid #eee;
  }
  .dark .navbar .offcanvas .offcanvas-header {
    border-color: #484848;
  }
  .navbar .offcanvas .offcanvas-header .navbar-toggler {
    font-size: 16px;
    margin: 0;
  }
  .dark .navbar .offcanvas .offcanvas-title {
    color: white;
  }
}

.sub-header-1 .header-top {
  background-color: rgba(var(--theme-color), 1);
  padding: 8px 0;
}
.sub-header-1 .top-left-header {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
}
.sub-header-1 .header-offer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(var(--white), 1);
}
.sub-header-1 .header-offer .notification-slider .timer-notification h6 {
  color: rgba(var(--white), 1);
  font-size: 16px;
}
.sub-header-1 .top-right-header {
  width: 100%;
}
.sub-header-1 .top-right-header .right-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sub-header-1 .top-right-header .right-list .dropdown {
  cursor: pointer;
  padding-right: 20px;
}
[dir=rtl] .sub-header-1 .top-right-header .right-list .dropdown {
  padding-right: unset;
  padding-left: 20px;
}
.sub-header-1 .top-right-header .right-list .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sub-header-1 .top-right-header .right-list .dropdown .dropdown-toggle span {
  color: rgba(var(--white), 1);
}
.sub-header-1 .top-right-header .right-list .dropdown .dropdown-toggle::after {
  content: "\ea4e";
  font-family: remixicon !important;
  font-style: normal;
  color: rgba(var(--white), 1);
  border: none;
}
.sub-header-1 .top-right-header .right-list .dropdown .dropdown-menu {
  min-width: 100%;
}
.sub-header-1 .top-right-header .right-list .dropdown .dropdown-menu li {
  width: 100%;
}
.sub-header-1 .top-right-header .right-list .dropdown .dropdown-menu li .dropdown-item:active {
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--white), 1);
}
.sub-header-1 .top-right-header .right-list .delivery-detail {
  white-space: nowrap;
  border-left: 1px solid rgba(var(--white), 0.2);
  padding-left: 20px;
}
[dir=rtl] .sub-header-1 .top-right-header .right-list .delivery-detail {
  padding-left: unset;
  padding-right: 20px;
}
.sub-header-1 .top-right-header .right-list .delivery-detail h6 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(var(--white), 1);
}
.sub-header-1 .top-right-header .right-list .delivery-detail h5 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: rgba(var(--white), 1);
}

.header-style-2 {
  position: relative;
}
.header-style-2 .top-navbar {
  display: flex;
  align-items: center;
  width: 100%;
  gap: calc(10px + 20 * (100vw - 320px) / 1600);
  padding: calc(15px + 10 * (100vw - 320px) / 1600) 0;
  justify-content: space-between;
}
.header-style-2 .nav-logo .logo {
  height: calc(35px + 21 * (100vw - 320px) / 1600);
}
.header-style-2 .middle-box {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header-style-2 .middle-box .location-box {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: rgba(var(--box-bg), 1);
  border: 1px solid rgba(var(--dark-text), 0.15);
  border-radius: 8px;
  width: calc(175px + 65 * (100vw - 576px) / 1344);
  justify-content: space-between;
}
@media (max-width: 576px) {
  .header-style-2 .middle-box .location-box {
    display: none;
  }
}
.header-style-2 .middle-box .location-box .local-name {
  font-weight: 500;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.header-style-2 .middle-box .location-box .arrow {
  font-size: 24px;
  line-height: 1;
  font-weight: 400;
  color: rgba(var(--dark-text), 1);
}
@media (max-width: 1199px) {
  .header-style-2 .middle-box .search-box {
    display: none;
  }
}
.header-style-2 .middle-box .search-box .form-control {
  width: calc(400px + 430 * (100vw - 992px) / 928);
  padding: 12px;
  background-color: rgba(var(--box-bg), 1);
  border: 1px solid rgba(var(--dark-text), 0.15);
  border-radius: 8px;
  cursor: pointer;
}
.header-style-2 .middle-box .search-box .form-control::placeholder {
  color: rgb(136, 136, 136);
}
.header-style-2 .middle-box .search-box .form-control:focus {
  box-shadow: none;
}
@media (max-width: 1199px) {
  .header-style-2 .middle-box .search-box .head-search-btn {
    display: none;
  }
}
.header-style-2 .right-side-box {
  margin-left: auto;
}
[dir=rtl] .header-style-2 .right-side-box {
  margin-left: unset;
  margin-right: auto;
}
.header-style-2 .right-side-box .search-full {
  width: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: rgba(var(--white), 1);
  z-index: 2;
}
[dir=rtl] .header-style-2 .right-side-box .search-full {
  right: unset;
  left: 0;
}
@media (min-width: 767px) {
  .header-style-2 .right-side-box .search-full {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-style-2 .right-side-box .search-full.show {
    display: block;
    transition: all 0.5 ease;
  }
}
.header-style-2 .right-side-box .search-full.open {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all 0.3s ease;
  animation: zoomIn 0.5s ease-in-out;
}
.header-style-2 .right-side-box .search-full .input-group .search-type {
  padding: 12px 12px 12px 55px;
  border-radius: 6px !important;
  background-color: rgba(var(--box-bg), 1);
  cursor: pointer;
}
[dir=rtl] .header-style-2 .right-side-box .search-full .input-group .search-type {
  padding: 12px 55px 12px 12px;
}
.header-style-2 .right-side-box .search-full .input-group .search-type::placeholder {
  color: rgb(136, 136, 136);
}
.header-style-2 .right-side-box .search-full .input-group .search-type:focus {
  box-shadow: none;
  border-color: transparent;
  margin-left: 0;
  outline: 0;
}
[dir=rtl] .header-style-2 .right-side-box .search-full .input-group .search-type:focus {
  margin-left: unset;
  margin-right: 0;
}
.header-style-2 .right-side-box .search-full .input-group .input-group-text {
  background-color: transparent;
  border: none;
}
.header-style-2 .right-side-box .search-full .input-group .input-group-text .full-search-icon {
  position: absolute;
  left: 40px;
  font-size: 20px;
  z-index: 2;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .header-style-2 .right-side-box .search-full .input-group .input-group-text .full-search-icon {
  left: unset;
  right: 40px;
}
.header-style-2 .right-side-box .search-full .input-group .input-group-text .full-close-icon {
  position: absolute;
  right: 40px;
  font-size: 20px;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .header-style-2 .right-side-box .search-full .input-group .input-group-text .full-close-icon {
  right: unset;
  left: 40px;
}
.header-style-2 .right-side-box .right-side-menu {
  display: flex;
  align-items: center;
  gap: calc(20px + 20 * (100vw - 320px) / 1600);
}
@media (max-width: 767px) {
  .header-style-2 .right-side-box .right-side-menu .right-side {
    display: none;
  }
  .header-style-2 .right-side-box .right-side-menu .right-side:last-child {
    display: block;
  }
}
.header-style-2 .right-side-box .right-side-menu .right-side i {
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
  padding: 10px;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 100%;
}
.header-style-2 .right-side-box .right-side-menu .right-side:first-child {
  display: none;
}
@media (max-width: 1199px) {
  .header-style-2 .right-side-box .right-side-menu .right-side:first-child {
    display: block;
  }
}

.sub-header-2 .header-top {
  padding: 8px 0;
  background-color: rgba(var(--white), 1);
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.sub-header-2 .top-left-header {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  white-space: nowrap;
}
.sub-header-2 .top-left-header .help-call h6 {
  white-space: nowrap;
}
.sub-header-2 .top-left-header .help-call h6 span {
  font-size: 14px;
  color: rgba(var(--theme-color), 1);
}
@media (max-width: 992px) {
  .sub-header-2 .top-left-header {
    display: none;
  }
}
.sub-header-2 .top-right-header {
  width: 100%;
}
.sub-header-2 .top-right-header .right-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}
.sub-header-2 .top-right-header .right-list .dropdown {
  cursor: pointer;
}
.sub-header-2 .top-right-header .right-list .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sub-header-2 .top-right-header .right-list .dropdown .dropdown-toggle span {
  color: rgba(var(--content-color), 1);
}
.sub-header-2 .top-right-header .right-list .dropdown .dropdown-toggle::after {
  content: "\ea4e";
  font-family: remixicon !important;
  font-style: normal;
  color: rgba(var(--content-color), 1);
  border: none;
}
.sub-header-2 .top-right-header .right-list .dropdown .dropdown-menu {
  min-width: 100%;
}
.sub-header-2 .top-right-header .right-list .dropdown .dropdown-menu li {
  width: 100%;
}
.sub-header-2 .top-right-header .right-list .dropdown .dropdown-menu li .dropdown-item:active {
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--white), 1);
}
.sub-header-2 .top-right-header .right-list .delivery-detail {
  white-space: nowrap;
}
.sub-header-2 .top-right-header .right-list .delivery-detail h6 {
  font-size: 14px;
  color: rgba(var(--content-color), 1);
}
.sub-header-2 .top-right-header .right-list .delivery-detail h5 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
}

.header-style-3 {
  position: relative;
  background-color: rgba(var(--white), 1);
}
.header-style-3 .top-navbar {
  display: flex;
  align-items: center;
  width: 100%;
  gap: calc(10px + 30 * (100vw - 320px) / 1600);
  padding: 25px 0;
}
.header-style-3 .nav-logo .logo {
  height: calc(35px + 21 * (100vw - 320px) / 1600);
}
.header-style-3 .middle-box {
  display: flex;
  gap: 10px;
  align-items: center;
}
@media (max-width: 1199px) {
  .header-style-3 .middle-box {
    display: none;
  }
}
.header-style-3 .middle-box .search-box .form-control {
  width: calc(400px + 380 * (100vw - 992px) / 928);
  padding: 12px;
  background-color: rgba(var(--box-bg), 1);
  border: 1px solid rgba(var(--dark-text), 0.15);
  border-radius: 8px;
  cursor: pointer;
}
.header-style-3 .middle-box .search-box .form-control::placeholder {
  color: rgb(136, 136, 136);
}
.header-style-3 .middle-box .search-box .form-control:focus {
  box-shadow: none;
}
.header-style-3 .right-side-box {
  margin-left: auto;
}
[dir=rtl] .header-style-3 .right-side-box {
  margin-left: unset;
  margin-right: auto;
}
.header-style-3 .right-side-box .search-full {
  background-color: rgba(var(--white), 1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
}
[dir=rtl] .header-style-3 .right-side-box .search-full {
  left: 0;
  right: unset;
}
.header-style-3 .right-side-box .search-full.open {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all 0.3s ease;
  animation: zoomIn 0.5s ease-in-out;
}
.header-style-3 .right-side-box .search-full .input-group .search-type {
  padding: 12px 12px 12px 55px;
  border-radius: 6px !important;
  background-color: rgba(var(--box-bg), 1);
  border: 1px solid rgba(var(--dark-text), 0.15);
  background: transparent;
  z-index: unset;
  cursor: pointer;
}
[dir=rtl] .header-style-3 .right-side-box .search-full .input-group .search-type {
  padding: 12px 55px 12px 12px;
}
.header-style-3 .right-side-box .search-full .input-group .search-type::placeholder {
  color: rgb(136, 136, 136);
}
.header-style-3 .right-side-box .search-full .input-group .search-type:focus {
  box-shadow: none;
  border-color: transparent;
}
.header-style-3 .right-side-box .search-full .input-group .input-group-text {
  background-color: transparent;
  border: none;
}
.header-style-3 .right-side-box .search-full .input-group .input-group-text .full-search-icon {
  position: absolute;
  left: 40px;
  font-size: 20px;
  z-index: 2;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .header-style-3 .right-side-box .search-full .input-group .input-group-text .full-search-icon {
  left: unset;
  right: 40px;
}
.header-style-3 .right-side-box .search-full .input-group .input-group-text .full-close-icon {
  position: absolute;
  right: 40px;
  font-size: 20px;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .header-style-3 .right-side-box .search-full .input-group .input-group-text .full-close-icon {
  left: 40px;
  right: unset;
}
.header-style-3 .right-side-box .right-side-menu {
  display: flex;
  align-items: center;
  gap: calc(10px + 30 * (100vw - 320px) / 1600);
}
.header-style-3 .right-side-box .right-side-menu .right-side .header-badge .badge-item {
  position: absolute;
  left: 35px;
  top: 10px;
  transform: translate(-50%, -50%);
}
[dir=rtl] .header-style-3 .right-side-box .right-side-menu .right-side .header-badge .badge-item {
  left: unset;
  right: 35px;
}
@media (max-width: 767px) {
  .header-style-3 .right-side-box .right-side-menu .right-side {
    display: none;
  }
  .header-style-3 .right-side-box .right-side-menu .right-side:last-child {
    display: block;
  }
}
.header-style-3 .right-side-box .right-side-menu .right-side i {
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
  padding: 10px;
  line-height: 1;
  color: rgba(var(--theme-color), 1);
  border-radius: 100%;
}
.header-style-3 .right-side-box .right-side-menu .right-side:first-child {
  display: none;
}
@media (max-width: 1199px) {
  .header-style-3 .right-side-box .right-side-menu .right-side:first-child {
    display: block;
  }
}

.header-light {
  position: relative;
  padding-top: calc(10px + 15 * (100vw - 320px) / 1600);
}
.header-light .navbar {
  padding-bottom: calc(10px + 15 * (100vw - 320px) / 1600) !important;
  border-bottom: 1px solid rgba(var(--black), 0.1);
}
.header-light .navbar .profile-part h6 {
  color: rgba(var(--content-color), 1);
}
.header-light .navbar .profile-part h5 {
  color: rgba(var(--dark-text), 1);
}
.header-light .navbar .navbar-toggler-icon {
  border-color: rgba(var(--black), 1);
}
.header-light .navbar .navbar-toggler-icon i {
  color: rgba(var(--black), 1);
}
.header-light .navbar .nav-option .cart-bag {
  border-right-color: rgba(var(--black), 0.1);
  color: rgba(var(--dark-text), 1);
}
.header-light .navbar .navbar-nav .nav-item .nav-link {
  color: rgba(var(--dark-text), 1);
}

.short-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.short-header .logo {
  height: calc(16px + 14 * (100vw - 320px) / 1600);
}
.short-header .short-nav-list {
  display: flex;
  align-items: center;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
}
.short-header .short-nav-list li:hover a {
  color: rgba(var(--theme-color), 1);
}
.short-header .short-nav-list li a {
  color: rgba(var(--white), 1);
  transition: 0.4s ease;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}

/*=====================
  4.3  mode button style start
==========================*/
.theme-btns {
  position: fixed;
  top: calc(50% + 70px);
  right: 0;
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  gap: 15px;
  z-index: 1;
  transform-origin: right top;
}
[dir=rtl] .theme-btns {
  right: unset;
  left: 34px;
  transform-origin: left top;
  top: calc(50% - 70px);
}
.theme-btns .btntheme {
  padding: 5px 14px;
  color: rgba(var(--white), 1);
  border-radius: 0 0 6px 6px;
  border: none;
  z-index: 3;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  outline: none;
}
[dir=rtl] .theme-btns .btntheme {
  border-radius: 6px 6px 0 0;
}
.theme-btns .icon {
  font-size: calc(15px + 3 * (100vw - 320px) / 1600);
  color: #fff;
  padding: 0 0 2px 0;
}
[dir=rtl] .theme-btns .icon {
  transform: rotate(180deg);
}
.theme-btns .text-value {
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  color: #fff;
}

/*========================
  4.4  tap to top style start
==========================*/
.scroll-to-top {
  z-index: 4;
  position: fixed;
  right: 20px;
  bottom: 30px;
  width: calc(30px + 10 * (100vw - 320px) / 1600);
  height: calc(30px + 10 * (100vw - 320px) / 1600);
  border-radius: 10px;
  background-color: rgba(var(--dark-text), 1);
  border: none;
  font-size: calc(20px + 10 * (100vw - 320px) / 1600);
  transition: 0.4s ease;
  transform: scale(0);
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
[dir=rtl] .scroll-to-top {
  right: unset;
  left: 20px;
}
@media (max-width: 767px) {
  .scroll-to-top {
    bottom: 90px;
  }
}
.scroll-to-top .arrow {
  color: rgba(var(--white), 1);
}
@media (max-width: 991px) {
  .scroll-to-top.menu-page {
    bottom: 70px;
  }
}

/* pages */
/*=====================
  5.1  about style start
==========================*/
.about-image-part {
  border-top: 1px solid rgba(var(--dashed-line), 1);
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  transition: 0.4s ease;
}
@media (max-width: 991px) {
  .about-image-part {
    border-bottom: none;
    border-top: none;
  }
}
.about-image-part .about-images {
  padding: calc(10px + 10 * (100vw - 320px) / 1600) 0;
}
@media (max-width: 991px) {
  .about-image-part .about-images {
    padding-block: 0;
  }
}
.about-image-part .about-images .img {
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover;
}

.about-images.h-100 img {
  object-fit: cover;
}

.about-main-img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover;
}
@media (max-width: 1199px) {
  .about-main-img {
    display: none;
  }
}

.service-box-section {
  background-color: rgba(var(--box-bg), 1);
}
.service-box-section .service-box {
  position: relative;
  padding: calc(15px + 10 * (100vw - 320px) / 1600);
  background: rgba(var(--white), 1);
  border-radius: 2px;
  transition: 0.4s ease;
}
.service-box-section .service-box .dot {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50px;
  right: 10px;
  background-color: rgba(var(--theme-color), 1);
  border-radius: 100%;
}
[dir=rtl] .service-box-section .service-box .dot {
  left: 10px;
  right: unset;
}
.service-box-section .service-box .dot::after {
  content: "";
  position: absolute;
  border: 1px dashed rgba(var(--theme-color), 1);
  width: 50px;
  top: 4px;
}
@media (max-width: 1199px) {
  .service-box-section .service-box .dot {
    display: none;
  }
}
.service-box-section .service-box:hover {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  transition: 0.4s ease;
}
.service-box-section .service-box:hover::before {
  border: 1px solid rgba(var(--theme-color), 1);
}
.service-box-section .service-box:hover .service-name {
  color: #fff;
}
.service-box-section .service-box:hover .service-name::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(10px + 15 * (100vw - 320px) / 1600);
  border: 1px solid #fff;
}
[dir=rtl] .service-box-section .service-box:hover .service-name::before {
  left: unset;
  right: 0;
}
.service-box-section .service-box:hover .service-details {
  color: rgba(255, 255, 255, 0.9);
}
.service-box-section .service-box .service-img {
  width: 55px;
  height: 55px;
  background-color: rgba(var(--box-bg), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.service-box-section .service-box .service-img .icon {
  width: 38px;
  height: 38px;
}
.service-box-section .service-box .service-name {
  position: relative;
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-left: 10px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .service-box-section .service-box .service-name {
  padding-left: unset;
  padding-right: 10px;
}
.service-box-section .service-box .service-name::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(10px + 15 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--theme-color), 1);
}
[dir=rtl] .service-box-section .service-box .service-name::before {
  left: unset;
  right: 0;
}
.service-box-section .service-box .service-details {
  margin-top: calc(5px + 5 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}

.team-box {
  padding: calc(10px + 15 * (100vw - 320px) / 1600);
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(var(--white), 1);
  height: 100%;
  transition: 0.4s ease;
}
.team-box:hover {
  border: 1px solid rgba(var(--theme-color), 1);
}
.team-box .member-image .img {
  width: 100%;
  border-radius: 2px;
}
.team-box .member-details .member-name {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.team-box .member-details .social-icon {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.team-box .member-details .social-icon li {
  transition: 0.4s ease;
}
.team-box .member-details .social-icon li .icon {
  height: calc(25px + 10 * (100vw - 320px) / 1600);
  width: calc(25px + 10 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(13px + 8 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 0.5);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  transition: 0.4s ease;
}
.team-box .member-details .social-icon li:hover {
  transform: translateY(-10px);
}
.team-box .member-details .social-icon li:hover .icon {
  color: #fff;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
}

/*=============================
  5.2  Authentication style start
===============================*/
.otp-box {
  margin-top: 20px;
}

.submit-btn {
  margin-top: calc(15px + 7 * (100vw - 320px) / 1600);
  margin-right: auto;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 500;
  padding: calc(10px + 5 * (100vw - 320px) / 1600) calc(25px + 5 * (100vw - 320px) / 1600);
}
[dir=rtl] .submit-btn {
  margin-left: auto;
  margin-right: unset;
}

.login-hero-section .login-data {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.login-hero-right {
  height: 100%;
}

/*========================
  5.3  blog style start
==========================*/
.left-box {
  position: sticky;
  top: 0;
  border-right: 1px solid rgba(var(--dashed-line), 1);
  height: 100%;
}
[dir=rtl] .left-box {
  border-left: 1px solid rgba(var(--dashed-line), 1);
  border-right: unset;
}
.left-box.right-box {
  border-left: 1px solid rgba(var(--dashed-line), 1);
  border-right: none;
}
@media (max-width: 991px) {
  .left-box.right-box {
    border-left: none;
  }
}
[dir=rtl] .left-box.right-box {
  border-left: none;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
@media (max-width: 991px) {
  [dir=rtl] .left-box.right-box {
    border-right: none;
  }
}
@media (max-width: 991px) {
  .left-box {
    border-right: none;
    display: block;
  }
  [dir=rtl] .left-box {
    border-left: none;
  }
}
.left-box .search-box {
  margin-bottom: calc(15px + 15 * (100vw - 320px) / 1600);
}
.left-box .search-box .form-control {
  position: relative;
  padding: 12px;
  box-shadow: none;
  background-color: rgba(var(--box-bg), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
  cursor: pointer;
}
.left-box .search-box .form-control::placeholder {
  color: rgb(136, 136, 136);
}
.left-box .search-box .search-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  transform: translate(-50%, -50%);
  padding-left: 10px;
  border-left: 1px solid rgba(var(--dashed-line), 1);
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .left-box .search-box .search-icon {
  left: 25px;
  right: unset;
  padding-left: unset;
  padding-right: 10px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
  border-left: unset;
}
.left-box .shop-left-sidebar {
  padding-right: calc(16px + 4 * (100vw - 320px) / 1600);
  position: sticky;
  top: 25px;
  z-index: 1;
}
@media (max-width: 991px) {
  .left-box .shop-left-sidebar {
    padding-right: 0;
    order: 2;
    display: block;
  }
}
[dir=rtl] .left-box .shop-left-sidebar {
  padding-right: 0;
  padding-left: calc(16px + 4 * (100vw - 320px) / 1600);
}
@media (max-width: 991px) {
  [dir=rtl] .left-box .shop-left-sidebar {
    padding-left: 0;
  }
}
.left-box .shop-left-sidebar.shop-right-sidebar {
  padding-left: calc(16px + 4 * (100vw - 320px) / 1600);
  padding-right: 0;
}
@media (max-width: 991px) {
  .left-box .shop-left-sidebar.shop-right-sidebar {
    padding-left: 0;
  }
}
[dir=rtl] .left-box .shop-left-sidebar.shop-right-sidebar {
  padding-right: calc(16px + 4 * (100vw - 320px) / 1600);
  padding-left: 0;
}
@media (max-width: 991px) {
  [dir=rtl] .left-box .shop-left-sidebar.shop-right-sidebar {
    padding-right: 0;
  }
}
.left-box .shop-left-sidebar .filter-item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: calc(5px + 10 * (100vw - 320px) / 1600);
}
.left-box .shop-left-sidebar .filter-item-list li {
  position: relative;
  background-color: rgba(var(--box-bg), 1);
  padding: 10px;
  border-radius: 4px;
  transition: 0.4s ease;
}
.left-box .shop-left-sidebar .filter-item-list li:hover {
  background-color: rgba(var(--theme-color), 0.1);
}
.left-box .shop-left-sidebar .filter-item-list li:hover a {
  color: rgba(var(--theme-color), 1);
}
.left-box .shop-left-sidebar .filter-item-list li a {
  color: rgba(var(--content-color), 1);
  transition: 0.4s ease;
}
.left-box .shop-left-sidebar .category-list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
  gap: calc(10px + 5 * (100vw - 320px) / 1600);
}
[dir=rtl] .left-box .shop-left-sidebar .category-list {
  padding-right: 0;
  padding-left: 10px;
}
.left-box .shop-left-sidebar .category-list.custom-height {
  max-height: 190px;
  overflow-y: auto;
  height: 100%;
}
.left-box .shop-left-sidebar .category-list.scroll-bar {
  /* Chrome, Edge, and Safari */
}
.left-box .shop-left-sidebar .category-list.scroll-bar {
  scrollbar-width: auto;
  scrollbar-color: rgba(var(--theme-color), 1);
  margin-top: 8px;
}
.left-box .shop-left-sidebar .category-list.scroll-bar::-webkit-scrollbar {
  width: 3px;
}
.left-box .shop-left-sidebar .category-list.scroll-bar::-webkit-scrollbar-track {
  background: rgba(var(--box-bg), 1);
  border-radius: 20px;
}
.left-box .shop-left-sidebar .category-list.scroll-bar::-webkit-scrollbar-thumb {
  background-color: rgba(var(--theme-color), 1);
  border-radius: 10px;
}
.left-box .shop-left-sidebar .category-list li {
  display: block;
  width: 100%;
}
.left-box .shop-left-sidebar .category-list li:hover .form-check .form-check-label .name,
.left-box .shop-left-sidebar .category-list li:hover .form-check .form-check-label .number {
  color: rgba(var(--theme-color), 1);
}
.left-box .shop-left-sidebar .category-list li a {
  cursor: pointer;
  color: rgba(var(--dark-text), 1);
}
.left-box .shop-left-sidebar .category-list li a .form-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
}
.left-box .shop-left-sidebar .category-list li a .form-check .form-check-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.left-box .shop-left-sidebar .category-list li a .form-check .form-check-label .name {
  font-size: calc(15px + 2 * (100vw - 320px) / 1600);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  width: 80%;
  transition: 0.4s ease;
}
.left-box .shop-left-sidebar .category-list li a .form-check .form-check-label .number {
  color: rgba(var(--content-color), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  transition: 0.4s ease;
}
.left-box .shop-left-sidebar .category-list li a .form-check .form-check-label .rating {
  display: flex;
  align-items: center;
  gap: calc(2px + 3 * (100vw - 992px) / 928);
}
.left-box .shop-left-sidebar .category-list li a .form-check .form-check-label .rating .icon {
  font-size: 20px;
  color: rgba(var(--secondary-color));
}
.left-box .shop-left-sidebar .category-list li a .form-check .form-check-label .rating .fill-icon {
  font-size: 20px;
  color: rgba(var(--secondary-color));
}
.left-box .shop-left-sidebar .sidebar-title {
  margin: 30px 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.left-box .shop-left-sidebar .wrapper .slider {
  position: relative;
  height: 3px;
  background-color: rgba(var(--content-color), 0.15);
}
.left-box .shop-left-sidebar .wrapper .slider .progress {
  position: absolute;
  height: 100%;
  left: 25%;
  right: 25%;
  border-radius: 5px;
  background: rgba(var(--theme-color), 1);
}
.left-box .shop-left-sidebar .wrapper .range-input {
  position: relative;
}
.left-box .shop-left-sidebar .wrapper .range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  appearance: none;
}
.left-box .shop-left-sidebar .wrapper input[type=number]::-webkit-outer-spin-button,
.left-box .shop-left-sidebar .wrapper input[type=number]::-webkit-inner-spin-button {
  appearance: none;
}
.left-box .shop-left-sidebar .wrapper .price-input {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 17px;
}
.left-box .shop-left-sidebar .wrapper .price-input .field input {
  width: 100%;
  font-size: 17px;
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(var(--content-color), 0.3);
  border-radius: 4px;
  background-color: transparent;
  outline: none;
}
.left-box .shop-left-sidebar .wrapper .price-input .separator {
  padding: 0 10px;
}
.left-box .shop-left-sidebar .wrapper input[type=range]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background: rgba(var(--theme-color), 1);
  pointer-events: auto;
  box-shadow: 0 0 6px rgba(var(--black), 0.05);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
.left-box .shop-left-sidebar .wrapper input[type=range]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background: rgba(var(--theme-color), 1);
  pointer-events: auto;
  box-shadow: 0 0 6px rgba(var(--black), 0.05);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
.left-box .shop-left-sidebar .product-right-sidebar-list li {
  padding-top: 10px;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px dashed rgba(var(--dark-text), 0.15);
  margin-top: 0;
}
.left-box .shop-left-sidebar .product-right-sidebar-list li:first-child {
  padding-top: 0;
}
.left-box .shop-left-sidebar .product-right-sidebar-list li:last-child {
  border-bottom: none;
}
.left-box .shop-left-sidebar .product-right-sidebar-list li .offer-product {
  display: flex;
  align-items: center;
  gap: 10px;
}
.left-box .shop-left-sidebar .product-right-sidebar-list li .offer-product .offer-image {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--box-bg), 1);
  padding: 10px;
}
.left-box .shop-left-sidebar .product-right-sidebar-list li .offer-product .offer-image .img {
  width: 50px;
}
.left-box .shop-left-sidebar .product-right-sidebar-list li .offer-product .offer-details {
  width: calc(100% - 50px - 10px);
}
.left-box .shop-left-sidebar .product-right-sidebar-list li .offer-product .offer-details .name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.left-box .shop-left-sidebar .product-right-sidebar-list li .offer-product .offer-details span {
  color: rgba(var(--content-color), 1);
}
.left-box .post-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: calc(10px + 5 * (100vw - 320px) / 1600);
}
.left-box .post-wrap .post-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.left-box .post-wrap .post-box:hover .content-box h6 {
  color: rgba(var(--theme-color), 1);
}
.left-box .post-wrap .post-box .img-box .img {
  width: 90px;
  border-radius: 4px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}
.left-box .post-wrap .post-box .content-box {
  width: calc(100% - 90px - 10px);
}
.left-box .post-wrap .post-box .content-box h6 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.4s ease;
}
.left-box .post-wrap .post-box .content-box span {
  font-weight: 400;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
}

.blog-wrap .content-box {
  margin-top: 20px;
}
.blog-wrap .content-box p {
  margin-top: 10px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
  line-height: 1.6;
}
.blog-wrap .content-box .blog-title {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
  cursor: pointer;
}
.blog-wrap .content-box .blog-title:hover {
  color: rgba(var(--theme-color), 1);
}
.blog-wrap .content-box .share-box {
  margin-top: calc(15px + 5 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  border-top: rgba(var(--dark-text), 0.15);
}
.blog-wrap .content-box .share-box .sender-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-wrap .content-box .share-box .sender-box .avatar-img {
  width: calc(40px + 10 * (100vw - 320px) / 1600);
  height: calc(40px + 10 * (100vw - 320px) / 1600);
  border-radius: 100%;
  overflow: hidden;
}
.blog-wrap .content-box .share-box .sender-box .avatar-img img {
  width: 100%;
}
.blog-wrap .content-box .share-box .sender-box .sender-details h5 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
  margin-bottom: 0;
}
.blog-wrap .content-box .share-box .action-box {
  cursor: pointer;
}
.blog-wrap .content-box .share-box .action-box .replay-btn {
  background-color: rgba(var(--theme-color), 1);
  color: rgba(var(--white), 1);
}
.blog-wrap .content-box .share-box .action-box .like {
  color: rgba(var(--dark-text), 1);
}
.blog-wrap .content-box .comments-section h4 {
  font-size: calc(17px + 3 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.blog-wrap .content-box .replay-form .post-button {
  margin-left: auto;
}
@media only screen and (max-width: 475px) {
  .blog-wrap .content-box .replay-form .post-button {
    width: 100%;
    justify-content: center;
  }
}

.title-box h4 {
  font-size: calc(17px + 3 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}

.comments-section {
  margin-top: 30px;
}
.comments-section .comment-wrapper {
  margin-top: 20px;
}
.comments-section .comment-wrapper .comment-list li {
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  border-radius: 4px;
}
.comments-section .comment-wrapper .comment-list li.reply-li {
  margin-left: 30px;
}
[dir=rtl] .comments-section .comment-wrapper .comment-list li.reply-li {
  margin-left: 0;
  margin-right: 30px;
}
.comments-section .comment-wrapper .comment-list li .comment-box .img-box {
  width: 50px;
  height: 50px;
}
.comments-section .comment-wrapper .comment-list li .comment-box .img-box .img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.comments-section .comment-wrapper .comment-list li .comment-box .avatar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 60px - 16px);
  padding-bottom: 10px;
}
.comments-section .comment-wrapper .comment-list li .comment-box .avatar-content .user-info h5 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
  font-size: 16px;
}
.comments-section .comment-wrapper .comment-list li .comment-box .avatar-content .user-info span {
  color: rgba(var(--content-color), 1);
}
.comments-section .comment-wrapper .comment-list li .comment-box .avatar-content .action-box .replay-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(var(--content-color), 1);
}
.comments-section .comment-wrapper .comment-list li .comment-box .avatar-content .action-box .replay-btn i {
  font-size: 18px;
}
.comments-section .comment-wrapper .comment-list li .comment-box p {
  margin-top: 10px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
}

.replay-section {
  margin-top: 15px;
}
.replay-section .theme-form .input-box .form-check-label {
  margin-bottom: 5px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.replay-section .theme-form .input-box .form-control {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  padding: calc(10px + 2 * (100vw - 320px) / 1600);
  box-shadow: none;
  border-color: rgba(var(--dashed-line), 1);
  background-color: rgba(var(--box-bg), 1);
}
.replay-section .theme-form .input-box .form-control::placeholder {
  color: rgb(136, 136, 136);
}

.blog-box {
  padding: 0;
  border: none;
  border-radius: 8px;
  transition: all 0.5s ease-in-out;
}
.blog-box .img-box {
  border-radius: 10px;
}
.blog-box .blog-image .bg-size {
  transition: 0.4s ease;
  border-radius: 8px;
}
.blog-box .blog-details h5 {
  transition: 0.4s ease;
}
.blog-box:hover .blog-image .bg-size {
  transform: scale(1.1);
  transition: 0.4s ease;
}
.blog-box:hover .blog-details h5 {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
}
.blog-box.list-box {
  display: flex;
  padding: 0;
  background-color: rgba(var(--box-bg), 1);
  border: none;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 768px) {
  .blog-box.list-box {
    display: block;
  }
}
.blog-box.list-box .blog-image .bg-size {
  transition: 0.4s ease;
}
.blog-box.list-box:hover .blog-image .bg-size {
  transform: scale(1.1);
  transition: 0.4s ease;
}
.blog-box.list-box:hover .blog-details h5 a {
  color: rgba(var(--theme-color), 1);
}
.blog-box.list-box .blog-image {
  cursor: pointer;
  transform: scale(1);
  overflow: hidden;
  border-radius: 8px;
  width: 45%;
}
@media (max-width: 768px) {
  .blog-box.list-box .blog-image {
    width: 100%;
  }
}
.blog-box.list-box .blog-image .img {
  transition: all 0.5s ease-in-out;
}
.blog-box.list-box .blog-details {
  padding: calc(8px + 12 * (100vw - 320px) / 1600);
  width: calc(100% - 300px - (8px + 7 * (100vw - 320px) / 1600));
}
@media (max-width: 768px) {
  .blog-box.list-box .blog-details {
    width: 100%;
    padding: calc(10px + 10 * (100vw - 320px) / 1600);
  }
}
.blog-box.list-box .blog-details h6 {
  margin-top: 18px;
  color: rgba(var(--dark-text), 1);
}
.blog-box.list-box .blog-details h5 {
  padding-bottom: 0;
}
.blog-box.list-box .blog-details p {
  padding-top: calc(5px + 5 * (100vw - 320px) / 1600);
}
.blog-box .blog-image {
  transform: scale(1);
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
}
.blog-box .blog-image .img {
  transition: all 0.5s ease-in-out;
  width: 100%;
  object-fit: contain;
  border-radius: 2px;
}
.blog-box .blog-details {
  padding-top: calc(10px + 5 * (100vw - 320px) / 1600);
}
.blog-box .blog-details h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--dark-text), 1);
}
.blog-box .blog-details h5 {
  padding-bottom: 4px;
  font-weight: 500;
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.blog-box .blog-details h5 a {
  color: rgba(var(--dark-text), 1);
  transition: 0.4s ease;
}
.blog-box .blog-details h5 a:hover {
  color: rgba(var(--theme-color), 1);
  transition: 0.4s ease;
}
.blog-box .blog-details p {
  padding-top: calc(5px + 5 * (100vw - 320px) / 1600);
  font-weight: 400;
  font-size: 14px;
  color: rgba(var(--content-color), 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.6;
}

/*========================
  5.4  cart style start
==========================*/
.quantity-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(10px + 270 * (100vw - 320px) / 1600);
}
@media (max-width: 425px) {
  .quantity-box {
    display: block !important;
    text-align: center;
  }
}
.quantity-box .plus-minus {
  border: 1px solid rgba(var(--dark-text), 0.15);
  padding: 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: rgba(var(--box-bg), 1);
  text-align: center;
}
.quantity-box .plus-minus input {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: calc(15px + 20 * (100vw - 320px) / 1600);
  text-align: center;
}
.quantity-box .plus-minus i {
  color: rgba(var(--dark-text), 1);
}
.quantity-box .plus-minus i.sub {
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
}
[dir=rtl] .quantity-box .plus-minus i.sub {
  padding-left: 5px;
  padding-right: unset;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
  border-right: unset;
}
.quantity-box .plus-minus i.add {
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
}
[dir=rtl] .quantity-box .plus-minus i.add {
  padding-right: 5px;
  padding-left: unset;
  border-left: unset;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
}
.quantity-box .remove {
  text-decoration: underline;
}

.order-summery-section {
  z-index: 0;
}
.order-summery-section.sticky-top {
  top: 30px;
}
.order-summery-section .checkout-detail {
  position: relative;
  background-color: rgba(var(--white), 1);
  padding: calc(15px + 5 * (100vw - 320px) / 1600) calc(15px + 5 * (100vw - 320px) / 1600) calc(20px + 10 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}
.order-summery-section .checkout-detail .cart-address-box {
  display: flex;
  gap: 10px;
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  border-radius: 6px;
}
.order-summery-section .checkout-detail .cart-address-box .add-img .img {
  width: calc(60px + 20 * (100vw - 320px) / 1600);
  height: calc(60px + 20 * (100vw - 320px) / 1600);
  border-radius: 4px;
  object-fit: cover;
}
.order-summery-section .checkout-detail .cart-address-box .add-content {
  width: calc(100% - (60px + 20 * (100vw - 320px) / 1600) - 10px);
  position: relative;
}
.order-summery-section .checkout-detail .cart-address-box .add-content .deliver-place {
  font-weight: 600;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.order-summery-section .checkout-detail .cart-address-box .add-content .change-add {
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
}
.order-summery-section .checkout-detail .cart-address-box .add-content .address {
  width: 100%;
  line-height: 1.2;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
}
.order-summery-section .checkout-detail .checkout-title {
  padding-bottom: 10px;
}
.order-summery-section .checkout-detail .promo-code .code-form-control {
  position: relative;
  padding: calc(6px + 4 * (100vw - 320px) / 1600);
  border-radius: 6px;
  font-size: 16px;
  border: 1px solid rgba(var(--dark-text), 0.15);
  padding-right: 105px;
  box-shadow: none;
}
[dir=rtl] .order-summery-section .checkout-detail .promo-code .code-form-control {
  padding-left: 105px;
  padding-right: unset;
}
.order-summery-section .checkout-detail .promo-code .code-form-control::placeholder {
  color: rgba(var(--black), 0.3);
}
.order-summery-section .checkout-detail .promo-code .apply-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: calc(8px + 2 * (100vw - 320px) / 1600) calc(10px + 15 * (100vw - 320px) / 1600);
  border-radius: 6px;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
}
[dir=rtl] .order-summery-section .checkout-detail .promo-code .apply-btn {
  right: unset;
  left: 0;
}
.order-summery-section .checkout-detail .bill-details-title {
  padding: calc(10px + 5 * (100vw - 991px) / 929) 0;
}
.order-summery-section .checkout-detail .sub-total {
  display: flex;
  justify-content: space-between;
  padding-bottom: calc(5px + 5 * (100vw - 991px) / 929);
}
.order-summery-section .checkout-detail .sub-total h6 {
  color: rgba(var(--dark-text), 1);
}
.order-summery-section .checkout-detail h5 {
  color: rgba(var(--dark-text), 1);
}
.order-summery-section .checkout-detail .delivery-info {
  width: 70%;
  margin-bottom: 15px;
  line-height: 1.2;
}
.order-summery-section .checkout-detail .grand-total {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(var(--dark-text), 0.15);
  padding: 10px 0 0;
}
.order-summery-section .checkout-detail .grand-total h5 {
  color: rgba(var(--dark-text), 1);
}
.order-summery-section .checkout-detail .grand-total .amount {
  color: rgba(var(--success-color), 1);
}
.order-summery-section .checkout-detail .restaurant-btn {
  padding: calc(10px + 5 * (100vw - 320px) / 1600) 0;
  margin-top: calc(10px + 20 * (100vw - 320px) / 1600);
  text-transform: uppercase;
}
.order-summery-section .checkout-detail .dots-design {
  position: absolute;
  bottom: -1px;
  width: 105%;
  left: 50%;
  transform: translateX(-50%);
}

/*=====================
  5.5  checkout style start
==========================*/
.empty-cart-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.empty-cart-image h2 {
  margin-top: calc(20px + 20 * (100vw - 320px) / 1600);
  font-weight: 600;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
  text-align: center;
  color: rgba(var(--dark-text), 1);
}
.empty-cart-image h5 {
  margin-top: calc(4px + 4 * (100vw - 320px) / 1600);
  font-weight: 400;
  text-align: center;
  color: rgba(var(--content-color), 1);
}
.empty-cart-image .restaurant-btn {
  padding: calc(10px + 5 * (100vw - 320px) / 1600) 25px;
  margin-top: calc(10px + 30 * (100vw - 320px) / 1600);
  text-transform: uppercase;
}

.layout-sec {
  margin-top: 20px;
}
.layout-sec .order-summery-section {
  z-index: 0;
}
.layout-sec .order-summery-section.sticky-top {
  top: 30px;
}
.layout-sec .order-summery-section .checkout-detail {
  position: relative;
  background-color: rgba(var(--white), 1);
  padding: calc(15px + 5 * (100vw - 320px) / 1600) calc(15px + 5 * (100vw - 320px) / 1600) calc(20px + 10 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}
.layout-sec .order-summery-section .checkout-detail li {
  width: 100%;
}
.layout-sec .order-summery-section .checkout-detail li + li {
  border-top: 1px solid rgba(var(--dashed-line), 1);
  padding-top: 10px;
  margin-top: 6px;
}
.layout-sec .order-summery-section .checkout-detail .cart-address-box {
  display: flex;
  gap: 10px;
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 6px;
}
.layout-sec .order-summery-section .checkout-detail .cart-address-box .add-img .img {
  width: calc(60px + 20 * (100vw - 320px) / 1600);
  height: calc(60px + 20 * (100vw - 320px) / 1600);
  border-radius: 4px;
}
.layout-sec .order-summery-section .checkout-detail .cart-address-box .add-content {
  width: calc(100% - (60px + 20 * (100vw - 320px) / 1600) - 10px);
  position: relative;
}
.layout-sec .order-summery-section .checkout-detail .cart-address-box .add-content .deliver-place {
  font-weight: 600;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.layout-sec .order-summery-section .checkout-detail .cart-address-box .add-content .deliver-place .ri-check-line {
  background-color: #60b246;
  border-radius: 100%;
  color: white;
  font-size: 13px;
  padding: 2px;
  margin-left: 6px;
}
.layout-sec .order-summery-section .checkout-detail .cart-address-box .add-content .change-add {
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
}
.layout-sec .order-summery-section .checkout-detail .cart-address-box .add-content .address {
  width: 100%;
  line-height: 1.4;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .checkout-title {
  padding-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  background-color: rgba(var(--white), 1);
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box:first-child {
  padding-top: 0;
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-img .img {
  width: calc(90px + 60 * (100vw - 320px) / 1600);
  height: calc(90px + 45 * (100vw - 320px) / 1600);
  object-fit: cover;
  border-radius: 4px;
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content {
  width: 100%;
  position: relative;
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content h5 {
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .place {
  position: relative;
  padding-left: calc(7px + 5 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
  border-bottom: none;
}
[dir=rtl] .layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .place {
  padding-left: unset;
  padding-right: calc(7px + 5 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .place::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--content-color), 1);
  border-radius: 100%;
}
[dir=rtl] .layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .place::after {
  left: unset;
  right: -5px;
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus {
  border: 1px solid rgba(var(--dashed-line), 1);
  padding: 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: rgba(var(--box-bg), 1);
  text-align: center;
  width: calc(75px + 15 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus input {
  background-color: transparent;
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: calc(20px + 15 * (100vw - 320px) / 1600);
  text-align: center;
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i {
  color: rgba(var(--dark-text), 1);
  cursor: pointer;
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.sub {
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.sub {
  padding-right: unset;
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dashed-line), 1);
  border-right: unset;
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.add {
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.add {
  padding-left: unset;
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dashed-line), 1);
  border-left: unset;
}
.layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .product-price {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .promo-code {
  margin: 10px 0 0;
}
.layout-sec .order-summery-section .checkout-detail .promo-code .code-form-control {
  position: relative;
  padding: calc(6px + 4 * (100vw - 320px) / 1600);
  border-radius: 6px;
  font-size: 16px;
  border: 1px solid rgba(var(--dashed-line), 1);
  padding-right: 105px;
  box-shadow: none;
}
[dir=rtl] .layout-sec .order-summery-section .checkout-detail .promo-code .code-form-control {
  padding-left: 105px;
  padding-right: calc(6px + 4 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .promo-code .code-form-control::placeholder {
  color: rgba(var(--black), 0.3);
}
.layout-sec .order-summery-section .checkout-detail .promo-code .apply-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-top: 10px;
  padding: calc(8px + 2 * (100vw - 320px) / 1600) calc(10px + 15 * (100vw - 320px) / 1600);
  border-radius: 6px;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
}
[dir=rtl] .layout-sec .order-summery-section .checkout-detail .promo-code .apply-btn {
  left: 0;
  right: unset;
}
.layout-sec .order-summery-section .checkout-detail .bill-details-title {
  padding: calc(10px + 15 * (100vw - 320px) / 1600) 0 calc(10px + 5 * (100vw - 320px) / 1600) 0;
}
.layout-sec .order-summery-section .checkout-detail .sub-total {
  display: flex;
  justify-content: space-between;
  padding-bottom: calc(5px + 5 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .sub-total h6 {
  color: rgba(var(--dark-text), 1);
}
.layout-sec .order-summery-section .checkout-detail h5 {
  color: rgba(var(--dark-text), 1);
}
.layout-sec .order-summery-section .checkout-detail .delivery-info {
  width: 70%;
  margin-bottom: 15px;
  line-height: 1.2;
}
.layout-sec .order-summery-section .checkout-detail .grand-total {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(var(--dark-text), 0.15);
  padding: 10px 0 0;
}
.layout-sec .order-summery-section .checkout-detail .grand-total h6 {
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
}
.layout-sec .order-summery-section .checkout-detail .grand-total h5 {
  color: rgba(var(--dark-text), 1);
}
.layout-sec .order-summery-section .checkout-detail .grand-total .amount {
  color: rgba(var(--success-color), 1);
}
.layout-sec .order-summery-section .checkout-detail .restaurant-btn {
  padding: calc(10px + 5 * (100vw - 320px) / 1600) 0;
  margin-top: calc(10px + 20 * (100vw - 320px) / 1600);
  text-transform: uppercase;
}
.layout-sec .order-summery-section .checkout-detail .dots-design {
  position: absolute;
  bottom: -1px;
  width: 105%;
  left: 50%;
  transform: translateX(-50%);
}

.process-section {
  padding: calc(10px + 15 * (100vw - 320px) / 1600) calc(10px + 20 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.process-section .process-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.process-section .process-list li {
  text-align: center;
  position: relative;
}
.process-section .process-list li.active .process-icon {
  border: 1px dashed rgba(var(--theme-color), 1);
}
.process-section .process-list li.active .icon {
  width: calc(25px + 15 * (100vw - 320px) / 1600);
}
.process-section .process-list li.active h6 {
  color: rgba(var(--theme-color), 1);
  font-weight: 500;
}
.process-section .process-list li.active::before {
  border-bottom-color: rgba(var(--theme-color), 1);
}
.process-section .process-list li.done .process-icon {
  border: 1px solid rgba(var(--theme-color), 1);
}
.process-section .process-list li.done .icon {
  width: calc(25px + 15 * (100vw - 320px) / 1600);
}
.process-section .process-list li.done h6 {
  color: rgba(var(--theme-color), 1);
}
.process-section .process-list li.done::before {
  border-bottom-color: rgba(var(--theme-color), 1);
}
.process-section .process-list li:first-child::before {
  content: none;
}
.process-section .process-list li::before {
  content: "";
  position: absolute;
  right: 40px;
  top: 30%;
  width: 290px;
  height: 1px;
  border: 1px dashed rgba(var(--dashed-line), 1);
}
[dir=rtl] .process-section .process-list li::before {
  left: 40px;
  right: unset;
}
@media (max-width: 1623px) {
  .process-section .process-list li::before {
    width: 255px;
  }
}
@media (max-width: 1399px) {
  .process-section .process-list li::before {
    width: 210px;
  }
}
@media (max-width: 1199px) {
  .process-section .process-list li::before {
    width: 170px;
  }
}
@media (max-width: 766px) {
  .process-section .process-list li::before {
    width: 120px;
  }
}
@media (max-width: 470px) {
  .process-section .process-list li::before {
    width: 100px;
  }
}
@media (max-width: 410px) {
  .process-section .process-list li::before {
    width: 80px;
  }
}
@media (max-width: 345px) {
  .process-section .process-list li::before {
    width: 60px;
  }
}
.process-section .process-list li a {
  display: block;
}
.process-section .process-list li .process-icon {
  width: calc(40px + 12 * (100vw - 320px) / 1600);
  height: calc(40px + 12 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0 auto;
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 100%;
  background-color: rgba(var(--white), 1);
  position: relative;
  z-index: 1;
}
.process-section .process-list li .icon {
  width: calc(25px + 15 * (100vw - 320px) / 1600);
}
.process-section .process-list li h6 {
  color: rgba(var(--content-color), 1);
  margin-top: 4px;
}

.account-part {
  padding: calc(20px + 60 * (100vw - 320px) / 1600) 20px;
  text-align: center;
  margin-top: calc(20px + 20 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.account-part .title {
  margin-top: calc(20px + 60 * (100vw - 320px) / 1600);
}
.account-part .title h3 {
  position: relative;
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.account-part .title h3::before {
  content: none;
}
.account-part .title h3::after {
  content: none;
}
.account-part .title .loader-line {
  width: 90px;
  height: 2px;
  overflow: hidden;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 20px;
  position: relative;
  margin: 0 auto;
}
.dark .account-part .title .loader-line {
  background-color: #424242;
}
.account-part .title .loader-line::before {
  content: "";
  position: absolute;
  width: 90px;
  height: 3px;
  top: 0px;
  left: -60px;
  background-color: rgba(var(--box-bg), 1);
}
[dir=rtl] .account-part .title .loader-line::before {
  left: unset;
  right: 0;
}
.account-part p {
  margin-top: calc(5px + 5 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.account-part .account-btn {
  margin-top: calc(10px + 30 * (100vw - 320px) / 1600);
}
.account-part.confirm-part {
  padding: calc(20px + 20 * (100vw - 320px) / 1600) 20px;
}
.account-part.confirm-part h3 {
  margin-top: calc(20px + 20 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}

.address-section {
  margin-top: calc(20px + 20 * (100vw - 320px) / 1600);
  padding: calc(20px + 20 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.address-section .address-box {
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 6px;
  height: 100%;
}
.address-section .address-box.new-address-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 15px;
}
@media (max-width: 767px) {
  .address-section .address-box.new-address-box {
    padding: 0;
    border: none;
  }
}
@media (max-width: 767px) {
  .address-section .address-box.new-address-box .new-address-btn {
    display: block;
    width: 100%;
  }
}
.address-section .address-box .address-title {
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.address-section .address-box .address-title h6 {
  font-weight: 500;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
}
.address-section .address-box .address-title .icon {
  font-size: 22px;
  color: rgba(var(--theme-color), 1);
}
.address-section .address-box .address-title .edit-btn {
  font-size: 14px;
  background-color: rgba(var(--theme-color), 0.12);
  padding: 2px 14px;
  border-radius: 20px;
  color: rgba(var(--theme-color2), 1);
}
.address-section .address-box .address-details {
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
}
.address-section .address-box .address-details h6 {
  color: rgba(var(--dark-text), 1);
  font-weight: 400;
}
.address-section .address-box .address-details .phone-number {
  margin-top: 12px;
  color: rgba(var(--content-color), 1);
}
.address-section .address-box .address-details .option-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px dashed rgba(var(--dashed-line), 1);
}

.payment-section {
  margin-top: calc(20px + 20 * (100vw - 320px) / 1600);
  padding: calc(20px + 20 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.payment-section .payment-list-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--dark-text), 0.15);
  padding: 10px calc(10px + 10 * (100vw - 320px) / 1600);
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.payment-section .payment-list-box .card-name {
  border: none;
  background-color: transparent;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  font-weight: 500;
  line-height: 1;
}
.payment-section .payment-list-box .form-check {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.payment-section .payment-list-box .form-check .form-check-input {
  width: 20px;
  height: 20px;
}
.payment-section .payment-list-box .form-check .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(var(--dark-text), 0.15);
}
.payment-section .payment-list-box .form-check .form-check-input:checked {
  background-color: rgba(var(--white), 1);
  border-color: rgba(var(--dark-text), 0.15);
  background-image: none;
}
.payment-section .payment-list-box .form-check .form-check-input:checked::after {
  content: "\eb7c";
  font-family: remixicon !important;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(var(--theme-color), 1);
}

.sm-size {
  width: 50px !important;
  height: auto !important;
}

/*========================
  5.6  coupon style start
==========================*/
.coupon-box {
  position: relative;
  padding: calc(15px + 15 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--black), 0.12);
  background: rgba(var(--white), 1);
  border-radius: 6px;
  width: 100%;
}
.coupon-box .coupon-img {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 2px;
}
.coupon-box .coupon-img .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.coupon-box .coupon-name {
  display: flex;
  align-items: center;
  gap: 12px;
}
.coupon-box .coupon-name img {
  border-radius: 6px;
}
.coupon-box .coupon-name .coupon-name-content {
  width: calc(100% - 60px - 12px);
}
.coupon-box .coupon-name .coupon-name-content h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.coupon-box .coupon-name .coupon-name-content h6 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.coupon-box .bank-name {
  width: calc(100% - 54px - 12px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.coupon-box .bank-name h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.coupon-box .bank-name h6 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.coupon-box .coupon-content {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1600);
}
.coupon-box .coupon-content.bank-offer-content {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.coupon-box .coupon-content.bank-offer-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.coupon-box .coupon-content.bank-offer-content h6 {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.coupon-box .coupon-content .coupon-apply {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: calc(10px + 10 * (100vw - 320px) / 1600);
  border-top: 1px dashed rgba(var(--black), 0.12);
}
.coupon-box .coupon-content .coupon-apply .copy-btn {
  padding: calc(8px + 2 * (100vw - 320px) / 1600);
  line-height: 1;
  font-size: 14px;
}
@media screen and (max-width: 1336px) and (min-width: 1399px) {
  .coupon-box .coupon-content .coupon-apply .copy-btn {
    padding: 7px;
  }
}
.coupon-box .coupon-content p {
  padding: calc(5px + 5 * (100vw - 320px) / 1600) 0 calc(10px + 10 * (100vw - 320px) / 1600);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.coupon-box .coupon-bottom {
  position: absolute;
  width: 95%;
  left: 10px;
  bottom: -1px;
}
[dir=rtl] .coupon-box .coupon-bottom {
  left: unset;
  right: 10px;
}
.coupon-box .coupon-right {
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  height: 90%;
}
[dir=rtl] .coupon-box .coupon-right {
  right: unset;
  left: -2px;
  transform: scaleX(-1) translateY(-50%);
}

.deal-box {
  padding: calc(10px + 8 * (100vw - 320px) / 1600);
  border: 1px dashed rgba(var(--white), 0.1);
  border-radius: 18px;
  background-color: rgba(var(--box-bg), 1);
  transition: all 0.5s ease;
  margin-top: 20px;
}
.deal-box .deal-img img {
  border-radius: 16px 16px;
}
.deal-box .deal-content {
  text-align: center;
}
.deal-box h3 {
  font-size: calc(18px + 9 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
  margin-top: calc(8px + 5 * (100vw - 320px) / 1600);
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  font-weight: 600;
}
.deal-box h6 {
  font-size: calc(14px + 1 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 0.8);
  font-weight: 400;
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  letter-spacing: 0.8px;
}
.deal-box h5 {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: #999;
  margin-top: calc(3px + 3 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.deal-box .coupon-code {
  display: block;
  border: rgba(var(--theme-color), 0.12);
  background: linear-gradient(to right, rgba(var(--theme-color), 0.1), rgba(var(--theme-color2), 0.1));
  border-radius: 10px;
  padding: calc(8px + 2 * (100vw - 320px) / 1600);
  margin-top: 5px;
}
.deal-box .coupon-code span {
  text-transform: uppercase;
  letter-spacing: 0.5em;
  font-weight: 600;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.deal-box .brand-logo {
  width: calc(65px + 15 * (100vw - 320px) / 1600);
  height: calc(65px + 15 * (100vw - 320px) / 1600);
  padding: 6px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  margin-top: -50px;
  position: relative;
  background-color: rgba(var(--white), 1);
}
.deal-box .brand-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.deal-box:hover {
  box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.11);
}

/*========================
   5.7 banner style start
==========================*/
.banner-section {
  background-color: rgba(var(--box-bg), 1);
}
.banner-section .banner-img {
  border-radius: 12px;
  object-fit: cover;
  border: 1px solid rgba(var(--dark-text), 0.15);
}

.product-banner {
  display: inline-block;
}
.product-banner .banner-img {
  border-radius: 12px;
}
@media (max-width: 1200px) {
  .product-banner .banner-img {
    display: none;
  }
}

/*========================
   5.8 Home-section style start
==========================*/
.home-wrapper {
  background-image: url(../images/background/bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 1;
  position: relative;
  padding: calc(95px + 85 * (100vw - 320px) / 1600) 0 calc(60px + 40 * (100vw - 320px) / 1600) !important;
}
.home-wrapper .right-vector {
  position: absolute;
  top: -62%;
  right: 0;
}
@media (max-width: 991px) {
  .home-wrapper .right-vector {
    display: none;
  }
}
.home-wrapper.home2 {
  padding: calc(45px + 35 * (100vw - 320px) / 1600) 0 calc(45px + 25 * (100vw - 320px) / 1600) !important;
}
.home-wrapper.home2 .row {
  padding-block: calc(40px + 100 * (100vw - 320px) / 1600);
}
.home-wrapper.home2 .search-section {
  width: 100%;
}
.home-wrapper.home2 .auth-form .form-input .form-control.search {
  width: 100%;
}
.home-wrapper.home2 h2 {
  font-size: calc(22px + 26 * (100vw - 320px) / 1600);
  text-align: left;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 0;
}
[dir=rtl] .home-wrapper.home2 h2 {
  text-align: right;
}
@media (max-width: 992px) {
  .home-wrapper.home2 h2 {
    line-height: 1.2;
  }
}
@media (max-width: 991px) {
  .home-wrapper {
    height: auto;
    padding: calc(120px + 34 * (100vw - 320px) / 1600) 0 calc(80px + 20 * (100vw - 320px) / 1600) !important;
  }
}
.home-wrapper h1 {
  text-align: center;
  font-style: italic;
  font-size: calc(40px + 46 * (100vw - 320px) / 1600);
  line-height: 1;
  letter-spacing: 1.5px;
  margin-top: calc(0px + 71 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  display: inline-block;
  letter-spacing: 0.06em;
  margin-top: -11px;
}
.home-wrapper h2 {
  margin-top: 5px;
  text-align: center;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.6px;
  color: #fff;
}
.home-wrapper .search-section {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  margin-top: calc(15px + 25 * (100vw - 320px) / 1600);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  z-index: 1;
}
.home-wrapper .search-section .theme-btn {
  padding: calc(8px + 7 * (100vw - 320px) / 1600) calc(20px + 30 * (100vw - 320px) / 1600);
  font-size: calc(15px + 1 * (100vw - 320px) / 1600);
}
@media (max-width: 425px) {
  .home-wrapper .search-section .theme-btn {
    margin-top: 10px;
  }
}
.home-wrapper .search-section .dropdown .place-btn {
  width: 216px;
  justify-content: space-between;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  gap: calc(10px + 30 * (100vw - 320px) / 1600);
  border-radius: 6px;
  color: rgba(var(--white), 1);
  backdrop-filter: blur(25px);
  color: rgba(var(--white), 1);
  background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, 0.06) 100%), rgba(226, 232, 255, 0.02);
  border: 1px solid rgba(226, 232, 255, 0.1);
}
@media (max-width: 991px) {
  .home-wrapper .search-section .dropdown .place-btn {
    width: 100%;
  }
}
.home-wrapper .search-section .dropdown .place-btn.show {
  border: transparent;
}
.home-wrapper .search-section .dropdown .place-btn:active {
  border: none;
}
.home-wrapper .search-section .dropdown .dropdown-menu {
  width: 100%;
  background-color: rgba(var(--white), 1);
}
.home-wrapper .search-section .dropdown .dropdown-menu.show {
  display: block;
}
.home-wrapper .search-section .dropdown .dropdown-menu li {
  width: 100%;
}
.home-wrapper .search-section .dropdown .dropdown-menu li .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px 10px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
[dir=rtl] .home-wrapper .search-section .dropdown .dropdown-menu li .dropdown-item {
  padding: 0 0 10px 10px;
}
.home-wrapper .search-section .dropdown .dropdown-menu li .dropdown-item:last-child {
  padding-bottom: 0;
}
.home-wrapper .search-section .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: rgba(var(--white), 0.08);
}
.home-wrapper .search-section .dropdown .dropdown-menu li .dropdown-item .place-icon {
  height: 30px;
  width: 30px;
  object-fit: contain;
}
.home-wrapper .search-section .search-head .form-group .form-input .search-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: rgba(var(--theme-color2), 1);
  font-size: calc(20px + 2 * (100vw - 320px) / 1600);
}
[dir=rtl] .home-wrapper .search-section .search-head .form-group .form-input .search-icon {
  left: unset;
  right: 15px;
}
.home-wrapper .home-features-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: calc(10px + 8 * (100vw - 320px) / 1600);
  margin-top: calc(20px + 20 * (100vw - 320px) / 1600);
  position: relative;
  z-index: 0;
}
.home-wrapper .home-features-list li {
  position: relative;
  width: calc(227px + 20 * (100vw - 320px) / 1600);
  display: inline-flex;
  backdrop-filter: blur(2px);
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  border-radius: 19px;
  background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, 0.06) 100%), rgba(226, 232, 255, 0.02);
  border: 1px solid rgba(226, 232, 255, 0.1);
}
.home-wrapper .home-features-list li .home-features-box {
  display: flex;
  align-items: center;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
}
.home-wrapper .home-features-list li .home-features-box h6 {
  font-weight: 400;
  color: #fff;
  width: calc(100% - 50px);
  text-align: left;
}
[dir=rtl] .home-wrapper .home-features-list li .home-features-box h6 {
  text-align: right;
}
.home-wrapper .home-features-list li .dots-chain3 {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 8px;
  height: 8px;
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
}
[dir=rtl] .home-wrapper .home-features-list li .dots-chain3 {
  left: 20px;
  right: unset;
}
.home-wrapper .home-features-list li .dots-chain3::before {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 30px;
  height: 50px;
  border-right: 2px dashed rgba(var(--white), 1);
  transform: rotate(90deg);
}
[dir=rtl] .home-wrapper .home-features-list li .dots-chain3::before {
  left: unset;
  right: 30px;
}
@media (max-width: 991px) {
  .home-wrapper .home-features-list li .dots-chain3::before {
    display: none;
  }
}
@media (max-width: 991px) {
  .home-wrapper .home-features-list li .dots-chain3 {
    display: none;
  }
}
.home-wrapper .home-features-list li .dots-chain4 {
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 8px;
  height: 8px;
  background-color: rgba(var(--white), 1);
  border-radius: 100%;
}
[dir=rtl] .home-wrapper .home-features-list li .dots-chain4 {
  left: 20px;
  right: unset;
}
.home-wrapper .home-features-list li .dots-chain4::before {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 30px;
  height: 50px;
  border-right: 2px dashed rgba(var(--white), 1);
  transform: rotate(90deg);
}
[dir=rtl] .home-wrapper .home-features-list li .dots-chain4::before {
  left: unset;
  right: 30px;
}
@media (max-width: 991px) {
  .home-wrapper .home-features-list li .dots-chain4::before {
    display: none;
  }
}
@media (max-width: 991px) {
  .home-wrapper .home-features-list li .dots-chain4 {
    display: none;
  }
}

.home3 .bg-home-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 576px) {
  .home3 .bg-home-img {
    object-fit: none;
    border-radius: 10px;
  }
}
.home3 .home-content {
  position: relative;
  padding: calc(33px + 37 * (100vw - 320px) / 1600) calc(20px + 50 * (100vw - 320px) / 1600);
}
.home3 .home-content p {
  color: rgba(255, 255, 255, 0.78);
  margin-bottom: calc(15px + 15 * (100vw - 320px) / 1600);
  width: 81%;
  line-height: 1.6;
}
@media (max-width: 767px) {
  .home3 .home-content p {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .home3 .home-content p {
    display: none;
  }
}
.home3 .home-content label {
  padding: 4px calc(10px + 14 * (100vw - 320px) / 1600);
  border-radius: 20px;
  margin-bottom: calc(8px + 8 * (100vw - 320px) / 1600);
  background: rgba(var(--theme-color), 0.3);
  color: rgba(var(--theme-color));
  font-weight: 500;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  width: max-content;
}
.home3 .home-content h2 {
  color: #fff;
  margin-bottom: calc(10px + 8 * (100vw - 320px) / 1600);
  line-height: 1.4;
  font-size: calc(18px + 18 * (100vw - 320px) / 1600);
}
.home3 .home-content .btn {
  padding: calc(8px + 4 * (100vw - 320px) / 1600) calc(20px + 25 * (100vw - 320px) / 1600);
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  text-transform: uppercase;
}
.home3 .home-content .auth-form {
  margin-bottom: calc(10px + 30 * (100vw - 320px) / 1600);
}
@media (max-width: 576px) {
  .home3 .home-content .auth-form {
    width: 100%;
  }
}
.home3 .home-content .auth-form .form-group .form-input input {
  width: 100%;
}
@media (max-width: 576px) {
  .home3 .home-content .auth-form .form-group .form-input input {
    padding-block: 8px;
  }
}
.home3 .home-left-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home3 .home-right {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  justify-content: center;
}
@media (max-width: 1500px) {
  .home3 .home-right .base-phone {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .home3 .home-right .base-phone {
    width: 100%;
  }
}
.home3 .home-right .food1,
.home3 .home-right .food2,
.home3 .home-right .food3 {
  position: absolute;
}
.home3 .home-right .food1 {
  left: 4%;
  top: 10%;
  width: 28%;
}
@media (max-width: 1400px) {
  .home3 .home-right .food1 {
    top: 22%;
  }
}
@media (max-width: 991px) {
  .home3 .home-right .food1 {
    top: 32%;
  }
}
@media (max-width: 767px) {
  .home3 .home-right .food1 {
    top: 46%;
    width: 33%;
    left: -2%;
  }
}
.home3 .home-right .food2 {
  top: -27%;
  right: 46%;
  animation: shimmy 2s infinite;
  animation-direction: alternate;
  width: 23%;
}
@media (max-width: 1400px) {
  .home3 .home-right .food2 {
    top: -10%;
  }
}
@media (max-width: 991px) {
  .home3 .home-right .food2 {
    top: -2%;
    right: 44%;
  }
}
@media (max-width: 767px) {
  .home3 .home-right .food2 {
    top: 16%;
    right: 39%;
    width: 29%;
  }
}
.home3 .home-right .food3 {
  top: 16%;
  right: 25%;
  width: 18%;
}
@media (max-width: 1400px) {
  .home3 .home-right .food3 {
    top: 26%;
  }
}
@media (max-width: 991px) {
  .home3 .home-right .food3 {
    top: 45%;
  }
}
@media (max-width: 767px) {
  .home3 .home-right .food3 {
    top: 48%;
    right: 16%;
    width: 21%;
  }
}

.home4 {
  background-color: #242321;
  padding-top: calc(60px + 100 * (100vw - 320px) / 1600);
}
.home4 .home-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .home4 .home-content {
    height: 100%;
  }
}
.home4 .home-content h1 {
  font-size: calc(25px + 30 * (100vw - 320px) / 1600);
  font-weight: 700;
  width: 90%;
  color: white;
}
@media (max-width: 768px) {
  .home4 .home-content h1 {
    width: 100%;
  }
}
.home4 .home-content h1 span {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
}
.home4 .home-content p {
  color: rgba(255, 255, 255, 0.78);
  margin-block: calc(10px + 15 * (100vw - 320px) / 1600) calc(15px + 15 * (100vw - 320px) / 1600);
  width: 80%;
  margin-inline: auto;
}
@media (max-width: 768px) {
  .home4 .home-content p {
    width: 100%;
  }
}
.home4 .home-content form {
  width: 100%;
}
.home4 .home-content form > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(10px + 5 * (100vw - 320px) / 1600);
}
.home4 .home-content .btn {
  padding: calc(10px + 4 * (100vw - 320px) / 1600) calc(18px + 12 * (100vw - 320px) / 1600);
  text-transform: uppercase;
  font-weight: 600;
}
.home4 .home-content .form-control {
  background-color: #f3f3f3;
  border: none;
  padding: calc(10px + 5 * (100vw - 320px) / 1600) calc(15px + 10 * (100vw - 320px) / 1600);
  width: 60%;
  background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, 0.1) 100%), rgba(226, 232, 255, 0.06);
  border-radius: 6px;
  border: 1px solid rgba(226, 232, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}
.home4 .home-content .form-control:focus {
  box-shadow: none;
}
.home4 .home-content .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.home4 .home-content .related-search {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1600);
  color: rgba(var(--white), 60%);
}
.home4 .home-content .related-search a {
  color: rgba(var(--white), 60%);
  text-decoration: underline;
}

.service-section .home-features-list {
  display: flex;
  justify-content: space-between;
  padding-bottom: calc(15px + 45 * (100vw - 320px) / 1600);
  border-bottom: 1px solid rgba(var(--black), 0.1);
}
.service-section .home-features-list .home-features-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
}
@media (max-width: 575px) {
  .service-section .home-features-list .home-features-box {
    justify-content: flex-start;
  }
}
.service-section .home-features-list .home-features-box img {
  background-color: rgba(var(--white), 1);
  padding: calc(8px + 4 * (100vw - 320px) / 1600);
  border-radius: 8px;
  width: calc(50px + 24 * (100vw - 320px) / 1600);
  height: calc(50px + 24 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--black), 0.1);
}
.service-section .home-features-list .home-features-box .home-features-content {
  width: calc(100% - (50px + 24 * (100vw - 320px) / 1600) - (10px + 10 * (100vw - 320px) / 1600));
}
.service-section .home-features-list .home-features-box .home-features-content h5 {
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
  font-weight: 500;
  margin-bottom: calc(3px + 3 * (100vw - 320px) / 1600);
}
.service-section .home-features-list .home-features-box .home-features-content h6 {
  color: rgba(var(--dark-text), 0.6);
  font-weight: 400;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}

.banner-section {
  background-color: rgba(var(--box-bg), 1);
}
.banner-section .banner-part .banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.brand-section {
  position: relative;
}
.brand-section.brand-style2 .position-relative {
  background-color: rgba(var(--box-bg), 1);
  padding: calc(12px + 8 * (100vw - 320px) / 1600);
  border-radius: 10px;
}
.brand-section.brand-style2 .brands-logo .food-brands {
  width: calc(50px + 10 * (100vw - 320px) / 1600);
  height: calc(60px + 10 * (100vw - 320px) / 1600);
  margin: 0 auto calc(8px + 6 * (100vw - 320px) / 1600);
}
.brand-section .item-4 {
  position: absolute;
  bottom: -35px;
  left: -40px;
  opacity: 0.6;
  z-index: -1;
}
[dir=rtl] .brand-section .item-4 {
  left: unset;
  right: -40px;
}
@media (max-width: 930px) {
  .brand-section .item-4 {
    display: none;
  }
}
.brand-section .brands-logo .brand-box {
  position: relative;
  transition: 0.4s ease;
}
.brand-section .brands-logo .brand-box::after {
  content: "";
  position: absolute;
  border-right: 1px solid rgba(var(--dashed-line), 1);
  width: 1px;
  height: 40%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.brand-section .brands-logo .brand-box:hover h4 {
  color: rgba(var(--theme-color), 1);
}
.brand-section .brands-logo .food-brands {
  position: relative;
  width: calc(60px + 40 * (100vw - 320px) / 1600);
  height: calc(60px + 40 * (100vw - 320px) / 1600);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin: 0 auto;
}
.brand-section .brands-logo .food-brands .brand-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.brand-section .brands-logo .food-brands:last-child::after {
  border-right: none;
}
.brand-section .brands-logo h4 {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(var(--dark-text), 1);
  transition: 0.4s ease;
  cursor: pointer;
  margin-top: calc(5px + 5 * (100vw - 320px) / 1600);
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.brand-section .swiper-wrapper .swiper-slide-active .brand-box::after {
  content: unset;
}
.brand-section .swiper-button-active .brand-section .brands-logo .brand-box::after {
  border-right: none;
}

.app-section {
  background-image: url(../images/background/app-bg.jpg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
[dir=rtl] .app-section .container > div {
  transform: scaleX(-1);
}
[dir=rtl] .app-section {
  transform: scaleX(-1);
}
@media (max-width: 991px) {
  .app-section {
    background-image: url(../images/background/sm-app-bg.jpg);
    padding-top: 0;
  }
}
.app-section .app-img .phone {
  width: 500px;
}
@media (max-width: 991px) {
  .app-section .app-img .phone {
    display: none;
  }
}
.app-section .app-content {
  padding-left: 154px;
}
[dir=rtl] .app-section .app-content {
  padding-left: 0;
  padding-right: 154px;
}
@media (max-width: 991px) {
  .app-section .app-content {
    padding-left: 0;
    padding-block: calc(20px + 20 * (100vw - 320px) / 1600);
    width: 100%;
  }
  [dir=rtl] .app-section .app-content {
    padding-left: unset;
    padding-right: 0;
  }
}
.app-section .app-content h2 {
  font-size: calc(24px + 12 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: 1.2;
  padding-bottom: calc(5px + 5 * (100vw - 320px) / 1600);
  color: #fff;
  position: relative;
  margin-bottom: calc(5px + 5 * (100vw - 320px) / 1600);
}
.app-section .app-content h2::after {
  content: "";
  position: absolute;
  width: 90px;
  height: 2px;
  bottom: 0px;
  left: 0;
  background-color: #fff;
}
[dir=rtl] .app-section .app-content h2::after {
  left: unset;
  right: 0;
}
.app-section .app-content h5 {
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
}
.app-section .app-content .app-buttons {
  padding-top: calc(20px + 25 * (100vw - 320px) / 1600);
}
.app-section .app-content .app-buttons .app-btn {
  width: calc(150px + 50 * (100vw - 320px) / 1600);
}

.app-container {
  padding-bottom: calc(30px + 50 * (100vw - 320px) / 1600);
}
.app-container .app-section {
  padding: calc(20px + 60 * (100vw - 320px) / 1600) calc(20px + 60 * (100vw - 320px) / 1600) 0;
  border-radius: calc(20px + 20 * (100vw - 320px) / 1600);
}
@media (max-width: 991px) {
  .app-container .app-section {
    padding-top: 0;
  }
}
.app-container .app-section .app-content {
  padding-left: calc(30px + 74 * (100vw - 320px) / 1600);
  margin-top: -80px;
}
[dir=rtl] .app-container .app-section .app-content {
  padding-left: 0;
  padding-right: calc(30px + 74 * (100vw - 320px) / 1600);
}
@media (max-width: 991px) {
  .app-container .app-section .app-content {
    margin-top: 0;
    padding-left: 0;
  }
  [dir=rtl] .app-container .app-section .app-content {
    padding-right: 0;
  }
}
.app-container .app-section .app-content h5 {
  width: 80%;
}
.app-container .app-section .app-img .phone {
  width: 410px;
}

.popular-restaurant {
  position: relative;
}
@media (max-width: 375px) {
  .popular-restaurant .theme-arrow .swiper-button-next,
  .popular-restaurant .theme-arrow .swiper-button-prev {
    display: none;
  }
}
.popular-restaurant .item-5 {
  position: absolute;
  top: 40px;
  right: -50px;
  opacity: 0.5;
}
[dir=rtl] .popular-restaurant .item-5 {
  left: -50px;
  right: unset;
}
@media (max-width: 991px) {
  .popular-restaurant .item-5 {
    display: none;
  }
}

.featured-restaurant {
  position: relative;
}
.featured-restaurant .item-1 {
  position: absolute;
  top: 10px;
  left: -40px;
}
[dir=rtl] .featured-restaurant .item-1 {
  left: unset;
  right: -40px;
}
@media (max-width: 1425px) {
  .featured-restaurant .item-1 {
    display: none;
  }
}

.banner-part .banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.banner-part a {
  display: block;
  width: 100%;
}

.main-wrapper .left-sidebar {
  position: fixed;
  width: 320px;
  background-color: #232220;
  height: 100vh;
  padding: 20px;
}
.main-wrapper .left-sidebar .top-panel {
  padding-bottom: 15px;
  text-align: center;
}
.main-wrapper .left-sidebar .menu-listing li {
  display: block;
  padding-bottom: 10px;
}
.main-wrapper .left-sidebar .menu-listing li a {
  font-size: 18px;
  color: white;
}

.shop-sidebar {
  width: calc(300px + 20 * (100vw - 991px) / 929);
  background-image: url(../images/sidebanner.png);
  border-right: 1px solid rgba(var(--dashed-line), 1);
  background-position: bottom;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(32, 32, 32);
  overflow: auto;
  padding: 25px;
  height: 100vh;
  z-index: 0;
  box-shadow: 0 0 21px 0 rgba(61, 61, 61, 0.1);
  transition: 0.4s ease;
}
@media (max-width: 991px) {
  .shop-sidebar {
    transform: translateX(-320px);
  }
  .shop-sidebar .sidebar-back {
    border-bottom: 1px solid #505050;
    padding-bottom: 10px;
  }
}
.shop-sidebar.show {
  display: block;
  width: 320px;
  transform: translate(0);
  transition: all 0.3s ease;
  left: 0;
  top: 0;
}
@media (max-width: 991px) {
  .shop-sidebar.show {
    position: fixed;
    top: 0;
    z-index: 9;
    border: none;
  }
}
.shop-sidebar .sidebar-logo {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-logo img {
  height: calc(30px + 5 * (100vw - 320px) / 1600);
}
.shop-sidebar li {
  display: block;
  margin-bottom: 25px;
}
.shop-sidebar li:hover a {
  color: rgba(var(--theme-color), 1);
  transition: 0.4s ease;
}
.shop-sidebar li.active a {
  color: rgba(var(--theme-color), 1);
}
.shop-sidebar li a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  transition: 0.4s ease;
}
.shop-sidebar li a img {
  height: 40px;
  width: 40px;
  object-fit: contain;
  filter: invert(1);
}
@media (max-width: 767px) {
  .shop-sidebar.close-shop-sidebar {
    width: 280px;
    transform: translate(-285px);
    transition: all 0.3s ease;
  }
}

.sidebar-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.show {
  opacity: 1;
  visibility: visible;
}

.shop-body {
  padding-left: calc(325px + 20 * (100vw - 991px) / 929);
  padding-top: calc(100px + 10 * (100vw - 991px) / 929);
  padding-right: calc(12px + 13 * (100vw - 320px) / 671);
}
@media (max-width: 991px) {
  .shop-body {
    padding-left: calc(12px + 13 * (100vw - 320px) / 671);
  }
}
.shop-body .top-section {
  background-image: url(../images/home-bg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  position: relative;
}
[dir=rtl] .shop-body .top-section {
  direction: ltr;
}
@media (max-width: 767px) {
  .shop-body .top-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--content-color), 0.1);
  }
}
.shop-body .top-section .banner-content {
  padding: calc(30px + 50 * (100vw - 320px) / 1600) calc(20px + 40 * (100vw - 320px) / 1600);
}
.shop-body .top-section .banner-content h4 {
  color: #747474;
  font-size: calc(14px + 8 * (100vw - 320px) / 1600);
}
.shop-body .top-section .banner-content h2 {
  font-size: calc(18px + 6 * (100vw - 320px) / 1600);
  margin-block: calc(4px + 4 * (100vw - 320px) / 447);
}
.shop-body .top-section .banner-content .btn {
  padding-inline: calc(12px + 13 * (100vw - 320px) / 1600);
}
.shop-body .popular-restaurant .swiper {
  padding-bottom: 30px;
}

.main-circle {
  width: calc(1040px + 640 * (100vw - 320px) / 1600);
  height: calc(1040px + 640 * (100vw - 320px) / 1600);
  border-radius: 100%;
  opacity: 0.5;
  position: relative;
  background-image: linear-gradient(0deg, hsl(27, 12%, 15%) 0%, hsl(26, 14%, 15%) 10%, hsl(26, 15%, 15%) 20%, hsl(26, 17%, 16%) 30%, hsl(26, 18%, 16%) 40%, hsl(26, 20%, 16%) 50%, hsl(26, 21%, 16%) 60%, hsl(26, 23%, 17%) 70%, hsl(26, 24%, 17%) 80%, hsl(26, 26%, 17%) 90%, hsl(26, 27%, 18%) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-circle.circle-1 {
  width: calc(880px + 560 * (100vw - 320px) / 1600);
  height: calc(880px + 560 * (100vw - 320px) / 1600);
}
.main-circle.circle-2 {
  width: calc(720px + 480 * (100vw - 320px) / 1600);
  height: calc(720px + 480 * (100vw - 320px) / 1600);
  opacity: 1;
}
.main-circle.circle-2::after {
  content: "";
  width: 100%;
  height: 97%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(var(--theme-color), 1)), to(rgba(var(--theme-color2), 1)));
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  border-radius: 100%;
  filter: blur(7px);
  position: absolute;
  top: 10px;
  left: 0;
  z-index: -1;
  animation: rotateCircles 10s linear infinite;
}
.main-circle::before {
  content: "";
  background: linear-gradient(to top, transparent 90%, rgba(255, 255, 255, 0.05) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
  border: 1px dashed #5d4837;
}

.background-effect {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(-60px + 60 * (100vw - 320px) / 1600);
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.quote-panel {
  transform: rotate(-1deg) translateY(-18px);
  padding: calc(10px + 5 * (100vw - 320px) / 1600) 0;
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  overflow: hidden;
}
.quote-panel .marquee {
  display: flex;
  gap: calc(15px + 10 * (100vw - 320px) / 1600);
  animation: marquee 20s linear infinite;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
}
[dir=rtl] .quote-panel .marquee {
  animation: marquee-reverse 20s linear infinite;
}
.quote-panel .marquee li {
  padding-right: calc(15px + 10 * (100vw - 320px) / 1600);
  color: #fff !important;
  display: flex;
  gap: calc(15px + 10 * (100vw - 320px) / 1600);
  align-items: center;
}
.quote-panel .marquee li img {
  width: calc(22px + 14 * (100vw - 320px) / 1600);
  height: calc(22px + 14 * (100vw - 320px) / 1600);
}
.quote-panel .marquee li span {
  font-size: calc(18px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  font-style: italic;
}
.quote-panel.footer-quote {
  transform: none;
}

.line-effect {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.line-effect .container {
  height: 100vh;
}
.line-effect .row {
  height: 100vh;
}
.line-effect .row > div {
  position: relative;
}
.line-effect .row > div:after {
  content: "";
  position: absolute;
  height: 100vh;
  width: 1px;
  background: #f5f5f5;
  left: 50%;
  transform: translateX(-50%);
}
.line-effect .row > div:before {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 1px;
  top: -50%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 75%, rgba(0, 0, 0, 0.4) 100%);
  animation: drop 7s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  left: 50%;
  transform: translateX(-50%);
}
.line-effect .row > div:nth-child(2):before {
  animation-delay: 2.5s;
}
.line-effect .row > div:nth-child(3):before {
  animation-delay: 3s;
}
.line-effect .row > div:nth-child(4):before {
  animation-delay: 3.5s;
}

/*========================
  5.9  menu style start
==========================*/
.menu-section .pharmacy-product-box .pharmacy-product-img .img {
  padding: 0;
  height: 100%;
}
.menu-section .pharmacy-product-box .pharmacy-product-details .bottom-panel .cart-btn {
  font-size: 14px;
  padding: calc(5px + 3 * (100vw - 320px) / 1600) calc(10px + 4 * (100vw - 320px) / 1600);
}
.menu-section .order-summery-section.sticky-top {
  top: 30px;
}
.menu-section .order-summery-section .checkout-detail {
  position: relative;
  background-color: rgba(var(--white), 1);
  padding: calc(10px + 10 * (100vw - 320px) / 1600) calc(15px + 5 * (100vw - 320px) / 1600) calc(15px + 15 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}
.menu-section .order-summery-section .checkout-detail .checkout-title {
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
}
.menu-section .order-summery-section .checkout-detail .cart-address-box {
  display: flex;
  gap: 10px;
  margin-bottom: calc(20px + 20 * (100vw - 320px) / 1600);
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  border-radius: 6px;
}
.menu-section .order-summery-section .checkout-detail .cart-address-box .add-img .img {
  width: calc(60px + 20 * (100vw - 320px) / 1600);
  height: calc(60px + 20 * (100vw - 320px) / 1600);
  border-radius: 4px;
}
.menu-section .order-summery-section .checkout-detail .cart-address-box .add-content {
  width: calc(100% - (60px + 20 * (100vw - 320px) / 1600) - 10px);
  position: relative;
}
.menu-section .order-summery-section .checkout-detail .cart-address-box .add-content .deliver-place {
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu-section .order-summery-section .checkout-detail .cart-address-box .add-content .change-add {
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
}
.menu-section .order-summery-section .checkout-detail .cart-address-box .add-content .address {
  width: 100%;
  line-height: 1.2;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
}
.menu-section .order-summery-section .checkout-detail .checkout-title {
  padding-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box {
  display: flex;
  gap: 10px;
  padding: calc(10px + 10 * (100vw - 320px) / 1600) 0;
  background-color: rgba(var(--white), 1);
  border-top: 1px solid rgba(var(--dark-text), 0.15);
}
@media (max-width: 385px) {
  .menu-section .order-summery-section .checkout-detail .horizontal-product-box {
    display: block;
  }
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-img .img {
  width: calc(75px + 20 * (100vw - 320px) / 1600);
  height: calc(75px + 20 * (100vw - 320px) / 1600);
  object-fit: cover;
  border-radius: 4px;
}
@media (max-width: 385px) {
  .menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-img .img {
    width: 100%;
  }
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content {
  width: calc(100% - (75px + 20 * (100vw - 320px) / 1600) - 10px);
  position: relative;
}
@media (max-width: 385px) {
  .menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content {
    width: 100%;
    margin-top: 10px;
  }
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content h5 {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
  max-width: 80%;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content h6 {
  color: rgba(var(--content-color), 1);
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .place {
  position: relative;
  padding-left: 12px;
  color: rgba(var(--content-color), 1);
  border-bottom: none;
}
[dir=rtl] .menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .place {
  padding-left: unset;
  padding-right: 12px;
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .place::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--content-color), 1);
  border-radius: 100%;
}
[dir=rtl] .menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .place::after {
  left: unset;
  right: -5px;
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus {
  border: 1px solid rgba(var(--dark-text), 0.15);
  padding: 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: rgba(var(--box-bg), 1);
  text-align: center;
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus input {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: calc(15px + 20 * (100vw - 320px) / 1600);
  text-align: center;
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i {
  color: rgba(var(--dark-text), 1);
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.sub {
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.add {
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
}
.menu-section .order-summery-section .checkout-detail .horizontal-product-box .product-content .product-price {
  font-weight: 700;
  color: rgba(var(--dark-text), 1);
}
.menu-section .order-summery-section .checkout-detail .sub-total {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.menu-section .order-summery-section .checkout-detail .sub-total.total-amount {
  font-weight: 500;
  padding-top: calc(10px + 10 * (100vw - 320px) / 1600);
  border-top: 1px dashed rgba(var(--dark-text), 0.15);
}
.menu-section .order-summery-section .checkout-detail .sub-total p {
  font-size: 13px;
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.menu-section .order-summery-section .checkout-detail h5 {
  color: rgba(var(--dark-text), 1);
}
.menu-section .order-summery-section .checkout-detail .delivery-info {
  width: 70%;
  margin-bottom: 15px;
  line-height: 1.2;
}
.menu-section .order-summery-section .checkout-detail .grand-total {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(var(--dark-text), 0.15);
  padding: 10px 0 0;
}
.menu-section .order-summery-section .checkout-detail .grand-total h5 {
  color: rgba(var(--dark-text), 1);
}
.menu-section .order-summery-section .checkout-detail .grand-total .amount {
  color: rgba(var(--success-color), 1);
}
.menu-section .order-summery-section .checkout-detail .restaurant-btn {
  padding: calc(10px + 5 * (100vw - 320px) / 1600) 0;
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  text-transform: uppercase;
}
.menu-section .order-summery-section .checkout-detail .dots-design {
  position: absolute;
  bottom: -1px;
  width: 105%;
  left: 50%;
  transform: translateX(-50%);
}

/*=============================
  5.10  Location style start
===============================*/
.location-map {
  border: 0;
  height: 100vh;
  width: 100%;
}

.delivery-root {
  position: relative;
  border: 0;
  height: 100%;
  width: 100%;
  margin-bottom: -16px;
}
.delivery-root > div {
  height: 100%;
}
@media (max-width: 1199px) {
  .delivery-root {
    height: 50vh;
  }
}

.location-popup {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  max-width: 600px;
  background: linear-gradient(180deg, #414449 1.31%, #1f1f1f 101.06%);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
  border-radius: 20px 20px 0px 0px;
  height: auto;
  padding: 20px;
}
.location-popup .change-location {
  display: flex;
  justify-content: space-between;
}
.location-popup .change-location h2 {
  font-weight: 500;
  color: rgba(var(--white), 1);
}
.location-popup .change-location i {
  color: rgba(var(--theme-color), 1);
  font-size: 18px;
}
.location-popup .address {
  font-size: 14px;
  font-weight: 500;
}
.location-popup .delivery-btn {
  font-size: 16px;
}

.order-status-content {
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-bottom: 0;
}
.order-status-content h4 {
  margin-bottom: 15px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.order-status-content .driver-details-box {
  display: flex;
  align-items: center;
  gap: calc(10px + 5 * (100vw - 320px) / 1600);
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--theme-color), 0.05);
  border: 1px solid rgba(var(--theme-color), 0.2);
  border-radius: 6px;
}
.order-status-content .driver-details-box .driver-image .img {
  width: calc(50px + 20 * (100vw - 320px) / 1600);
  height: calc(50px + 20 * (100vw - 320px) / 1600);
  object-fit: contain;
  border-radius: 100%;
}
.order-status-content .driver-details-box .driver-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc(5px + 5 * (100vw - 320px) / 1600);
  width: calc(100% - (50px + 20 * (100vw - 320px) / 1600) - (10px + 5 * (100vw - 320px) / 1600));
}
@media (max-width: 440px) {
  .order-status-content .driver-details-box .driver-content {
    display: block;
  }
}
@media (max-width: 440px) {
  .order-status-content .driver-details-box .driver-content .driver-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: calc(5px + 5 * (100vw - 320px) / 1600);
  }
}
.order-status-content .driver-details-box .driver-content .driver-info h6 {
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.order-status-content .driver-details-box .driver-content .driver-info h5 {
  line-height: 1;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
  margin-top: 5px;
}
.order-status-content .shipping-details {
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
}
.order-status-content .shipping-details h4 {
  margin-bottom: 15px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.order-status-content .shipping-details .delivery-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 calc(10px + 10 * (100vw - 320px) / 1600);
  margin-top: 10px;
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 4px;
}
.order-status-content .shipping-details .delivery-list li {
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  padding-block: calc(10px + 10 * (100vw - 320px) / 1600);
}
.order-status-content .shipping-details .delivery-list li:last-child {
  border-bottom: none;
}
.order-status-content .shipping-details .delivery-list li h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
  margin-top: 3px;
}
.order-status-content .shipping-details .delivery-list li h5 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.order-status-content .shipping-details .delivery-list li .order-address {
  display: flex;
  align-items: center;
  gap: 10px;
}
.order-status-content .shipping-details .delivery-list li .order-address .place-icon {
  width: 60px;
  height: 60px;
}

.order-details-content {
  margin-top: 20px;
}
.order-details-content h4 {
  margin-bottom: 15px;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.order-details-content .order-content-main-box {
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--dark-text), 0.15);
  border-radius: 6px;
}
.order-details-content .order-content-main-box .order-list li {
  width: 100%;
  padding-block: 10px;
  border-bottom: 1px dashed rgba(var(--dark-text), 0.15);
}
.order-details-content .order-content-main-box .order-list li:first-child {
  padding-top: 0;
}
.order-details-content .order-content-main-box .order-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px dashed rgba(var(--dark-text), 0.15);
}
.order-details-content .order-content-main-box .order-list li .order-box-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.order-details-content .order-content-main-box .order-list li .order-box-content .product-img .img {
  width: calc(60px + 20 * (100vw - 320px) / 1600);
  height: calc(60px + 20 * (100vw - 320px) / 1600);
  object-fit: contain;
}
.order-details-content .order-content-main-box .order-list li .order-box-content .order-details {
  width: 100%;
}
.order-details-content .order-content-main-box .order-list li .order-box-content .order-details h6 {
  font-weight: 500;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.order-details-content .order-content-main-box .order-list li .order-box-content .order-details p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(var(--content-color), 1);
}
.order-details-content .order-content-main-box .total-amount {
  margin-top: 10px;
}
.order-details-content .order-content-main-box .total-amount .grand-amount {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(var(--dark-text), 0.15);
}

/*========================
  5.11  product detail style start
==========================*/
.product-banner-section {
  background-image: url("../images/background/home-bg.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: calc(60px + 49 * (100vw - 320px) / 1600) 0 calc(15px + 15 * (100vw - 320px) / 1600);
}
@media (max-width: 600px) {
  .product-banner-section {
    background-position: right;
  }
}
.product-banner-section .restaurant-box {
  position: relative;
  padding: 0;
  backdrop-filter: blur(6px);
  display: flex;
  gap: calc(10px + 8 * (100vw - 320px) / 1600);
  padding-top: calc(30px + 45 * (100vw - 320px) / 1600);
}
@media (max-width: 576px) {
  .product-banner-section .restaurant-box .restaurant-image {
    display: none;
  }
}
.product-banner-section .restaurant-box .restaurant-image .img {
  width: calc(60px + 20 * (100vw - 320px) / 1600);
  height: calc(60px + 20 * (100vw - 320px) / 1600);
  border-radius: 4px;
}
.product-banner-section .restaurant-box .restaurant-details {
  width: calc(100% - (60px + 20 * (100vw - 320px) / 1600) - (10px + 8 * (100vw - 320px) / 1600));
}
@media (max-width: 576px) {
  .product-banner-section .restaurant-box .restaurant-details {
    width: 100%;
  }
}
.product-banner-section .restaurant-box .restaurant-details .restaurant-name {
  color: #fff;
  font-size: calc(18px + 6 * (100vw - 320px) / 1600);
  font-weight: 500;
}
.product-banner-section .restaurant-box .restaurant-details .restaurant-place {
  position: relative;
  color: rgba(255, 255, 255, 0.8);
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  margin-top: calc(6px + 7 * (100vw - 320px) / 1600);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon {
  position: relative;
  display: flex;
  align-items: center;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .product-banner-section .restaurant-box .restaurant-details .categories-icon {
    justify-content: flex-start;
  }
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon a {
  line-height: 1;
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .icon {
  font-size: calc(18px + 7 * (100vw - 320px) / 1600);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn {
  position: relative;
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .effect-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .effect-group .effect {
  display: block;
  position: absolute;
  top: 38%;
  left: 50%;
  width: 20px;
  transform-origin: 0px 2px;
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .effect-group .effect:nth-child(2) {
  transform: rotate(72deg);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .effect-group .effect:nth-child(3) {
  transform: rotate(144deg);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .effect-group .effect:nth-child(4) {
  transform: rotate(216deg);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .effect-group .effect:nth-child(5) {
  transform: rotate(288deg);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .effect-group .effect::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  border-radius: 2px;
  height: 3px;
  background: #777777;
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .effect-group .effect::after {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  right: 10%;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  background: rgba(var(--theme-color), 1);
  transform: scale(0, 0);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn.active .outline-icon {
  display: none;
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn.active .fill-icon {
  display: flex;
  color: rgba(var(--error-color), 1);
  font-size: calc(18px + 7 * (100vw - 320px) / 1600);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn.active .effect:before {
  animation: fireworkLine 0.5s linear 0.1s;
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn.active .effect:after {
  animation: fireworkLine 0.5s linear 0.1s;
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .outline-icon {
  display: flex;
  font-size: calc(18px + 7 * (100vw - 320px) / 1600);
  color: rgba(var(--error-color), 1);
}
.product-banner-section .restaurant-box .restaurant-details .categories-icon .like-btn .fill-icon {
  display: none;
}
.product-banner-section .restaurant-box .restaurant-details .food-categories {
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 991px) {
  .product-banner-section .restaurant-box .restaurant-details .restaurant-description {
    display: flex;
    gap: calc(12px + 13 * (100vw - 320px) / 447);
    align-items: center;
  }
}
.product-banner-section .restaurant-box .restaurant-details .restaurant-description .distance {
  padding-top: calc(5px + 3 * (100vw - 320px) / 1600);
}
@media (max-width: 991px) {
  .product-banner-section .restaurant-box .restaurant-details .restaurant-description .distance {
    padding-top: 0;
  }
}
.product-banner-section .restaurant-box .restaurant-details .restaurant-description .distance .shop-time {
  display: flex;
  align-items: center;
  gap: calc(8px + 4 * (100vw - 320px) / 1600);
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.product-banner-section .restaurant-box .restaurant-details .restaurant-description .distance .shop-time::after {
  content: "";
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 20px;
  display: inline-block;
  padding-right: calc(8px + 4 * (100vw - 320px) / 1600);
}
[dir=rtl] .product-banner-section .restaurant-box .restaurant-details .restaurant-description .distance .shop-time::after {
  padding-right: 0;
  padding-left: calc(8px + 4 * (100vw - 320px) / 1600);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 0;
}
.product-banner-section .restaurant-box .restaurant-details .restaurant-description .distance .rating-star {
  display: flex;
  align-items: center;
  gap: calc(6px + 2 * (100vw - 320px) / 1600);
  color: #fff;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.product-banner-section .restaurant-box .restaurant-details .restaurant-description .distance .rating-star .star {
  width: calc(15px + 5 * (100vw - 320px) / 1600);
  height: calc(15px + 5 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--success-color), 1);
  border-radius: 100%;
}
.product-banner-section .restaurant-box .restaurant-details .restaurant-description .distance .rating-star .star i {
  font-size: calc(10px + 4 * (100vw - 320px) / 1600);
  color: #fff;
}

.tab-details-section {
  position: relative;
  padding-top: calc(20px + 40 * (100vw - 320px) / 1600);
}
.tab-details-section .menu-button {
  position: fixed;
  bottom: calc(65px + 10 * (100vw - 320px) / 671);
  left: calc(20px + 30 * (100vw - 320px) / 671);
  z-index: 3;
  padding: 10px;
  background: rgba(var(--dark-text), 1);
  border-radius: 10px;
}
[dir=rtl] .tab-details-section .menu-button {
  left: unset;
  right: calc(20px + 30 * (100vw - 320px) / 671);
}
.tab-details-section .menu-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: rgba(var(--white), 1);
}
.tab-details-section .menu-button a i {
  line-height: 1;
  font-size: 16px;
}

.product-details-content .order-summery-section {
  margin-top: 0;
}
@media (max-width: 991px) {
  .product-details-content .order-summery-section {
    display: none;
  }
}
.product-details-content .order-summery-section.sticky-top {
  top: 30px;
}
.product-details-content .order-summery-section .checkout-detail {
  position: relative;
  background-color: rgba(var(--white), 1);
  padding: calc(10px + 10 * (100vw - 320px) / 1600) calc(15px + 5 * (100vw - 320px) / 1600) calc(15px + 15 * (100vw - 320px) / 1600);
  margin-bottom: 0;
}
.product-details-content .order-summery-section .checkout-detail ul li {
  width: 100%;
}
.product-details-content .order-summery-section .checkout-detail .checkout-title {
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
}
.product-details-content .order-summery-section .checkout-detail .cart-address-box {
  display: flex;
  gap: 10px;
  margin-bottom: calc(20px + 20 * (100vw - 320px) / 1600);
  padding: calc(10px + 5 * (100vw - 320px) / 1600);
  background: rgba(var(--white), 1);
  border: 1px solid rgba(var(--black), 0.05);
  border-radius: 6px;
}
.product-details-content .order-summery-section .checkout-detail .cart-address-box .add-img .img {
  width: calc(60px + 20 * (100vw - 320px) / 1600);
  height: calc(60px + 20 * (100vw - 320px) / 1600);
  border-radius: 4px;
}
.product-details-content .order-summery-section .checkout-detail .cart-address-box .add-content {
  width: calc(100% - (60px + 20 * (100vw - 320px) / 1600) - 10px);
  position: relative;
}
.product-details-content .order-summery-section .checkout-detail .cart-address-box .add-content .deliver-place {
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product-details-content .order-summery-section .checkout-detail .cart-address-box .add-content .change-add {
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
}
.product-details-content .order-summery-section .checkout-detail .cart-address-box .add-content .address {
  width: 100%;
  line-height: 1.2;
  font-size: calc(13px + 3 * (100vw - 320px) / 1600);
}
.product-details-content .order-summery-section .checkout-detail .checkout-title {
  padding-bottom: calc(10px + 10 * (100vw - 991px) / 929);
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box {
  display: flex;
  padding: calc(10px + 10 * (100vw - 991px) / 929) 0;
  background-color: rgba(var(--white), 1);
  border-top: 1px solid rgba(var(--dark-text), 0.15);
}
@media (max-width: 385px) {
  .product-details-content .order-summery-section .checkout-detail .horizontal-product-box {
    display: block;
  }
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-img .img {
  width: calc(100px + 30 * (100vw - 320px) / 1600);
  height: calc(100px + 30 * (100vw - 320px) / 1600);
  object-fit: cover;
  border-radius: 4px;
}
@media (max-width: 385px) {
  .product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-img .img {
    width: 100%;
  }
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content {
  width: 100%;
  position: relative;
}
@media (max-width: 385px) {
  .product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content {
    width: 100%;
    margin-top: 10px;
  }
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content h5 {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(15px + 2 * (100vw - 991px) / 929);
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content h6 {
  color: rgba(var(--content-color), 1);
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content h6.ingredients-text {
  width: 70%;
  font-size: calc(14px + 2 * (100vw - 600px) / 1320);
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .place {
  position: relative;
  padding-left: 12px;
  color: rgba(var(--content-color), 1);
  border-bottom: none;
}
[dir=rtl] .product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .place {
  padding-left: unset;
  padding-right: 12px;
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .place::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: rgba(var(--content-color), 1);
  border-radius: 100%;
}
[dir=rtl] .product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .place::after {
  left: unset;
  right: -5px;
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus {
  border: 1px solid rgba(var(--dashed-line), 1);
  padding: calc(3px + 2 * (100vw - 991px) / 929);
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: rgba(var(--box-bg), 1);
  text-align: center;
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus input {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: calc(15px + 20 * (100vw - 320px) / 1600);
  text-align: center;
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i {
  color: rgba(var(--dark-text), 1);
  cursor: pointer;
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.sub {
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.sub {
  padding-right: unset;
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dashed-line), 1);
  border-right: unset;
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.add {
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dashed-line), 1);
}
[dir=rtl] .product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus i.add {
  padding-left: unset;
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dashed-line), 1);
  border-left: unset;
}
.product-details-content .order-summery-section .checkout-detail .horizontal-product-box .product-content .product-price {
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.product-details-content .order-summery-section .checkout-detail .sub-total {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.product-details-content .order-summery-section .checkout-detail .sub-total.total-amount {
  font-weight: 500;
  padding-top: calc(10px + 10 * (100vw - 320px) / 1600);
  border-top: 1px dashed rgba(var(--dark-text), 0.15);
}
.product-details-content .order-summery-section .checkout-detail .sub-total p {
  font-size: 13px;
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.product-details-content .order-summery-section .checkout-detail h5 {
  color: rgba(var(--dark-text), 1);
}
.product-details-content .order-summery-section .checkout-detail .delivery-info {
  width: 70%;
  margin-bottom: 15px;
  line-height: 1.2;
}
.product-details-content .order-summery-section .checkout-detail .grand-total {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(var(--dark-text), 0.15);
  padding: 10px 0 0;
}
.product-details-content .order-summery-section .checkout-detail .grand-total h5 {
  color: rgba(var(--dark-text), 1);
}
.product-details-content .order-summery-section .checkout-detail .grand-total .amount {
  color: rgba(var(--success-color), 1);
}
.product-details-content .order-summery-section .checkout-detail .restaurant-btn {
  padding: calc(10px + 5 * (100vw - 991px) / 929) 0;
  margin-top: calc(10px + 10 * (100vw - 991px) / 929);
  text-transform: uppercase;
}
.product-details-content .order-summery-section .checkout-detail .dots-design {
  position: absolute;
  bottom: -1px;
  width: 105%;
  left: 50%;
  transform: translateX(-50%);
}

.product-sidebar {
  position: sticky;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  margin-top: calc(20px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
  z-index: 1;
}
.product-sidebar.sticky-top {
  top: 30px;
}
@media (max-width: 991px) {
  .product-sidebar.sticky-top {
    top: unset;
    bottom: 80px;
    left: calc(20px + 30 * (100vw - 320px) / 671);
  }
  [dir=rtl] .product-sidebar.sticky-top {
    left: unset;
    right: calc(20px + 30 * (100vw - 320px) / 671);
  }
}
.product-sidebar.show {
  opacity: 1;
  visibility: visible;
  width: 80%;
  height: 350px;
}
@media (max-width: 992px) and (min-width: 650px) {
  .product-sidebar.show {
    width: 40%;
  }
}
.product-sidebar.show .product-items {
  padding-top: 0;
}
@media (max-width: 991px) {
  .product-sidebar {
    position: fixed;
    left: 60px;
    bottom: 60px;
    top: unset;
    width: 0;
    height: 0;
    display: block;
    padding: calc(16px + 4 * (100vw - 320px) / 1600);
    background-color: rgba(var(--white), 1);
    transition: 0.4s ease;
    border-radius: 10px;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
  }
  [dir=rtl] .product-sidebar {
    left: unset;
    right: 60px;
  }
  .product-sidebar .sidebar-search {
    display: none;
  }
}
.product-sidebar .sidebar-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(var(--dark-text), 0.2);
}
.product-sidebar .sidebar-title a {
  color: rgba(var(--content-color), 1);
}
.product-sidebar .sidebar-search {
  position: relative;
}
.product-sidebar .sidebar-search input {
  width: 100%;
  background-color: rgba(var(--box-bg), 1);
  box-shadow: none;
  border: none;
  padding: 10px 16px;
  outline: none;
}
.product-sidebar .sidebar-search i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  color: #a0a0a0;
  z-index: 1;
}
[dir=rtl] .product-sidebar .sidebar-search i {
  right: unset;
  left: 16px;
}
.product-sidebar .filter-content {
  padding: 20px;
}
.product-sidebar .filter-content .type-list {
  margin-top: 20px;
}
.product-sidebar .filter-content .type-list li {
  width: 100%;
  padding: 12px 0;
  border-bottom: 1px dashed rgba(var(--dark-text), 0.15);
}
.product-sidebar .filter-content .type-list li:first-child {
  padding-top: 0;
}
.product-sidebar .filter-content .type-list li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.product-sidebar .filter-content .type-list li .form-check-reverse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.product-sidebar .filter-content .type-list li .form-check-reverse .form-check-label {
  display: flex;
  align-items: center;
  gap: 8px;
}
.product-sidebar .filter-content .type-list li .form-check-reverse .form-check-label .img {
  width: calc(20px + 30 * (100vw - 320px) / 1600);
}
.product-sidebar .filter-content .type-list li .form-check-reverse .form-check-label h6 {
  font-size: 14px;
  font-weight: 400;
}
.product-sidebar .filter-content .type-list li .form-check-reverse .form-check-input {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--dark-text), 0.15);
}
.product-sidebar .filter-content .type-list li .form-check-reverse .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(var(--dark-text), 0.15);
}
.product-sidebar .filter-content .type-list li .form-check-reverse .form-check-input:checked {
  background-color: rgba(var(--theme-color), 1);
  border-color: rgba(var(--theme-color), 0.15);
  background-image: none;
  width: 20px;
  height: 20px;
}
.product-sidebar .filter-content .type-list li .form-check-reverse .form-check-input:checked::after {
  content: "\eb7b";
  font-family: remixicon !important;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--white), 1);
}
.product-sidebar .product-items {
  padding: calc(10px + 10 * (100vw - 320px) / 1600) 0;
}
.product-sidebar .product-items h6 {
  padding-bottom: 15px;
}
.product-sidebar .product-items .nav-pills {
  display: block;
}
.product-sidebar .product-items .nav-pills.sub-nav-pills {
  padding-left: calc(10px + 10 * (100vw - 320px) / 1600);
  width: 100%;
}
.product-sidebar .product-items .nav-pills.sub-nav-pills li {
  margin-bottom: 0;
}
.product-sidebar .product-items .nav-pills.sub-nav-pills li a {
  color: rgba(var(--content-color), 0.9);
}
.product-sidebar .product-items .nav-pills.sub-nav-pills li + li {
  margin-top: 5px;
}
[dir=rtl] .product-sidebar .product-items .nav-pills.sub-nav-pills {
  padding-left: unset;
  padding-right: 20px;
}
.product-sidebar .product-items .nav-pills li {
  display: block;
  margin-bottom: calc(6px + 4 * (100vw - 991px) / 929);
}
.product-sidebar .product-items .nav-pills li:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.product-sidebar .product-items .nav-pills .nav-link {
  position: relative;
  color: rgba(var(--dark-text), 1);
  border-radius: 0;
  width: 100%;
  padding: 0;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.product-sidebar .product-items .nav-pills .nav-link.active {
  margin-right: auto;
  color: rgba(var(--theme-color), 1);
  background-color: transparent;
  border-radius: 0;
  font-weight: 500;
}
.product-sidebar .product-items .nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  right: calc(-14px + -6 * (100vw - 991px) / 929);
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(var(--theme-color), 1);
}
[dir=rtl] .product-sidebar .product-items .nav-pills .nav-link.active::after {
  right: unset;
  left: calc(-14px + -6 * (100vw - 991px) / 929);
}

.product-box-section {
  height: 100%;
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.product-box-section .product-details-box-list .product-details-box-title {
  padding-top: 10px;
  font-size: calc(16px + 6 * (100vw - 320px) / 1600);
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.product-box-section .product-details-box-list .product-details-box {
  width: 100%;
  padding: calc(10px + 10 * (100vw - 320px) / 1600) 0;
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  position: relative;
  display: flex;
  gap: 15px;
}
.product-box-section .product-details-box-list .product-details-box .product-img .img {
  width: calc(100px + 50 * (100vw - 320px) / 1600);
  height: calc(100px + 50 * (100vw - 320px) / 1600);
  border-radius: 7px;
  object-fit: cover;
}
.product-box-section .product-details-box-list .product-details-box .product-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - (100px + 50 * (100vw - 320px) / 1600) - 15px);
}
@media (max-width: 425px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description {
    flex-wrap: wrap;
  }
}
.product-box-section .product-details-box-list .product-details-box .product-content .description > div:first-child {
  width: 73%;
}
@media (max-width: 425px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description > div:first-child {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description {
    display: block !important;
  }
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .product-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.product-box-section .product-details-box-list .product-details-box .product-content .description h2 span {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .customized {
  display: list-item;
  list-style-type: disc;
  margin-left: 25px;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .product-box-section .product-details-box-list .product-details-box .product-content .description .customized {
  margin-left: unset;
  margin-right: 25px;
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .customized::marker {
  color: rgba(var(--content-color), 1);
}
@media (max-width: 767px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description .customized {
    display: none;
  }
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .rating-section {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
  margin-top: 5px;
}
@media (max-width: 425px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description .rating-section {
    padding-bottom: 0;
    border-bottom: none;
  }
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .rating-section .rating-star {
  display: flex;
  align-items: center;
  gap: 1px;
}
@media (max-width: 450px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description .rating-section .rating-star {
    width: fit-content;
  }
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .rating-section .rating-star li {
  padding: 0;
  border-bottom: none;
  line-height: 1;
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .rating-section .rating-star li .star {
  color: rgba(var(--rate-color), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  line-height: 1;
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .rating-section .rating-amount {
  color: rgba(var(--content-color), 1);
  font-size: 16px;
}
@media (max-width: 350px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description .rating-section .rating-amount {
    display: none;
  }
}
.product-box-section .product-details-box-list .product-details-box .product-content .description p {
  width: 100%;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  padding-top: 10px;
  color: rgba(var(--content-color), 0.7);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description p {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description p {
    display: none;
  }
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  text-align: center;
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price h2 {
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
@media (max-width: 425px) {
  .product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: unset;
    width: 100%;
    margin-top: 5px;
  }
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price h2 span {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price .add-btn {
  border-radius: 10px;
  padding: calc(5px + 4 * (100vw - 320px) / 1600) calc(14px + 13 * (100vw - 320px) / 1600);
  margin-left: auto;
}
[dir=rtl] .product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price .add-btn {
  margin-left: 0;
  margin-right: auto;
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price .plus-minus {
  border-radius: 6px;
  border: 1px solid rgba(var(--theme-color), 1);
  padding: 6px 17px;
  display: inline-flex;
  align-items: center;
  background-color: rgba(var(--box-bg), 1);
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -15px;
  display: none;
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price .plus-minus input {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 14px;
  outline: none;
  width: 35px;
  text-align: center;
}
.product-box-section .product-details-box-list .product-details-box .product-content .description .product-box-price .plus-minus i {
  color: rgba(var(--theme-color), 1);
}

.photos-section {
  height: 100%;
  margin-top: 30px;
  padding: calc(15px + 15 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.photos-section h5 {
  font-weight: 600;
  padding-bottom: 5px;
  color: rgba(var(--dark-text), 1);
  border-bottom: 1px solid rgba(var(--dark-text), 0.07);
}
.photos-section .photos-tab {
  margin: calc(15px + 15 * (100vw - 320px) / 1600) 0;
}
.photos-section .photos-tab .nav-item .nav-link {
  padding: calc(10px + 5 * (100vw - 320px) / 1600) calc(20px + 40 * (100vw - 320px) / 1600);
}
.photos-section .photos-list {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
@media (max-width: 992px) {
  .photos-section .photos-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .photos-section .photos-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 375px) {
  .photos-section .photos-list {
    grid-template-columns: 1fr;
  }
}
.photos-section .photos-list li .restaurant-photo {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 7px;
}
@media (max-width: 375px) {
  .photos-section .photos-list li .restaurant-photo {
    width: 100%;
  }
}

.review-section {
  height: 100%;
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.review-section h5 {
  font-weight: 600;
  padding-bottom: 5px;
  color: rgba(var(--dark-text), 1);
  border-bottom: 1px solid rgba(var(--dark-text), 0.07);
}
.review-section .review-box-list li {
  width: 100%;
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
  padding-bottom: 15px;
}
.review-section .review-box-list li:first-child {
  margin-top: 0;
}
.review-section .review-box-list li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.review-section .review-box-list li .review-box .review-head {
  display: flex;
  align-items: center;
  gap: 12px;
}
.review-section .review-box-list li .review-box .review-head .reviewer-name {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.review-section .review-box-list li .review-box .review-head h6 {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.review-section .review-box-list li .review-box .review-head .review-image {
  width: 60px;
  height: 60px;
}
.review-section .review-box-list li .review-box .review-head .review-image .img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.review-section .review-box-list li .review-box .review-head .rating-star {
  display: flex;
  align-items: center;
}
.review-section .review-box-list li .review-box .review-head .rating-star li {
  padding: 0;
  margin: 0;
}
.review-section .review-box-list li .review-box .review-head .rating-star li .star {
  color: rgba(var(--rate-color), 1);
  font-size: 20px;
}
.review-section .review-box-list li .review-box .review-content {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.review-section .review-box-list li .review-box .review-content h6 {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.review-section .review-box-list li .review-box .review-content p {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
  margin-top: 7px;
}

.overview-section {
  height: 100%;
  margin-top: 30px;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
}
.overview-section h5 {
  font-weight: 600;
  padding-bottom: 5px;
  color: rgba(var(--dark-text), 1);
  border-bottom: 1px solid rgba(var(--dark-text), 0.07);
}
.overview-section .overview-content {
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
}
.overview-section .overview-content .overview-heading {
  font-weight: 600;
  margin-bottom: 4px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.overview-section .overview-content .content-list.w-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: calc(5px + 5 * (100vw - 320px) / 1600);
}
.overview-section .overview-content .content-list li {
  font-weight: 400;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  display: list-item;
  list-style-type: disc;
  margin-left: 20px;
  color: rgba(var(--content-color), 1);
  padding-bottom: 8px;
}
[dir=rtl] .overview-section .overview-content .content-list li {
  margin-left: unset;
  margin-right: 20px;
}
.overview-section .overview-content .content-list li:last-child {
  padding-bottom: 0;
}
.overview-section .overview-content .content-list li::marker {
  color: rgba(var(--content-color), 1);
}
@media (max-width: 991px) {
  .overview-section .overview-content .content-list li.content-address {
    width: 90%;
  }
}

@media (max-width: 991px) {
  .fixed-btn {
    position: fixed;
    width: 100%;
    max-width: 991px;
    padding-bottom: 10px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    z-index: 1;
  }
  .fixed-btn .cart-fixed-bottom {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: rgba(var(--theme-color), 1);
    border-radius: 10px;
    z-index: 2;
  }
}

/*========================
  5.12  product style start
==========================*/
.view-product .slider-box {
  position: relative;
  cursor: pointer;
}
.view-product .slider-box .swiper {
  width: 100%;
  height: 100%;
}
.view-product .slider-box .swiper-slide img {
  display: block;
  width: 100%;
  padding: 10px;
  object-fit: contain;
  background-color: rgba(var(--dark-text), 0.05);
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  font-weight: 600;
  border-radius: 5px;
}
.view-product .slider-box .thumbnail-box {
  z-index: 5;
  height: 100%;
}
.view-product .slider-box .thumbnail-box .thumbnail-img-box {
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
}
.view-product .slider-box .thumbnail-box .thumbnail-img-box .swiper-slide {
  display: block;
}
.view-product .slider-box .thumbnail-box .thumbnail-img-box .swiper-wrapper {
  width: 100%;
}
.view-product .slider-box .thumbnail-box .thumbnail-img-box .swiper-slide {
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.view-product .slider-box .thumbnail-box .thumbnail-img-box .swiper-slide-thumb-active {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  background-color: rgba(var(--dark-text), 0.05);
}
.view-product .slider-box .thumbnail-box .thumbnail-img-box .mainslider2 .swiper-slide-active {
  background-color: rgba(var(--dark-text), 0.15);
}
.view-product .slider-box .thumbnail-box .thumbnail-img-box .mainslider2 .swiper-slide-active .img {
  background-size: contain;
}
.view-product .slider-box .thumbnail-box .thumbnail-img-box .mainslider2 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 5px;
  background-color: rgba(var(--dark-text), 0.15);
}
.view-product .slider-box .mainslider2 .swiper-slide-active {
  background-color: rgba(var(--dark-text), 0.01);
  background-size: contain;
}
.view-product .product-detail-box {
  width: 100%;
  height: 100%;
  padding: 0 calc(10px + 10 * (100vw - 320px) / 1600) 0 0;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
}
[dir=rtl] .view-product .product-detail-box {
  border-left: 1px solid rgba(var(--dark-text), 0.15);
  border-right: unset;
}
@media (max-width: 992px) {
  .view-product .product-detail-box {
    border-right: none;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .view-product .product-detail-box {
    text-align: center;
  }
}
[dir=rtl] .view-product .product-detail-box {
  padding: 0 0 0 calc(10px + 10 * (100vw - 320px) / 1600);
}
.view-product .product-detail-box h2 {
  color: rgba(var(--dark-text), 1);
}
.view-product .product-detail-box h2 span {
  font-size: calc(18px + 6 * (100vw - 320px) / 1600);
}
.view-product .product-detail-box h4 {
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.view-product .product-detail-box h5 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(var(--dark-text), 1);
}
.view-product .product-detail-box .offer-tag {
  position: relative;
  font-weight: 500;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  padding: 5px calc(5px + 5 * (100vw - 320px) / 1600);
  color: rgba(var(--error-color), 1);
  background-color: rgba(var(--error-color), 0.15);
  border-radius: 10px;
  z-index: 1;
}
.view-product .product-detail-box .description {
  margin-top: 5px;
}
.view-product .product-detail-box .option-side.title-wrap {
  justify-content: flex-start;
  gap: calc(8px + 4 * (100vw - 320px) / 1600);
  margin-bottom: 8px;
}
.view-product .product-detail-box .option-side.title-wrap h2 {
  margin-bottom: 0;
}
.view-product .product-detail-box h5 {
  display: block;
  margin-bottom: 8px;
}
.view-product .product-detail-box .rating-option {
  display: flex;
  align-items: center;
  gap: calc(5px + 5 * (100vw - 320px) / 1600);
}
.view-product .product-detail-box .rating-option .rating {
  display: flex;
  align-items: center;
}
.view-product .product-detail-box .rating-option .rating li .fill {
  font-size: 20px;
  color: rgba(var(--secondary-color), 1);
}
.view-product .product-detail-box .rating-option .rating li .icon {
  font-size: 20px;
  color: rgba(var(--secondary-color), 1);
}
.view-product .product-detail-box .quantity-box {
  margin-top: 10px;
}
.view-product .product-detail-box .quantity-box .heading {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}
.view-product .product-detail-box .quantity-box .plus-minus {
  border: 1px solid rgba(var(--dark-text), 0.15);
  padding: calc(5px + 5 * (100vw - 320px) / 1600);
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  background-color: rgba(var(--box-bg), 1);
  text-align: center;
}
.view-product .product-detail-box .quantity-box .plus-minus input {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 18px;
  outline: none;
  width: calc(30px + 20 * (100vw - 320px) / 1600);
  text-align: center;
}
.view-product .product-detail-box .quantity-box .plus-minus i {
  font-size: 18px;
  color: rgba(var(--dark-text), 1);
}
.view-product .product-detail-box .quantity-box .plus-minus i.sub {
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
}
[dir=rtl] .view-product .product-detail-box .quantity-box .plus-minus i.sub {
  padding-right: unset;
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
  border-right: unset;
}
.view-product .product-detail-box .quantity-box .plus-minus i.add {
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
}
[dir=rtl] .view-product .product-detail-box .quantity-box .plus-minus i.add {
  padding-left: unset;
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
  border-left: unset;
}
.view-product .product-detail-box .sale-details {
  margin-block: calc(10px + 10 * (100vw - 320px) / 1600);
}
.view-product .product-detail-box .sale-details .heading {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}
.view-product .product-detail-box .sale-details .product-timer {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .view-product .product-detail-box .sale-details .product-timer {
    justify-content: center;
  }
}
.view-product .product-detail-box .sale-details .product-timer li {
  width: calc(70px + 20 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 0.5);
  border: 1px solid rgba(var(--dark-text), 0.15);
  backdrop-filter: blur(1.61716px);
  position: relative;
  border-radius: 6px;
  padding: 8px calc(5px + 5 * (100vw - 320px) / 1600);
  padding-inline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.view-product .product-detail-box .sale-details .product-timer li + li::before {
  position: absolute;
  left: -7px;
  top: 50%;
  font-weight: 700;
  font-size: 15px;
  color: rgba(var(--content-color), 0.5);
}
[dir=rtl] .view-product .product-detail-box .sale-details .product-timer li + li::before {
  left: unset;
  right: -7px;
}
.view-product .product-detail-box .sale-details .product-timer li + li::after {
  content: ":";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(-10px + -5 * (100vw - 320px) / 1600);
  font-size: calc(24px + 6 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
}
[dir=rtl] .view-product .product-detail-box .sale-details .product-timer li + li::after {
  left: unset;
  right: -15px;
}
.view-product .product-detail-box .sale-details .product-timer li .counter > div {
  font-weight: 600;
  font-size: calc(19px + 5 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
  line-height: 1;
}
.view-product .product-detail-box .sale-details .product-timer li span {
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--content-color), 1);
  text-transform: capitalize;
}
.view-product .product-detail-box .availability h6 span {
  font-weight: 500;
}
@media (max-width: 768px) {
  .view-product .product-detail-box .availability h6 {
    justify-content: center;
  }
}
.view-product .product-detail-box .buttons {
  display: flex;
  align-items: center;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.view-product .product-detail-box .buttons .buy-btn:hover {
  color: rgba(var(--white), 1);
  background-color: rgba(var(--theme-color), 1);
}
.view-product .product-detail-box .buttons .wishlist-btn {
  width: calc(30px + 10 * (100vw - 320px) / 1600);
  height: calc(30px + 10 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--theme-color), 1);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 100%;
}
.view-product .product-detail-box .buttons .wishlist-btn i {
  font-size: calc(16px + 6 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .view-product .product-detail-box .buttons {
    justify-content: center;
  }
}
.view-product .product-detail-box .price span {
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.view-product .product-detail-box .price del {
  font-weight: 500;
}
.view-product .product-detail-box .related-product h5 {
  margin-bottom: 8px;
  font-weight: 500;
  margin-top: -3px;
}
.view-product .product-detail-box .related-product .product-list {
  display: flex;
  align-items: center;
  gap: 10px;
}
.view-product .product-detail-box .related-product .product-list:not(:last-of-type) {
  margin-bottom: 10px;
}
.view-product .product-detail-box .related-product .product-list .img-wrap img {
  width: 70px;
}
.view-product .product-detail-box .related-product .product-list .content-wrap h5 {
  font-size: 16px;
  font-weight: normal;
  color: rgba(var(--dark-text), 1);
}
.view-product .product-detail-box .pickup-box .product-info {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.view-product .product-detail-box .pickup-box .product-info .product-info-list {
  display: flex;
  flex-wrap: wrap;
  gap: calc(5px + 5 * (100vw - 320px) / 1600);
  align-items: flex-start;
  text-align: left;
  display: inline-block;
  column-count: 2;
  padding: 15px;
  border-radius: 12px;
  width: 80%;
  background-color: rgba(var(--box-bg), 1);
}
.view-product .product-detail-box .pickup-box .product-info .product-info-list-2 {
  display: flex;
}
@media (max-width: 1500px) {
  .view-product .product-detail-box .pickup-box .product-info .product-info-list-2 {
    width: 100%;
  }
}
@media (max-width: 1450px) {
  .view-product .product-detail-box .pickup-box .product-info .product-info-list-2 {
    column-count: 1;
    padding: 15px;
  }
}
.view-product .product-detail-box .pickup-box .product-info .product-info-list-3 {
  width: 50%;
}
.view-product .product-detail-box .pickup-box .product-info .product-info-list-3 li {
  margin-bottom: 7px;
}
.view-product .product-detail-box .pickup-box .product-info .product-info-list li {
  width: 100%;
  font-size: calc(14px + 1 * (100vw - 320px) / 1600);
  position: relative;
  color: rgba(var(--content-color), 1);
}
.view-product .product-detail-box .pickup-box .product-info .product-info-list li::after {
  position: absolute;
  top: 50%;
  width: 5px;
  height: 5px;
  left: 0;
  background-color: rgba(var(--content-color), 1);
  border-radius: 100%;
}
[dir=rtl] .view-product .product-detail-box .pickup-box .product-info .product-info-list li::after {
  left: unset;
  right: 0;
}
.view-product .product-detail-box .pickup-box .product-info .product-info-list li a {
  margin-left: 5px;
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.view-product .product-detail-box .pickup-box .product-info .product-info-list li a + a {
  margin-left: 0;
}
[dir=rtl] .view-product .product-detail-box .pickup-box .product-info .product-info-list li a + a {
  margin-left: unset;
  margin-right: 0;
}
.view-product .product-detail-box .pickup-box .pickup-icon i {
  font-size: 20px;
  color: rgba(var(--theme-color), 1);
}
.view-product .product-detail-box .pickup-box .product-title {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.view-product .product-detail-box .pickup-box .pickup-detail {
  margin-top: 5px;
}
.view-product .product-detail-box .pickup-box .pickup-detail p {
  width: 100%;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  line-height: 1.6;
}
.view-product .product-detail-box .pickup-box .pickup-detail h6 {
  margin-top: 4px;
}
.view-product .product-detail-box .pickup-box .pickup-detail a {
  margin-top: 0;
  color: rgba(var(--theme-color), 1);
  display: inline-block;
}
.view-product .product-detail-box .pickup-box .share-icons {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.view-product .product-detail-box .pickup-box .share-icons h4 {
  color: rgba(var(--dark-text), 1);
}
.view-product .product-detail-box .pickup-box .share-icons .icon-list {
  display: flex;
  align-items: center;
  gap: 10px;
}
.view-product .product-detail-box .pickup-box .share-icons .icon-list li {
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
}
.view-product .product-detail-box .pickup-box .share-icons .icon-list li:hover {
  color: rgba(var(--theme-color), 1);
}
.view-product .right-sidebar {
  position: sticky;
  top: 0;
}
@media (max-width: 992px) {
  .view-product .right-sidebar {
    display: none;
  }
}
.view-product .right-sidebar .vendor-box {
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
}
.view-product .right-sidebar .vendor-box .verndor-contain {
  display: flex;
  gap: calc(5px + 5 * (100vw - 320px) / 1600);
  padding: 5px calc(10px + 5 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
  align-items: center;
  border-radius: 4px;
  width: 100%;
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-image {
  width: 64px;
  height: auto;
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-name {
  display: flex;
  flex-direction: column;
  width: calc(100% - 63px);
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-name h6 {
  color: rgba(var(--dark-text), 1);
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-name .vendor-rating {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-name .vendor-rating .rating {
  display: flex;
  align-items: center;
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-name .vendor-rating .rating li .fill {
  font-size: 20px;
  color: rgba(var(--secondary-color), 1);
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-name .vendor-rating .rating li .icon {
  font-size: 20px;
  color: rgba(var(--secondary-color), 1);
}
.view-product .right-sidebar .vendor-box .verndor-contain .vendor-name .vendor-rating span {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.view-product .right-sidebar .vendor-box .vendor-list {
  margin-top: calc(13px + 11 * (100vw - 320px) / 1600);
}
.view-product .right-sidebar .vendor-box .vendor-list ul {
  display: flex;
  flex-wrap: wrap;
  gap: calc(9px + 9 * (100vw - 320px) / 1600);
  align-items: center;
}
[dir=rtl] .view-product .right-sidebar .vendor-box .vendor-list ul {
  padding-right: 0;
}
.view-product .right-sidebar .vendor-box .vendor-list ul li {
  display: block;
}
.view-product .right-sidebar .vendor-box .vendor-list ul li .address-contact {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}
.view-product .right-sidebar .vendor-box .vendor-list ul li .address-contact i {
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
}
.view-product .right-sidebar .vendor-box .vendor-list ul li .address-contact h5 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.view-product .right-sidebar .vendor-box .vendor-list ul li .address-contact h5 span {
  margin-left: 7px;
  font-weight: 400;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
}
.view-product .right-sidebar .vendor-box .vendor-detail {
  margin: calc(13px + 15 * (100vw - 320px) / 1600) 0 0;
  line-height: 1.7;
  color: rgba(var(--content-color), 1);
  padding-bottom: calc(10px + 8 * (100vw - 320px) / 1600);
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.view-product .right-sidebar .category-menu {
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 5px;
}
.view-product .right-sidebar .category-menu h4 {
  position: relative;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.view-product .right-sidebar .category-menu h4::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  bottom: -8px;
  left: 0;
  background-color: rgba(var(--theme-color), 1);
}
[dir=rtl] .view-product .right-sidebar .category-menu h4::after {
  left: unset;
  right: 0px;
}
@media (max-width: 992px) {
  .view-product .right-sidebar .category-menu h4::after {
    background-color: transparent;
  }
}
.view-product .right-sidebar .category-menu .product-list.product-right-sidebar-list li {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px dashed rgba(var(--dark-text), 0.15);
}
.view-product .right-sidebar .category-menu .product-list.product-right-sidebar-list li:last-child {
  border-bottom: none;
}
.view-product .right-sidebar .category-menu .product-list.product-right-sidebar-list li .offer-product {
  display: flex;
  align-items: center;
  gap: 10px;
}
.view-product .right-sidebar .category-menu .product-list.product-right-sidebar-list li .offer-product .offer-image .img {
  width: 50px;
}
.view-product .right-sidebar .category-menu .product-list.product-right-sidebar-list li .offer-product .offer-detail {
  width: calc(100% - 50px - 10px);
}
.view-product .right-sidebar .category-menu .product-list.product-right-sidebar-list li .offer-product .offer-detail .name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgba(var(--dark-text), 1);
  font-weight: 500;
}
.view-product .right-sidebar .category-menu .product-list.product-right-sidebar-list li .offer-product .offer-detail span {
  color: rgba(var(--content-color), 1);
}

.tab-style2 {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  overflow-x: auto;
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.tab-style2 .nav-item {
  color: rgba(var(--white), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  cursor: pointer;
  border-radius: 0;
  transition: all 0.3s ease;
  border-radius: 0;
}
.tab-style2 .nav-item:hover .nav-link {
  color: rgba(var(--theme-color), 1);
}
.tab-style2 .nav-item .nav-link {
  width: 100%;
  font-weight: 400;
  padding: 10px calc(10px + 10 * (100vw - 320px) / 1600);
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  white-space: nowrap;
  color: rgba(var(--content-color), 1);
  border: none;
}
.tab-style2 .nav-item .nav-link:hover {
  border-left: none;
  border-right: none;
  border-top: none;
}
.tab-style2 .nav-item .nav-link:focus {
  border-color: transparent;
}
.tab-style2 .nav-item .nav-link.active {
  width: 100%;
  font-weight: 500;
  color: rgba(var(--theme-color), 1);
  border-bottom: 3px solid rgba(var(--theme-color), 1);
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
}

.tab-content .tab-pane .content {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  line-height: 1.5;
}
.tab-content .tab-pane .features-list {
  width: 100%;
  padding-inline-start: calc(20px + 10 * (100vw - 320px) / 1600);
}
.tab-content .tab-pane .features-list li {
  width: 100%;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--dark-text), 1);
  display: list-item;
  list-style-type: disc;
  padding-top: 10px;
}
.tab-content .tab-pane table.info-table {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--dark-text), 0.15);
}
.tab-content .tab-pane table.info-table tbody tr td {
  padding: 15px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.tab-content .tab-pane .rating-title {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
  margin-bottom: calc(5px + 5 * (100vw - 320px) / 1600);
}
.tab-content .tab-pane .rating-title h4 {
  margin: 0;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.tab-content .tab-pane .rating-option {
  display: flex;
  align-items: center;
  gap: calc(5px + 5 * (100vw - 320px) / 1600);
  margin-bottom: -10px;
}
.tab-content .tab-pane .rating-option .rating li .fill {
  font-size: 20px;
  color: rgba(var(--secondary-color), 1);
}
.tab-content .tab-pane .rating-option .rating li .icon {
  font-size: 20px;
  color: rgba(var(--secondary-color), 1);
}
.tab-content .tab-pane .rating-box {
  margin-top: calc(5px + 5 * (100vw - 320px) / 1600);
}
.tab-content .tab-pane .rating-box ul {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: calc(10px + 5 * (100vw - 320px) / 1600);
}
.tab-content .tab-pane .rating-box ul li {
  width: 100%;
}
.tab-content .tab-pane .rating-box ul li .rating-list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
}
.tab-content .tab-pane .rating-box ul li .rating-list h5 {
  white-space: nowrap;
}
.tab-content .tab-pane .rating-box ul li .rating-list .progress {
  width: 100%;
  border-radius: 3px;
}
.tab-content .tab-pane .rating-box ul li .rating-list .progress .progress-bar {
  background: rgba(var(--theme-color), 1);
}
.tab-content .tab-pane .review-title {
  margin: 20px 0 10px;
}
.tab-content .tab-pane .review-title h4 {
  margin: 0;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.tab-content .tab-pane .review-form .form-label {
  color: rgba(var(--dark-text), 1);
}
.tab-content .tab-pane .review-form .form-control {
  padding: calc(8px + 4 * (100vw - 320px) / 1600);
  box-shadow: none;
  border-color: rgba(var(--dark-text), 0.15);
}
.tab-content .tab-pane .review-form .form-control::placeholder {
  color: rgb(136, 136, 136);
}
.tab-content .tab-pane .review-section .review-box-list li {
  width: 100%;
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
  padding-bottom: 15px;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px;
}
.tab-content .tab-pane .review-section .review-box-list li:first-child {
  margin-top: 0;
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-head {
  display: flex;
  align-items: center;
  gap: 12px;
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-head .reviewer-name {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-head h6 {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-head .review-image .img {
  width: calc(45px + 15 * (100vw - 320px) / 1600);
  height: calc(45px + 15 * (100vw - 320px) / 1600);
  border-radius: 100%;
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-head .rating-star {
  display: flex;
  align-items: center;
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-head .rating-star li {
  padding: 0;
  margin: 0;
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-head .rating-star li .star {
  color: rgba(var(--rate-color), 1);
  font-size: 20px;
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-content {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-content h6 {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.tab-content .tab-pane .review-section .review-box-list li .review-box .review-content p {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  color: rgba(var(--content-color), 1);
}

.product-section .grocery-product-page-banner {
  margin-top: 40px;
}
.product-section .grocery-product-page-banner .img {
  height: 1180px;
  border-radius: 6px;
}

.sticky-bottom-cart {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  background-color: rgba(var(--white), 1);
  padding: calc(11px + -5 * (100vw - 320px) / 1600) 0;
  box-shadow: 0 0 3px 1px rgba(var(--dark-text), 0.06);
  transition: all 0.3s ease;
}
.sticky-bottom-cart .cart-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.sticky-bottom-cart .product-image {
  display: flex;
  align-items: center;
}
.sticky-bottom-cart .product-image img {
  width: 90px;
  height: 80px;
  object-fit: contain;
  object-position: top;
}
.sticky-bottom-cart .product-image .content {
  margin-left: 12px;
  color: rgba(var(--dark-text), 1);
  margin-top: 0;
}
[dir=rtl] .sticky-bottom-cart .product-image .content {
  margin-left: unset;
  margin-right: 12px;
}
@media (max-width: 576px) {
  .sticky-bottom-cart .product-image .content {
    display: none;
  }
}
.sticky-bottom-cart .product-image .content h5,
.sticky-bottom-cart .product-image .content h6 {
  margin-bottom: 0;
}
.sticky-bottom-cart .product-image .content h5 {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.sticky-bottom-cart .product-image .content h6 {
  font-size: 15px;
  margin-top: 6px;
  font-weight: 500;
}
.sticky-bottom-cart .product-image .content h6 del {
  margin-left: 7px;
  color: rgba(var(--content-color), 1);
}
[dir=rtl] .sticky-bottom-cart .product-image .content h6 del {
  margin-left: unset;
  margin-right: 7px;
}
.sticky-bottom-cart .product-image .content h6 span {
  margin-left: 5px;
  color: rgba(var(--theme-color), 1);
}
[dir=rtl] .sticky-bottom-cart .product-image .content h6 span {
  margin-left: 0;
  margin-right: 5px;
}
@media (max-width: 576px) {
  .sticky-bottom-cart .product-image .content h6 span {
    display: none;
  }
}
.sticky-bottom-cart .quantity-box .heading {
  color: rgba(var(--dark-text), 1);
  font-weight: 600;
}
.sticky-bottom-cart .quantity-box .plus-minus {
  border: 1px solid rgba(var(--dark-text), 0.15);
  padding: calc(5px + 5 * (100vw - 320px) / 1600);
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  background-color: rgba(var(--box-bg), 1);
  text-align: center;
}
.sticky-bottom-cart .quantity-box .plus-minus input {
  background-color: rgba(var(--white), 1);
  color: rgba(var(--theme-color), 1);
  border: none;
  font-size: 18px;
  outline: none;
  width: calc(15px + 20 * (100vw - 320px) / 1600);
  text-align: center;
}
.sticky-bottom-cart .quantity-box .plus-minus i {
  font-size: 18px;
  color: rgba(var(--dark-text), 1);
}
.sticky-bottom-cart .quantity-box .plus-minus i.sub {
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
}
[dir=rtl] .sticky-bottom-cart .quantity-box .plus-minus i.sub {
  padding-right: unset;
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
  border-right: unset;
}
.sticky-bottom-cart .quantity-box .plus-minus i.add {
  padding-left: 5px;
  border-left: 1px solid rgba(var(--dark-text), 0.15);
}
[dir=rtl] .sticky-bottom-cart .quantity-box .plus-minus i.add {
  padding-left: unset;
  padding-right: 5px;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
  border-left: unset;
}
.sticky-bottom-cart .buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .sticky-bottom-cart .buttons {
    justify-content: center;
  }
}
.sticky-bottom-cart .buttons .add-btn1 a {
  padding: 12px 40px;
  white-space: nowrap;
}
.sticky-bottom-cart .buttons .add-btn1 a:hover {
  background-color: rgba(var(--theme-color), 1);
  border-color: rgba(var(--theme-color), 1);
  color: rgba(var(--white), 1);
}
.sticky-bottom-cart .buttons .add-btn1 a.wishlist-btn {
  display: none;
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--dark-text), 1);
}
.sticky-bottom-cart .buttons .add-btn1 a.wishlist-btn:hover {
  background-color: rgba(var(--theme-color), 1);
  border-color: rgba(var(--theme-color), 1);
  color: rgba(var(--white), 1);
}

/*=====================
  5.13  profile style start
==========================*/
.profile-section .profile-sidebar {
  width: 100%;
  background-color: rgba(var(--box-bg), 1);
  border-radius: 6px 6px 0 0;
}
.profile-section .profile-sidebar.sticky-top {
  top: 30px;
  z-index: 1;
}
.profile-section .profile-sidebar .profile-cover {
  position: relative;
  width: 100%;
  height: 150px;
  background: linear-gradient(333.77deg, #ff8d2f 9.66%, #ffd3b0 101.81%);
  border-radius: 10px 10px 0px 0px;
}
.profile-section .profile-sidebar .profile-cover .profile-pic {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  padding: 5px;
  background-color: rgba(var(--white), 1);
}
.profile-section .profile-sidebar .profile-name {
  margin-top: 55px;
}
.profile-section .profile-sidebar .profile-name .user-name {
  margin-top: 15px;
  text-align: center;
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.profile-section .profile-sidebar .profile-name h6 {
  margin-top: 4px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: rgba(var(--content-color), 1);
}
.profile-section .profile-sidebar .profile-list {
  padding-left: calc(10px + 10 * (100vw - 320px) / 1600);
  padding-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  margin-top: calc(20px + 20 * (100vw - 320px) / 1600);
}
[dir=rtl] .profile-section .profile-sidebar .profile-list {
  padding-left: unset;
  padding-right: calc(10px + 10 * (100vw - 320px) / 1600);
}
@media (max-width: 991px) {
  .profile-section .profile-sidebar .profile-list {
    display: flex;
    gap: 15px;
    overflow: auto;
    margin: calc(10px + 20 * (100vw - 320px) / 1600) 10px 0;
  }
}
.profile-section .profile-sidebar .profile-list li {
  width: 100%;
  padding: 0;
  padding-block: 10px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  cursor: pointer;
}
[dir=rtl] .profile-section .profile-sidebar .profile-list li {
  text-align: right;
}
.profile-section .profile-sidebar .profile-list li:hover i,
.profile-section .profile-sidebar .profile-list li:hover a {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (min-width: 991px) {
  .profile-section .profile-sidebar .profile-list li:first-child {
    padding-top: 0;
  }
}
.profile-section .profile-sidebar .profile-list li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
@media (max-width: 991px) {
  .profile-section .profile-sidebar .profile-list li {
    padding: 0;
    border: none;
  }
}
.profile-section .profile-sidebar .profile-list li a {
  width: 100%;
  color: rgba(var(--dark-text), 1);
  cursor: pointer;
}
.profile-section .profile-sidebar .profile-list li i {
  color: rgba(var(--dark-text), 1);
}
.profile-section .profile-sidebar .profile-list li.active {
  position: relative;
}
.profile-section .profile-sidebar .profile-list li.active a {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  font-weight: 500;
}
.profile-section .profile-sidebar .profile-list li.active::after {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  border-right: 3px solid rgba(var(--theme-color), 1);
}
[dir=rtl] .profile-section .profile-sidebar .profile-list li.active::after {
  right: unset;
  left: 0;
  border-right: unset;
  border-left: 3px solid rgba(var(--theme-color), 1);
}
@media (max-width: 991px) {
  .profile-section .profile-sidebar .profile-list li.active::after {
    display: none;
  }
}
.profile-section .profile-sidebar .profile-list li.active i {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.profile-section .change-profile-content {
  width: 100%;
  height: 100%;
  padding: calc(20px + 20 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
}
.profile-section .change-profile-content .profile-details-list li {
  width: 100%;
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background-color: rgba(var(--white), 1);
  border-radius: 6px;
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
}
.profile-section .change-profile-content .profile-details-list li:last-child {
  margin-bottom: 0;
}
.profile-section .change-profile-content .profile-details-list li .profile-content i {
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--theme-color), 1);
}
.profile-section .change-profile-content .profile-details-list li .profile-content span {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: rgba(var(--dark-text), 1);
}
.profile-section .change-profile-content .profile-details-list li .profile-content h6 {
  font-weight: 400;
  color: rgba(var(--content-color), 1);
  padding-left: calc(22px + 8 * (100vw - 320px) / 1600);
  margin-top: 4px;
}
[dir=rtl] .profile-section .change-profile-content .profile-details-list li .profile-content h6 {
  padding-left: unset;
  padding-right: calc(22px + 8 * (100vw - 320px) / 1600);
}
.profile-section .change-profile-content .profile-details-list li .btn {
  padding: 3px 15px;
}
.profile-section .my-order-content {
  width: 100%;
  height: 100%;
  padding: calc(20px + 20 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
}
.profile-section .my-order-content .title {
  position: relative;
}
.profile-section .my-order-content .title .loader-line {
  width: 90px;
  height: 2px;
  overflow: hidden;
  background-color: #eee;
  border-radius: 20px;
  position: absolute;
  top: -10px;
}
.dark .profile-section .my-order-content .title .loader-line {
  background-color: #424242;
}
.profile-section .my-order-content .title .loader-line::before {
  content: "";
  position: absolute;
  width: 90px;
  height: 3px;
  top: 0px;
  left: -60px;
  background-color: rgba(var(--box-bg), 1);
}
[dir=rtl] .profile-section .my-order-content .title .loader-line::before {
  left: unset;
  right: 0;
}
.profile-section .my-order-content .order-box-list li {
  width: 100%;
  background-color: rgba(var(--white), 1);
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  border-radius: 6px;
}
.profile-section .my-order-content .order-box-list li:last-child {
  margin-bottom: 0;
}
.profile-section .my-order-content .order-box-list li .order-box {
  position: relative;
}
@media (max-width: 430px) {
  .profile-section .my-order-content .order-box-list li .order-box {
    display: block;
  }
}
.profile-section .my-order-content .order-box-list li .order-box .order-box-content {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  border-bottom: 1px dashed rgba(var(--dashed-line), 1);
}
@media (max-width: 375px) {
  .profile-section .my-order-content .order-box-list li .order-box .order-box-content {
    flex-wrap: wrap;
  }
}
.profile-section .my-order-content .order-box-list li .order-box .order-box-content .brand-icon .icon {
  width: calc(60px + 10 * (100vw - 320px) / 1600);
  height: calc(60px + 10 * (100vw - 320px) / 1600);
  object-fit: contain;
}
.profile-section .my-order-content .order-box-list li .order-box .order-box-content .order-details {
  width: calc(100% - (60px + 10 * (100vw - 320px) / 1600) - 10px);
}
@media (max-width: 430px) {
  .profile-section .my-order-content .order-box-list li .order-box .order-box-content .order-details {
    margin-top: 5px;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .profile-section .my-order-content .order-box-list li .order-box .order-box-content .order-details .details-btn {
    position: absolute;
    top: 20px;
    right: 10px;
  }
}
.profile-section .my-order-content .order-title {
  font-weight: 600;
  font-size: calc(15px + 5 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.profile-section .cards-content {
  width: 100%;
  height: 100%;
  padding: calc(20px + 20 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
}
.profile-section .cards-content .debit-card {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  border: rgba(61, 61, 61, 0.15);
  border-radius: 6px;
  background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%);
}
.profile-section .cards-content .debit-card .card-details {
  padding: 20px;
}
.profile-section .cards-content .debit-card .card-details .card-name {
  color: rgba(255, 255, 255, 0.5);
}
.profile-section .cards-content .debit-card .card-details .chip {
  margin-top: 12px;
}
.profile-section .cards-content .debit-card .card-details h6 {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
.profile-section .cards-content .debit-card .card-details h5 {
  font-weight: 600;
  color: #fff;
}
.profile-section .cards-content .debit-card .card-details .ac-details {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-section .cards-content .debit-card .card-details .ac-details h6 {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
.profile-section .cards-content .debit-card .card-details .ac-details h3 {
  font-weight: 600;
  color: #fff;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.profile-section .cards-content .debit-card .card-details .user-name {
  margin-top: 10px;
}
.profile-section .cards-content .debit-card .card-details .user-name h6 {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
.profile-section .cards-content .debit-card .card-details .user-name h5 {
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.profile-section .cards-content .debit-card:hover .card-hover {
  opacity: 1;
  border-radius: 6px;
  transform: scale(1);
  transition: all 0.3s ease;
}
.profile-section .cards-content .debit-card .card-hover {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  opacity: 0;
  transition: 0.4s ease;
}
.profile-section .cards-content .debit-card .card-hover .edit-icon {
  color: rgba(var(--white), 1);
}
.profile-section .cards-content .debit-card.color-1 {
  background-color: rgb(75, 119, 160);
}
.profile-section .cards-content .debit-card.color-2 {
  background-color: rgb(119, 120, 118);
}
.profile-section .cards-content .debit-card.color-3 {
  background-color: rgb(134, 184, 207);
}
.profile-section .cards-content .add-card {
  width: 100%;
  min-height: 227px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(var(--white), 1);
  border-radius: 6px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .profile-section .cards-content .add-card {
    min-height: 100%;
    padding: 20px;
  }
}
@media (max-width: 575px) {
  .profile-section .cards-content .add-card {
    padding: 70px;
  }
}
.profile-section .cards-content .add-card .add-icon {
  font-size: 25px;
  color: rgba(var(--dark-text), 1);
}
.profile-section .address-content {
  width: 100%;
  height: 100%;
  padding: calc(20px + 20 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
}
.profile-section .address-content .address-box {
  border: 1px solid rgba(var(--dark-text), 0.15);
  background-color: rgba(var(--white), 1);
  border-radius: 6px;
}
.profile-section .address-content .address-box.new-address-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 15px;
}
@media (max-width: 767px) {
  .profile-section .address-content .address-box.new-address-box .new-address-btn {
    width: 100%;
  }
}
.profile-section .address-content .address-box .address-title {
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.profile-section .address-content .address-box .address-title h6 {
  font-weight: 500;
  color: rgba(var(--dark-text), 1);
}
.profile-section .address-content .address-box .address-title .icon {
  font-size: 22px;
  color: rgba(var(--theme-color), 1);
}
.profile-section .address-content .address-box .address-title .edit-btn {
  font-size: calc(13px + 5 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
}
.profile-section .address-content .address-box .address-details {
  padding: 15px;
}
.profile-section .address-content .address-box .address-details h6 {
  color: rgba(var(--content-color), 1);
}
.profile-section .address-content .address-box .address-details .phone-number {
  margin-top: 12px;
  color: rgba(var(--content-color), 1);
}
.profile-section .address-content .address-box .address-details .option-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px dashed rgba(var(--dark-text), 0.15);
}
.profile-section .setting-content {
  width: 100%;
  height: 100%;
  padding: calc(20px + 20 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: rgba(var(--box-bg), 1);
}
.profile-section .setting-content .notification-setting {
  padding: calc(10px + 10 * (100vw - 320px) / 1600);
  background: rgba(var(--white), 1);
  border-radius: 6px;
}
.profile-section .setting-content .notification-setting li {
  display: block;
  border-bottom: 1px solid rgba(var(--dashed-line), 0.5);
}
.profile-section .setting-content .notification-setting li:last-child {
  border-bottom: none;
}
.profile-section .setting-content .notification-setting li .notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
}
.profile-section .setting-content .notification-setting li .notification h6 {
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.profile-section .setting-content .notification-setting li .notification input[type=checkbox] {
  position: relative;
  width: 37px;
  height: 22px;
  appearance: none;
  background: rgba(var(--box-bg), 1);
  outline: none;
  border-radius: 50px;
  cursor: pointer;
}
.profile-section .setting-content .notification-setting li .notification input[type=checkbox]:checked {
  background: rgba(var(--theme-color), 0.1);
}
.profile-section .setting-content .notification-setting li .notification input[type=checkbox]:checked::before {
  transform: translateX(80%) translateY(-50%);
  background: rgba(var(--theme-color), 1);
}
[dir=rtl] .profile-section .setting-content .notification-setting li .notification input[type=checkbox]:checked::before {
  transform: translateX(-80%) translateY(-50%);
}
.profile-section .setting-content .notification-setting li .notification input[type=checkbox]:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(var(--content-color), 0.5);
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: 0.5s;
}
[dir=rtl] .profile-section .setting-content .notification-setting li .notification input[type=checkbox]:before {
  left: unset;
  right: 5px;
}
.profile-section .setting-content .delete-account {
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
}
.profile-section .setting-content .delete-account h3 {
  margin-bottom: calc(10px + 5 * (100vw - 320px) / 1600);
}
@media (max-width: 375px) {
  .profile-section .setting-content .delete-account h3 {
    margin-bottom: 10px;
  }
}

/*========================
   5.14 shop style start
==========================*/
.shop-section .left-box {
  position: sticky;
  top: 0;
  border-right: 1px solid rgba(var(--dark-text), 0.15);
  height: 100%;
}
[dir=rtl] .shop-section .left-box {
  border-left: 1px solid rgba(var(--dark-text), 0.15);
  border-right: unset;
}
.shop-section .left-box.show {
  transform: translateX(0);
}
@media (max-width: 992px) {
  .shop-section .left-box {
    position: fixed;
    transition: 0.3s ease-in-out;
    top: 0;
    left: 0;
    width: calc(280px + 70 * (100vw - 320px) / 672);
    overflow: auto;
    height: 100vh;
    background-color: rgba(var(--white), 1);
    padding: calc(16px + 4 * (100vw - 320px) / 1600);
    z-index: 4;
    display: block;
    transform: translateX(-350px);
  }
  [dir=rtl] .shop-section .left-box {
    left: unset;
    right: 0;
  }
}
.shop-section .left-box .shop-left-sidebar {
  padding-right: calc(16px + 4 * (100vw - 320px) / 1600);
}
[dir=rtl] .shop-section .left-box .shop-left-sidebar {
  padding-right: unset;
  padding-left: calc(16px + 4 * (100vw - 320px) / 1600);
}
.shop-section .left-box .shop-left-sidebar .filter-category {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.shop-section .left-box .shop-left-sidebar .filter-category .filter-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop-section .left-box .shop-left-sidebar .filter-category .filter-title h3 {
  font-weight: 500;
}
.shop-section .left-box .shop-left-sidebar .filter-category .filter-title a {
  color: rgba(var(--theme-color), 1);
}
.shop-section .left-box .shop-left-sidebar .filter-category .filter-item-list {
  display: flex;
  flex-wrap: wrap;
  gap: calc(10px + 5 * (100vw - 320px) / 1600);
  padding-top: 15px;
}
.shop-section .left-box .shop-left-sidebar .filter-category .filter-item-list li {
  background-color: rgba(var(--box-bg), 1);
  position: relative;
  border-radius: 4px;
}
.shop-section .left-box .shop-left-sidebar .filter-category .filter-item-list li a {
  display: flex;
  align-items: center;
  gap: 10px;
}
.shop-section .left-box .shop-left-sidebar .filter-category .filter-item-list li:hover {
  background-color: rgba(var(--theme-color), 0.1) !important;
}
.shop-section .left-box .shop-left-sidebar .search-box {
  margin-bottom: 30px;
}
.shop-section .left-box .shop-left-sidebar .search-box .form-control {
  padding: 12px;
  box-shadow: none;
  border: 1px solid rgba(var(--dark-text), 0.15);
  cursor: pointer;
}
.shop-section .left-box .shop-left-sidebar .category-list {
  display: flex;
  flex-wrap: wrap;
  gap: calc(10px + 5 * (100vw - 320px) / 1600);
}
.shop-section .left-box .shop-left-sidebar .category-list.custom-height {
  max-height: 190px;
  overflow-y: auto;
  height: 100%;
}
.shop-section .left-box .shop-left-sidebar .category-list {
  scrollbar-width: none;
}
.shop-section .left-box .shop-left-sidebar .category-list::-webkit-scrollbar {
  width: 3px;
}
.shop-section .left-box .shop-left-sidebar .category-list li {
  display: block;
  width: 100%;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check {
  display: flex;
  align-items: center;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check input {
  margin-top: -4px;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .checkbox-animated {
  cursor: pointer;
  position: relative;
  margin-right: calc(6px + 10 * (100vw - 992px) / 928);
  height: 100%;
}
[dir=rtl] .shop-section .left-box .shop-left-sidebar .category-list li .form-check .checkbox-animated {
  margin-left: calc(6px + 10 * (100vw - 992px) / 928);
  margin-right: unset;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .checkbox-animated::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 6px;
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), rotate(-45deg) scale(0, 0);
  transform: rotate(-45deg) scale(0, 0);
  left: 4px;
  top: 4px;
  z-index: 1;
  border: 2px solid rgba(var(--theme-color), 1);
  border-top-style: none;
  border-right-style: none;
}
[dir=rtl] .shop-section .left-box .shop-left-sidebar .category-list li .form-check .checkbox-animated::before {
  left: unset;
  right: 4px;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .checkbox-animated::before:not(:checked) {
  content: none;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .checkbox-animated::after {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  top: -2px;
  left: 0;
  background-color: rgba(var(--white), 1);
  border: 2px solid rgba(var(--dark-text), 0.15);
  cursor: pointer;
}
[dir=rtl] .shop-section .left-box .shop-left-sidebar .category-list li .form-check .checkbox-animated::after {
  left: unset;
  right: 0;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .checkbox-animated:checked::before {
  transform: rotate(-45deg) scale(1, 1);
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .form-check-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 15px;
}
[dir=rtl] .shop-section .left-box .shop-left-sidebar .category-list li .form-check .form-check-label {
  margin-left: unset;
  margin-right: 15px;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .form-check-label .name {
  font-size: calc(15px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  width: 80%;
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .form-check-label .number {
  color: rgba(var(--content-color), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .form-check-label .rating {
  display: flex;
  align-items: center;
  gap: calc(2px + 3 * (100vw - 992px) / 928);
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .form-check-label .rating .icon {
  font-size: 20px;
  color: rgba(var(--secondary-color));
}
.shop-section .left-box .shop-left-sidebar .category-list li .form-check .form-check-label .rating .fill-icon {
  font-size: 20px;
  color: rgba(var(--secondary-color));
}
.shop-section .left-box .shop-left-sidebar .sidebar-title {
  margin: 30px 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--dark-text), 0.15);
}
.shop-section .left-box .shop-left-sidebar .wrapper .slider {
  position: relative;
  height: 3px;
  background-color: rgba(var(--content-color), 0.15);
}
.shop-section .left-box .shop-left-sidebar .wrapper .slider .progress {
  position: absolute;
  height: 100%;
  left: 25%;
  right: 25%;
  border-radius: 5px;
  background: rgba(var(--theme-color), 1);
}
.shop-section .left-box .shop-left-sidebar .wrapper .range-input {
  position: relative;
}
.shop-section .left-box .shop-left-sidebar .wrapper .range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  appearance: none;
}
.shop-section .left-box .shop-left-sidebar .wrapper input[type=number]::-webkit-outer-spin-button,
.shop-section .left-box .shop-left-sidebar .wrapper input[type=number]::-webkit-inner-spin-button {
  appearance: none;
}
.shop-section .left-box .shop-left-sidebar .wrapper .price-input {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 17px;
}
.shop-section .left-box .shop-left-sidebar .wrapper .price-input .field input {
  width: 100%;
  font-size: 17px;
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(var(--content-color), 0.3);
  border-radius: 4px;
  color: rgba(var(--dark-text), 1);
  background-color: transparent;
  outline: none;
}
.shop-section .left-box .shop-left-sidebar .wrapper .price-input .separator {
  padding: 0 10px;
}
.shop-section .left-box .shop-left-sidebar .wrapper input[type=range]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background: rgba(var(--theme-color), 1);
  pointer-events: auto;
  box-shadow: 0 0 6px rgba(var(--black), 0.05);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
.shop-section .left-box .shop-left-sidebar .wrapper input[type=range]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background: rgba(var(--theme-color), 1);
  pointer-events: auto;
  box-shadow: 0 0 6px rgba(var(--black), 0.05);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.top-filter-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}
@media (max-width: 992px) {
  .top-filter-menu {
    margin: 15px 0;
  }
}
@media (max-width: 375px) {
  .top-filter-menu {
    display: block;
  }
}
.top-filter-menu .filter-button {
  padding: 8px;
  background-color: rgba(var(--theme-color), 1);
  border-radius: 6px;
}
.top-filter-menu .filter-button a {
  color: rgba(var(--white), 1);
}
@media (max-width: 768px) {
  .top-filter-menu .product-show-content {
    display: none;
  }
}
.top-filter-menu .category-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top-filter-menu .category-dropdown .dropdown {
  margin-left: 10px;
}
[dir=rtl] .top-filter-menu .category-dropdown .dropdown {
  margin-left: unset;
  margin-right: 10px;
}
.top-filter-menu .category-dropdown .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  padding: calc(9px + 1 * (100vw - 320px) / 1600) 15px;
  color: rgba(var(--content-color), 1);
  background-color: rgba(var(--box-bg), 1);
  border: 1px solid rgba(var(--dark-text), 0.15);
  border-radius: 5px;
}
.top-filter-menu .category-dropdown .dropdown .dropdown-toggle span {
  color: rgba(var(--dark-text), 1);
}
.top-filter-menu .category-dropdown .dropdown .dropdown-toggle::after {
  content: none;
}
.top-filter-menu .category-dropdown .dropdown .dropdown-toggle i {
  font-size: 18px;
  line-height: 1;
}
.top-filter-menu .category-dropdown .dropdown .dropdown-menu.show {
  background-color: rgba(var(--box-bg), 1);
}
.top-filter-menu .category-dropdown .dropdown .dropdown-menu li {
  display: block;
}
.top-filter-menu .category-dropdown .dropdown .dropdown-menu li a {
  color: rgba(var(--dark-text), 1);
}
.top-filter-menu .category-dropdown .dropdown .dropdown-menu li a:hover, .top-filter-menu .category-dropdown .dropdown .dropdown-menu li a:focus {
  background-color: transparent;
}
.top-filter-menu .category-dropdown .dropdown .dropdown-menu li a:active {
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--box-bg), 1);
}

.custom-pagination {
  margin-top: calc(22px + 13 * (100vw - 320px) / 1600);
}
.custom-pagination .pagination {
  flex-wrap: wrap;
  gap: calc(6px + 7 * (100vw - 320px) / 1600);
}
.custom-pagination .pagination .page-item {
  border-radius: 5px;
  overflow: hidden;
}
[dir=rtl] .custom-pagination .pagination .page-item:first-child {
  transform: rotate(-180deg);
}
[dir=rtl] .custom-pagination .pagination .page-item:last-child {
  transform: rotate(180deg);
}
.custom-pagination .pagination .page-item:not(:first-child) .page-link {
  margin: 0;
}
.custom-pagination .pagination .page-item.active .page-link {
  background: linear-gradient(to right, rgba(var(--theme-color), 1), rgba(var(--theme-color2), 1));
  border-color: rgba(var(--theme-color), 1);
  color: #fff;
}
.custom-pagination .pagination .page-item.disabled .page-link {
  opacity: 0.5;
  user-select: none;
}
.custom-pagination .pagination .page-item .page-link {
  color: rgba(var(--content-color), 1);
  border: 1px solid transparent;
  border-radius: 5px;
  z-index: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--box-bg), 1);
}
.custom-pagination .pagination .page-item .page-link:hover {
  border: 1px solid rgba(var(--theme-color), 1);
  background-color: unset;
  color: rgba(var(--theme-color), 1);
}
.custom-pagination .pagination .page-item .page-link:focus {
  color: rgba(var(--theme-color), 1);
  box-shadow: none;
}

.bg-overlay {
  width: 100vw;
  height: 100vh;
  background-color: #222;
  position: fixed;
  z-index: 3;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.bg-overlay.show {
  visibility: visible;
  opacity: 0.5;
}

/*=====================
  5.15  sub page style start
==========================*/
.side-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.testimonial-section .container {
  position: relative;
}
.testimonial-section .container::after, .testimonial-section .container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100%;
  z-index: 2;
  background: linear-gradient(270deg, rgba(var(--white), 0) 0%, rgb(249, 249, 249) 75%);
}
@media (max-width: 768px) {
  .testimonial-section .container::after, .testimonial-section .container::before {
    display: none;
  }
}
.testimonial-section .container::before {
  left: unset;
  right: 0;
  background: linear-gradient(90deg, rgba(var(--white), 0) 0%, rgb(249, 249, 249) 75%);
}
@media (max-width: 768px) {
  .testimonial-section .container::before {
    display: none;
  }
}

.testimonial-box {
  padding: calc(15px + 15 * (100vw - 320px) / 1600);
  background-color: rgba(var(--white), 1);
  border-radius: 6px;
  direction: ltr;
  height: 100%;
}
.testimonial-box .comma-icon {
  width: 50px;
}
.testimonial-box .testi-bottom {
  display: flex;
  align-items: center;
  gap: 20px;
}
.testimonial-box .testimonial-content {
  display: flex;
  flex-direction: column;
  gap: calc(10px + 5 * (100vw - 320px) / 1600);
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.testimonial-box .testimonial-content p {
  line-height: 1.5;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 0.5);
}
.testimonial-box .testimonial-content .img {
  width: calc(40px + 25 * (100vw - 320px) / 1600);
  height: calc(40px + 25 * (100vw - 320px) / 1600);
  border-radius: 100%;
}

.main-testimonial-content h2 {
  font-size: calc(30px + 20 * (100vw - 320px) / 1600);
  color: rgba(var(--dark-text), 1);
}
.main-testimonial-content p {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1600);
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
}
.main-testimonial-content .sticky-top {
  position: sticky;
  top: 50px;
  left: 0;
}
[dir=rtl] .main-testimonial-content .sticky-top {
  left: unset;
  right: 0;
}

.coming-soon {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100%;
  position: absolute;
  z-index: 1;
  padding-top: 0;
  top: 0;
  left: 0;
}
.coming-soon:before {
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  height: 100%;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
}
.coming-soon .coming-soon-logo {
  margin-bottom: 30px;
  padding-bottom: 20px;
  position: relative;
}
.coming-soon .coming-soon-logo .logo-img {
  width: 160px;
}
.coming-soon .coming-soon-logo:before {
  background-color: white;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 65px;
}
.coming-soon .coming-soon-text {
  padding: 0 calc(12px + 10 * (100vw - 320px) / 1600);
  position: relative;
  text-align: center;
}
.coming-soon .coming-soon-text .h2 {
  color: white;
  font-size: 26px;
  font-weight: 500;
  margin: 0 0 10px;
  width: 100%;
}
.coming-soon .coming-soon-text h2 {
  color: white;
  font-size: 32px;
  font-weight: 500;
  margin: 0 0 12px;
  width: 100%;
}
.coming-soon .coming-soon-text p {
  color: rgba(var(--white), 0.6);
  width: 60%;
  margin-inline: auto;
}

.coming-soon-social {
  margin-top: calc(12px + 18 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(10px + 6 * (100vw - 320px) / 1600);
  width: 100%;
}

@media (max-width: 1399px) {
  .coming-soon .coming-soon-logo .logo-img {
    width: 130px;
  }
  .coming-soon .coming-soon-text .h2 {
    font-size: 24px;
  }
  .coming-soon .coming-soon-text h2 {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .coming-soon .coming-soon-text p {
    width: 100%;
  }
}
.notify-section {
  margin-top: calc(15px + 15 * (100vw - 320px) / 1600);
  position: relative;
  width: 50%;
  margin-inline: auto;
}
@media (max-width: 768px) {
  .notify-section {
    width: 100%;
  }
}
.notify-section .custom-group {
  position: relative;
}
.notify-section .form-control {
  border-radius: 9px;
  height: calc(50px + 10 * (100vw - 320px) / 1600);
  padding: calc(6px + 4 * (100vw - 320px) / 1600) calc(6px + 4 * (100vw - 320px) / 1600) calc(6px + 4 * (100vw - 320px) / 1600) calc(10px + 10 * (100vw - 320px) / 1600);
  background-color: white !important;
  border: none;
}
.notify-section .form-control:focus {
  box-shadow: none;
  border: none;
}
.notify-section .btn {
  border: 1px solid rgba(var(--theme-color), 1);
  border-radius: 5px;
  padding: 8px 30px;
  position: absolute;
  right: 10px;
  text-transform: capitalize;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(var(--theme-color), 1);
  color: white;
}
.notify-section .btn:hover {
  border: 1px solid rgba(var(--theme-color), 1);
}

@media (max-width: 1399.98px) {
  .notify-section .btn {
    padding: 8px 20px;
  }
}
.social-link {
  background-color: rgba(var(--box-bg), 1);
  border-radius: 5px;
  display: inline-block;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, 0.1) 100%), rgba(226, 232, 255, 0.06);
  border: 1px solid rgba(238, 238, 238, 0.2);
}
.social-link i {
  color: rgba(var(--white), 1);
}
.social-link:hover {
  color: rgba(var(--theme-color), 1);
}

.error-section .banner-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  padding: calc(30px + 70 * (100vw - 320px) / 1600) 0;
}
.error-section .banner-content .banner-img {
  width: calc(250px + 350 * (100vw - 320px) / 1600);
  height: auto;
}
.error-section .banner-content p {
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
  margin: 0 auto;
  width: 70%;
}
@media (max-width: 992px) {
  .error-section .banner-content p {
    width: 100%;
  }
}

.absolute-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  animation: zoom 20s linear infinite alternate;
  background-image: url("../images/coming-soon.jpeg");
}
@media screen and (max-width: 450px) {
  .absolute-bg {
    z-index: 0;
    height: 660px;
    width: 100%;
    transform: translate(-55%, 0%) scale(1);
    background-position: 50%;
  }
}

.fog {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #000;
  overflow: hidden;
}
.fog .fog-container {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
}
.fog .fog-container .fog-img {
  position: absolute;
  height: 200vh;
  width: 100%;
}
.fog .fog-container .fog-img.fog-img-first {
  background: url("../images/smoke.png") no-repeat;
  animation: smoke 6s linear 1s infinite alternate;
  left: 24%;
  top: 30%;
  opacity: 0.3;
}

.copyright-box {
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: 1;
  text-align: center;
  width: 100%;
}
.copyright-box p {
  color: rgba(var(--white), 1);
}

/* themes */
/*=====================
  6.1  Dark style start
==========================*/
body.dark.noice-background {
  background: rgba(var(--white), 1) !important;
}
body.dark.noice-background::before {
  background-color: rgb(60, 60, 60);
}
body.dark.noice-background .header-light .logo {
  filter: invert(1);
}
body.dark .navbar-menu {
  border-top: 1px solid rgba(var(--light-text), 0.2);
}
body.dark .location .location-img {
  filter: invert(0.85);
}
body.dark .line-effect .container .row > div:after {
  background: #252525;
}
body.dark .categories .food-categories {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .home-wrapper .search-section .dropdown .place-btn {
  color: rgba(var(--content-color), 1) !important;
}
body.dark .home-wrapper .search-section .dropdown .dropdown-menu {
  background-color: rgb(17, 17, 17) !important;
}
body.dark .home-wrapper .search-section .dropdown .dropdown-menu li .dropdown-item {
  color: rgba(var(--white), 1);
}
body.dark .mega-menu .dropdown-menu .demo-box img {
  border: calc(8px + 4 * (100vw - 991px) / 929) solid #1e1e1e;
}
body.dark .products .product-box {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  border: none;
  margin: 1px;
  z-index: 0;
}
body.dark .products .product-box::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(161.57deg, rgba(255, 255, 255, 0.2) -44.12%, rgba(255, 255, 255, 0) 99.26%);
  -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(var(--white), 1))) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(var(--white), 1)));
  -webkit-mask: linear-gradient(rgba(var(--white), 1) 0 0) content-box, linear-gradient(rgba(var(--white), 1) 0 0);
  -webkit-mask-composite: xor;
  z-index: -1;
}
body.dark .login-section .login-data .food-side-bg {
  mix-blend-mode: hue;
}
body.dark .search-filter .footer-modal {
  border: 1px solid rgba(var(--light-text), 0.5);
}
body.dark .btn-close {
  filter: invert(1);
}
body.dark .form-check-input {
  background-color: transparent;
  border: 1px solid rgba(var(--light-text), 1);
}
body.dark .form-control {
  background-color: rgba(var(--box-bg), 1);
  color: rgba(var(--light-text), 1);
}
body.dark .bill-details .total-detail .dots-design {
  filter: invert(0.85);
}
body.dark .address-list li .address-icon {
  border: 1px solid rgba(var(--light-text), 1);
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .address-list li .address-content {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .modal .modal-dialog .modal-content {
  background-color: rgba(var(--white), 1);
}
body.dark .order-tracking {
  background-color: rgba(var(--box-bg), 1);
}
body.dark .coupon-box .coupon-left {
  filter: invert(0.85);
}
body.dark .coupon-box .coupon-right {
  filter: invert(0.79);
}
body.dark .voucher-box .voucher-discount .top-vector {
  filter: invert(0.85);
}
body.dark .voucher-box::after {
  box-shadow: none;
}
body.dark .voucher-box::before {
  box-shadow: none;
}
body.dark .payment-list .payment-card-box .payment-detail .add-img .img1 {
  width: 35px;
  height: 30px;
  filter: invert(1);
}
body.dark .confirm-title .for-dark {
  display: block;
}
body.dark .confirm-title .for-light {
  display: none;
}
body.dark .profile-list li .profile-box {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
}
body.dark .profile-list li .profile-box .profile-icon {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .profile-list li .profile-box .profile-icon .icon {
  filter: invert(1);
}
body.dark .otp-form .form-input .form-control {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .search-section .form-group .filter-button {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .auth-form.search-head .form-group .form-input .form-control.search {
  color: #fff;
}
body.dark .auth-form.search-head .form-group .form-input .form-control.search::placeholder {
  color: rgb(136, 136, 136);
}
body.dark .team-box {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  border: 1px solid rgba(var(--dashed-line), 1);
  transition: 0.4s ease;
}
body.dark .team-box:hover {
  border-color: rgba(var(--theme-color), 1);
}
body.dark .dark-border-gradient {
  border: 1px solid transparent;
  position: relative;
  padding: 1px;
  border-radius: 6px;
}
body.dark .dark-border-gradient .form-control,
body.dark .dark-border-gradient .dropdown-toggle {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  z-index: 1;
  position: relative;
}
body.dark .dark-border-gradient::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 6px;
  padding: 1px;
  background: linear-gradient(161.57deg, rgba(255, 255, 255, 0.53) -44.12%, rgba(255, 255, 255, 0) 99.26%);
  -webkit-mask: linear-gradient(rgba(var(--white), 1) 0 0) content-box, linear-gradient(rgba(var(--white), 1) 0 0);
  -webkit-mask-composite: xor;
}
body.dark .contact-form {
  background-color: rgba(var(--white), 1);
}
body.dark .vertical-product-box {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
}
body.dark .vertical-product-box:hover {
  border: 1px solid rgba(var(--theme-color), 1);
}
body.dark .vertical-product-box.product-style-3:hover {
  border: none;
}
body.dark .vertical-product-box.product-style-3 .vertical-product-box-img .offers img {
  filter: brightness(0.22) invert(0.02);
}
body.dark .vertical-product-box .brand-label {
  box-shadow: 2px 6px 5px #232323;
}
body.dark .process-section .process-list li .icon {
  filter: brightness(1) invert(1);
}
body.dark .process-section .process-list li.active .icon {
  color: rgba(var(--theme-color), 1);
  filter: none;
}
body.dark .process-section .process-list li.done .icon {
  color: rgba(var(--theme-color), 1);
  filter: none;
}
body.dark .layout-sec .order-summery-section .checkout-detail .dots-design {
  filter: invert(0.78);
}
body.dark .layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus {
  background-color: rgba(var(--box-bg), 1);
}
body.dark .layout-sec .order-summery-section .checkout-detail .horizontal-product-box .product-content .plus-minus input {
  background-color: rgba(var(--box-bg), 1);
}
body.dark .layout-sec .order-summery-section .checkout-detail .cart-address-box .add-img .img {
  filter: invert(0.7);
}
body.dark .cart-product-section .cart-product-list li .cart-product-box .cart-details .quantity-box .plus-minus {
  background-color: rgba(var(--box-bg), 1);
}
body.dark .cart-product-section .cart-product-list li .cart-product-box .cart-details .quantity-box .plus-minus input {
  background-color: rgba(var(--box-bg), 1);
}
body.dark .order-summery-section .checkout-detail .dots-design {
  filter: invert(0.78);
}
body.dark .coupon-box .coupon-bottom {
  filter: invert(0.75) grayscale(100);
  opacity: 0.8;
}
body.dark .payment-accordion .accordion-item .accordion-header .accordion-button::after {
  filter: brightness(0) invert(1);
}
body.dark .payment-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}
body.dark .sidebar-accordion .accordion-item .accordion-header .accordion-button::after {
  filter: brightness(0) invert(1);
}
body.dark .sidebar-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}
body.dark .help-accordion .accordion-item .accordion-header .accordion-button::after {
  filter: brightness(0) invert(1);
}
body.dark .help-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}
body.dark .help-accordion .accordion-item .accordion-body {
  color: rgba(var(--dark-text), 0.5);
}
body.dark .tab-content .tab-pane .review-section .review-box-list li {
  background: linear-gradient(149.22deg, #3c3c3c -9.22%, #2a2a2a 114.95%);
  border: 1px solid rgba(245, 245, 245, 0.06);
}
body.dark .tab-content .tab-pane .review-section .review-box-list li .review-box .review-head .rating-star li {
  background: transparent;
  border: none;
}
body.dark .top-right-header .right-list .dropdown .dropdown-toggle::after {
  filter: brightness(0) invert(1);
}
body.dark .pharmacy-categories-box:hover .categories-details h6 {
  color: rgba(var(--dark-text), 1);
}
body.dark .mode-change-button .icon {
  filter: invert(0);
}
body.dark .testimonial-section .container::after {
  background: linear-gradient(270deg, rgba(var(--white), 0) 0%, rgb(60, 60, 60) 75%);
}
body.dark .testimonial-section .container::before {
  background: linear-gradient(90deg, rgba(var(--white), 0) 0%, rgb(60, 60, 60) 75%);
}
body.dark .login-hero-section {
  background-color: rgb(32, 32, 32) !important;
}