/*========================
  5.6  coupon style start
==========================*/
.coupon-box {
  position: relative;
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  border: 1px solid rgba(var(--black), 0.12);
  background: rgba(var(--white), 1);
  border-radius: 6px;
  width: 100%;

  .coupon-img {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: rgba(var(--box-bg), 1);
    border-radius: 2px;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .coupon-name {
    display: flex;
    align-items: center;
    gap: 12px;

    img {
      border-radius: 6px;
    }

    .coupon-name-content {
      width: calc(100% - 60px - 12px);

      h5 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      h6 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }

  .bank-name {
    width: calc(100% - 54px - 12px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    h5 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h6 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .coupon-content {
    margin-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

    &.bank-offer-content {
      margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      }

      h6 {
        margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    .coupon-apply {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      border-top: 1px dashed rgba(var(--black), 0.12);

      .copy-btn {
        padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1;
        font-size: 14px;

        @media screen and (max-width: 1336px) and (min-width: 1399px) {
          padding: 7px;
        }
      }
    }

    p {
      padding: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))) 0 calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 400;
      color: rgba(var(--content-color), 1);
    }
  }

  .coupon-bottom {
    position: absolute;
    width: 95%;
    left: 10px;
    bottom: -1px;

    [dir="rtl"] & {
      left: unset;
      right: 10px;
    }
  }

  .coupon-right {
    position: absolute;
    right: -1px;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;

    [dir="rtl"] & {
      right: unset;
      left: -2px;
      transform: scaleX(-1) translateY(-50%);
    }
  }
}

.deal-box {
  padding: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
  border: 1px dashed rgba(var(--white), 0.1);
  border-radius: 18px;
  background-color: rgba(var(--box-bg), 1);
  transition: all 0.5s ease;
  margin-top: 20px;

  .deal-img {
    img {
      border-radius: 16px 16px;
    }
  }

  .deal-content {
    text-align: center;
  }

  h3 {
    font-size: calc(18px + (27 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--theme-color), 1);
    margin-top: calc(8px + (13 - 8) * ((100vw - 320px) / (1920 - 320)));
    @include gradient-text;
        font-weight: 600;
  }

  h6 {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--content-color), 0.8);
    font-weight: 400;
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.8px;
  }

  h5 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #999;
    margin-top: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--dark-text), 1);
    font-weight: 500;
  }

  .coupon-code {
    display: block;
    border: rgba(var(--theme-color), 0.12);
    background: linear-gradient(to right,
        rgba(var(--theme-color), 0.1),
        rgba(var(--theme-color2), 0.1));
    border-radius: 10px;
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 5px;

    span {
      text-transform: uppercase;
      letter-spacing: 0.5em;
      font-weight: 600;
      @include gradient-text;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .brand-logo {
    width: calc(65px + (80 - 65) * ((100vw - 320px) / (1920 - 320)));
    height: calc(65px + (80 - 65) * ((100vw - 320px) / (1920 - 320)));
    padding: 6px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
    margin-top: -50px;
    position: relative;
    background-color: rgba(var(--white), 1);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &:hover {
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.11);
  }
}