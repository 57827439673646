/*=====================
  5.1  about style start
==========================*/
.about-image-part {
  border-top: 1px solid rgba(var(--dashed-line), 1);
  border-bottom: 1px solid rgba(var(--dashed-line), 1);
  transition: 0.4s ease;

  @media (max-width: 991px) {
    border-bottom: none;
    border-top: none;
  }

  .about-images {
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) 0;

    @media (max-width: 991px) {
      padding-block: 0;
    }

    .img {
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
      object-fit: cover;
    }
  }
}

.about-images.h-100 {
  img {
    object-fit: cover;
  }
}

.about-main-img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover;

  @media (max-width: 1199px) {
    display: none;
  }
}

.service-box-section {
  background-color: rgba(var(--box-bg), 1);

  .service-box {
    position: relative;
    padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    background: rgba(var(--white), 1);
    border-radius: 2px;
    transition: 0.4s ease;

    .dot {
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50px;
      right: 10px;
      background-color: rgba(var(--theme-color), 1);
      border-radius: 100%;

      [dir="rtl"] & {
        left: 10px;
        right: unset;
      }

      &::after {
        content: "";
        position: absolute;
        border: 1px dashed rgba(var(--theme-color), 1);
        width: 50px;
        top: 4px;
      }

      @media (max-width: 1199px) {
        display: none;
      }
    }

    &:hover {
      background: $gradient-fill;
      transition: 0.4s ease;

      &::before {
        border: 1px solid rgba(var(--theme-color), 1);
      }

      .service-name {
        color: #fff;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
          height: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
          border: 1px solid #fff;

          [dir="rtl"] & {
            left: unset;
            right: 0;
          }
        }
      }

      .service-details {
        color: rgba(255, 255, 255, 0.9);
      }
    }

    .service-img {
      width: 55px;
      height: 55px;
      background-color: rgba(var(--box-bg), 1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;

      .icon {
        width: 38px;
        height: 38px;
      }
    }

    .service-name {
      position: relative;
      margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      padding-left: 10px;
      font-weight: 500;
      color: rgba(var(--dark-text), 1);

      [dir="rtl"] & {
        padding-left: unset;
        padding-right: 10px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        height: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
        border: 1px solid rgba(var(--theme-color), 1);

        [dir="rtl"] & {
          left: unset;
          right: 0;
        }
      }
    }

    .service-details {
      margin-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 400;
      color: rgba(var(--content-color), 1);
    }
  }
}

.team-box {
  padding: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(var(--white), 1);
  height: 100%;
  transition: 0.4s ease;

  &:hover {
    border: 1px solid rgba(var(--theme-color), 1);
  }

  .member-image {
    .img {
      width: 100%;
      border-radius: 2px;
    }
  }

  .member-details {
    .member-name {
      margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    }

    .social-icon {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

      li {
        transition: 0.4s ease;

        .icon {
          height: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
          width: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: calc(13px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
          color: rgba(var(--dark-text), 0.5);
          background: rgba(0, 0, 0, 0.1);
          border-radius: 100%;
          transition: 0.4s ease;
        }

        &:hover {
          transform: translateY(-10px);

          .icon {
            color: #fff;
            background: $gradient-fill;
          }
        }
      }
    }
  }
}