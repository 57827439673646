/*========================
  5.11  product detail style start
==========================*/
.product-banner-section {
  background-image: url("../images/background/home-bg.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: calc(60px + (109 - 60) * ((100vw - 320px) / (1920 - 320))) 0
    calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));

  @media (max-width: 600px) {
    background-position: right;
  }

  .restaurant-box {
    position: relative;
    padding: 0;
    backdrop-filter: blur(6px);
    display: flex;
    gap: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-top: calc(30px + (75 - 30) * ((100vw - 320px) / (1920 - 320)));

    .restaurant-image {
      @media (max-width: 576px) {
        display: none;
      }

      .img {
        width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
        height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 4px;
      }
    }

    .restaurant-details {
      width: calc(
        100% - calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320))) -
          calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)))
      );

      @media (max-width: 576px) {
        width: 100%;
      }

      .restaurant-name {
        color: #fff;
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
      }

      .restaurant-place {
        position: relative;
        color: rgba(255, 255, 255, 0.8);
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-top: calc(6px + (13 - 6) * ((100vw - 320px) / (1920 - 320)));
      }

      .categories-icon {
        position: relative;
        display: flex;
        align-items: center;
        gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        justify-content: flex-end;

        @media (max-width: 991px) {
          justify-content: flex-start;
        }

        a {
          line-height: 1;
        }

        .icon {
          font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
        }

        .like-btn {
          position: relative;

          .effect-group {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .effect {
              display: block;
              position: absolute;
              top: 38%;
              left: 50%;
              width: 20px;
              transform-origin: 0px 2px;

              &:nth-child(2) {
                transform: rotate(72deg);
              }

              &:nth-child(3) {
                transform: rotate(144deg);
              }

              &:nth-child(4) {
                transform: rotate(216deg);
              }

              &:nth-child(5) {
                transform: rotate(288deg);
              }

              &::before {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                border-radius: 2px;
                height: 3px;
                background: #777777;
              }

              &::after {
                content: "";
                display: block;
                position: absolute;
                top: 10px;
                right: 10%;
                border-radius: 50%;
                width: 3px;
                height: 3px;
                background: rgba(var(--theme-color), 1);
                transform: scale(0, 0);
              }
            }
          }

          &.active {
            .outline-icon {
              display: none;
            }

            .fill-icon {
              display: flex;
              color: rgba(var(--error-color), 1);
              font-size: calc(
                18px + (25 - 18) * ((100vw - 320px) / (1920 - 320))
              );
            }

            .effect:before {
              animation: fireworkLine 0.5s linear 0.1s;
            }

            .effect:after {
              animation: fireworkLine 0.5s linear 0.1s;
            }
          }

          .outline-icon {
            display: flex;
            font-size: calc(
              18px + (25 - 18) * ((100vw - 320px) / (1920 - 320))
            );
            color: rgba(var(--error-color), 1);
          }

          .fill-icon {
            display: none;
          }
        }
      }

      .food-categories {
        color: rgba(255, 255, 255, 0.8);
      }

      .restaurant-description {
        @media (max-width: 991px) {
          display: flex;
          gap: calc(12px + (25 - 12) * ((100vw - 320px) / (767 - 320)));
          align-items: center;
        }

        .distance {
          padding-top: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));

          @media (max-width: 991px) {
            padding-top: 0;
          }

          .shop-time {
            display: flex;
            align-items: center;
            gap: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
            font-size: calc(
              16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))
            );

            &::after {
              content: "";
              position: relative;
              border-left: 1px solid rgba(255, 255, 255, 0.2);
              height: 20px;
              display: inline-block;
              padding-right: calc(
                8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))
              );

              [dir="rtl"] & {
                padding-right: 0;
                padding-left: calc(
                  8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))
                );
                border-right: 1px solid rgba(255, 255, 255, 0.2);
                border-left: 0;
              }
            }
          }

          .rating-star {
            display: flex;
            align-items: center;
            gap: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
            color: #fff;
            font-size: calc(
              16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))
            );

            .star {
              width: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
              height: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(var(--success-color), 1);
              border-radius: 100%;

              i {
                font-size: calc(
                  10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))
                );
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.tab-details-section {
  position: relative;
  padding-top: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));

  .menu-button {
    position: fixed;
    bottom: calc(65px + (75 - 65) * ((100vw - 320px) / (991 - 320)));
    left: calc(20px + (50 - 20) * ((100vw - 320px) / (991 - 320)));
    z-index: 3;
    padding: 10px;
    background: rgba(var(--dark-text), 1);
    border-radius: 10px;

    [dir="rtl"] & {
      left: unset;
      right: calc(20px + (50 - 20) * ((100vw - 320px) / (991 - 320)));
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      color: rgba(var(--white), 1);

      i {
        line-height: 1;
        font-size: 16px;
      }
    }
  }
}

.product-details-content {
  .order-summery-section {
    @media (max-width: 991px) {
      display: none;
    }

    margin-top: 0;

    &.sticky-top {
      top: 30px;
    }

    .checkout-detail {
      position: relative;
      background-color: rgba(var(--white), 1);
      padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)))
        calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
        calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 0;

      ul {
        li {
          width: 100%;
        }
      }

      .checkout-title {
        font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      }

      .cart-address-box {
        display: flex;
        gap: 10px;
        margin-bottom: calc(
          20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))
        );
        padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        background: rgba(var(--white), 1);
        border: 1px solid rgba(var(--black), 0.05);
        border-radius: 6px;

        .add-img {
          .img {
            width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
            height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 4px;
          }
        }

        .add-content {
          width: calc(
            100% - calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320))) -
              10px
          );
          position: relative;

          .deliver-place {
            font-size: calc(
              13px + (16 - 13) * ((100vw - 320px) / (1920 - 320))
            );
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .change-add {
            font-size: calc(
              13px + (16 - 13) * ((100vw - 320px) / (1920 - 320))
            );
            color: rgba(var(--theme-color), 1);
          }

          .address {
            width: 100%;
            line-height: 1.2;
            font-size: calc(
              13px + (16 - 13) * ((100vw - 320px) / (1920 - 320))
            );
          }
        }
      }

      .checkout-title {
        padding-bottom: calc(
          10px + (20 - 10) * ((100vw - 991px) / (1920 - 991))
        );
      }

      .horizontal-product-box {
        display: flex;
        padding: calc(10px + (20 - 10) * ((100vw - 991px) / (1920 - 991))) 0;
        background-color: rgba(var(--white), 1);
        border-top: 1px solid rgba(var(--dark-text), 0.15);

        @media (max-width: 385px) {
          display: block;
        }

        .product-img {
          .img {
            width: calc(100px + (130 - 100) * ((100vw - 320px) / (1920 - 320)));
            height: calc(
              100px + (130 - 100) * ((100vw - 320px) / (1920 - 320))
            );
            object-fit: cover;
            border-radius: 4px;

            @media (max-width: 385px) {
              width: 100%;
            }
          }
        }

        .product-content {
          width: 100%;
          position: relative;

          @media (max-width: 385px) {
            width: 100%;
            margin-top: 10px;
          }

          h5 {
            color: rgba(var(--dark-text), 1);
            font-weight: 600;
            max-width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: calc(
              15px + (17 - 15) * ((100vw - 991px) / (1920 - 991))
            );
          }

          h6 {
            color: rgba(var(--content-color), 1);
            font-weight: 400;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.ingredients-text {
              width: 70%;
              font-size: calc(
                14px + (16 - 14) * ((100vw - 600px) / (1920 - 600))
              );
            }
          }

          .place {
            position: relative;
            padding-left: 12px;
            color: rgba(var(--content-color), 1);
            border-bottom: none;

            [dir="rtl"] & {
              padding-left: unset;
              padding-right: 12px;
            }

            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 5px;
              height: 5px;
              background-color: rgba(var(--content-color), 1);
              border-radius: 100%;

              [dir="rtl"] & {
                left: unset;
                right: -5px;
              }
            }
          }

          .plus-minus {
            border: 1px solid rgba(var(--dashed-line), 1);
            padding: calc(3px + (5 - 3) * ((100vw - 991px) / (1920 - 991)));
            border-radius: 6px;
            display: flex;
            align-items: center;
            background-color: rgba(var(--box-bg), 1);
            text-align: center;

            input {
              background-color: rgba(var(--box-bg), 1);
              color: rgba(var(--theme-color), 1);
              border: none;
              font-size: 14px;
              outline: none;
              width: calc(15px + (35 - 15) * ((100vw - 320px) / (1920 - 320)));
              text-align: center;
            }

            i {
              color: rgba(var(--dark-text), 1);
              cursor: pointer;

              &.sub {
                padding-right: 5px;
                border-right: 1px solid rgba(var(--dashed-line), 1);

                [dir="rtl"] & {
                  padding-right: unset;
                  padding-left: 5px;
                  border-left: 1px solid rgba(var(--dashed-line), 1);
                  border-right: unset;
                }
              }

              &.add {
                padding-left: 5px;
                border-left: 1px solid rgba(var(--dashed-line), 1);

                [dir="rtl"] & {
                  padding-left: unset;
                  padding-right: 5px;
                  border-right: 1px solid rgba(var(--dashed-line), 1);
                  border-left: unset;
                }
              }
            }
          }

          .product-price {
            font-weight: 600;
            color: rgba(var(--dark-text), 1);
          }
        }
      }

      .sub-total {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        &.total-amount {
          font-weight: 500;
          padding-top: calc(
            10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))
          );
          border-top: 1px dashed rgba(var(--dark-text), 0.15);
        }

        p {
          font-size: 13px;
          font-weight: 400;
          color: rgba(var(--content-color), 1);
        }
      }

      h5 {
        color: rgba(var(--dark-text), 1);
      }

      .delivery-info {
        width: 70%;
        margin-bottom: 15px;
        line-height: 1.2;
      }

      .grand-total {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(var(--dark-text), 0.15);
        padding: 10px 0 0;

        h5 {
          color: rgba(var(--dark-text), 1);
        }

        .amount {
          color: rgba(var(--success-color), 1);
        }
      }

      .restaurant-btn {
        padding: calc(10px + (15 - 10) * ((100vw - 991px) / (1920 - 991))) 0;
        margin-top: calc(10px + (20 - 10) * ((100vw - 991px) / (1920 - 991)));
        text-transform: uppercase;
      }

      .dots-design {
        position: absolute;
        bottom: -1px;
        width: 105%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.product-sidebar {
  position: sticky;
  padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
  background-color: rgba(var(--white), 1);
  z-index: 1;

  &.sticky-top {
    top: 30px;

    @media (max-width: 991px) {
      top: unset;
      bottom: 80px;
      left: calc(20px + (50 - 20) * ((100vw - 320px) / (991 - 320)));

      [dir="rtl"] & {
        left: unset;
        right: calc(20px + (50 - 20) * ((100vw - 320px) / (991 - 320)));
      }
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
    width: 80%;
    height: 350px;

    @media (max-width: 992px) and (min-width: 650px) {
      width: 40%;
    }

    .product-items {
      padding-top: 0;
    }
  }

  @media (max-width: 991px) {
    position: fixed;
    left: 60px;
    bottom: 60px;
    top: unset;
    width: 0;
    height: 0;
    display: block;
    padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--white), 1);
    transition: 0.4s ease;
    border-radius: 10px;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    overflow: auto;

    [dir="rtl"] & {
      left: unset;
      right: 60px;
    }

    .sidebar-search {
      display: none;
    }
  }

  .sidebar-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(var(--dark-text), 0.2);

    a {
      color: rgba(var(--content-color), 1);
    }
  }

  .sidebar-search {
    position: relative;

    input {
      width: 100%;
      background-color: rgba(var(--box-bg), 1);
      box-shadow: none;
      border: none;
      padding: 10px 16px;
      outline: none;
    }

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      color: #a0a0a0;
      z-index: 1;

      [dir="rtl"] & {
        right: unset;
        left: 16px;
      }
    }
  }

  .filter-content {
    padding: 20px;

    .type-list {
      margin-top: 20px;

      li {
        width: 100%;
        padding: 12px 0;
        border-bottom: 1px dashed rgba(var(--dark-text), 0.15);

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }

        .form-check-reverse {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0;

          .form-check-label {
            display: flex;
            align-items: center;
            gap: 8px;

            .img {
              width: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
            }

            h6 {
              font-size: 14px;
              font-weight: 400;
            }
          }

          .form-check-input {
            border-radius: 4px;
            width: 20px;
            height: 20px;
            border: 1px solid rgba(var(--dark-text), 0.15);

            &:focus {
              box-shadow: none;
              border-color: rgba(var(--dark-text), 0.15);
            }

            &:checked {
              background-color: rgba(var(--theme-color), 1);
              border-color: rgba(var(--theme-color), 0.15);
              background-image: none;
              width: 20px;
              height: 20px;

              &::after {
                content: "";
                font-family: remixicon !important;
                font-style: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(var(--white), 1);
              }
            }
          }
        }
      }
    }
  }

  .product-items {
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) 0;

    h6 {
      padding-bottom: 15px;
    }

    .nav-pills {
      display: block;

      &.sub-nav-pills {
        padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        width: 100%;

        li {
          margin-bottom: 0;

          a {
            color: rgba(var(--content-color), 0.9);
          }

          + li {
            margin-top: 5px;
          }
        }

        [dir="rtl"] & {
          padding-left: unset;
          padding-right: 20px;
        }
      }

      li {
        display: block;
        margin-bottom: calc(6px + (10 - 6) * ((100vw - 991px) / (1920 - 991)));

        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
        }
      }

      .nav-link {
        position: relative;
        color: rgba(var(--dark-text), 1);
        border-radius: 0;
        width: 100%;
        padding: 0;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

        &.active {
          margin-right: auto;
          color: rgba(var(--theme-color), 1);
          background-color: transparent;
          border-radius: 0;
          font-weight: 500;

          &::after {
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            right: calc(-14px + (-20 - -14) * ((100vw - 991px) / (1920 - 991)));
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(var(--theme-color), 1);

            [dir="rtl"] & {
              right: unset;
              left: calc(
                -14px + (-20 - -14) * ((100vw - 991px) / (1920 - 991))
              );
            }
          }
        }
      }
    }
  }
}

.product-box-section {
  height: 100%;
  margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  background-color: rgba(var(--white), 1);

  .product-details-box-list {
    .product-details-box-title {
      padding-top: 10px;
      font-size: calc(16px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      color: rgba(var(--dark-text), 1);
    }

    .product-details-box {
      width: 100%;
      padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
      border-bottom: 1px solid rgba(var(--dashed-line), 1);
      position: relative;
      display: flex;
      gap: 15px;

      .product-img {
        .img {
          width: calc(100px + (150 - 100) * ((100vw - 320px) / (1920 - 320)));
          height: calc(100px + (150 - 100) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 7px;
          object-fit: cover;
        }
      }

      .product-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: calc(
          100% - calc(100px + (150 - 100) * ((100vw - 320px) / (1920 - 320))) -
            15px
        );

        .description {
          @media (max-width: 425px) {
            flex-wrap: wrap;
          }

          > div {
            &:first-child {
              width: 73%;

              @media (max-width: 425px) {
                width: 100%;
              }
            }
          }

          @media (max-width: 420px) {
            display: block !important;
          }

          .product-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 600;
            color: rgba(var(--dark-text), 1);
          }

          h2 {
            span {
              font-size: calc(
                14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))
              );
              font-weight: 400;
              color: rgba(var(--content-color), 1);
            }
          }

          .customized {
            display: list-item;
            list-style-type: disc;
            margin-left: 25px;
            color: rgba(var(--content-color), 1);

            [dir="rtl"] & {
              margin-left: unset;
              margin-right: 25px;
            }

            &::marker {
              color: rgba(var(--content-color), 1);
            }

            @media (max-width: 767px) {
              display: none;
            }
          }

          .rating-section {
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
            border-bottom: 1px dashed rgba(var(--dashed-line), 1);
            margin-top: 5px;

            @media (max-width: 425px) {
              padding-bottom: 0;
              border-bottom: none;
            }

            .rating-star {
              display: flex;
              align-items: center;
              gap: 1px;

              @media (max-width: 450px) {
                width: fit-content;
              }

              li {
                padding: 0;
                border-bottom: none;
                line-height: 1;

                .star {
                  color: rgba(var(--rate-color), 1);
                  font-size: calc(
                    14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))
                  );
                  line-height: 1;
                }
              }
            }

            .rating-amount {
              color: rgba(var(--content-color), 1);
              font-size: 16px;

              @media (max-width: 350px) {
                display: none;
              }
            }
          }

          p {
            width: 100%;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))
            );
            font-weight: 400;
            padding-top: 10px;
            color: rgba(var(--content-color), 0.7);
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @media (max-width: 1200px) {
              width: 100%;
            }

            @media (max-width: 425px) {
              display: none;
            }
          }

          .product-box-price {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            text-align: center;

            h2 {
              font-size: calc(
                20px + (24 - 20) * ((100vw - 320px) / (1920 - 320))
              );
            }

            @media (max-width: 425px) {
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              height: unset;
              width: 100%;
              margin-top: 5px;
            }

            h2 {
              span {
                font-size: calc(
                  14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))
                );
                font-weight: 400;
                color: rgba(var(--content-color), 1);
              }
            }

            .add-btn {
              border-radius: 10px;
              padding: calc(5px + (9 - 5) * ((100vw - 320px) / (1920 - 320)))
                calc(14px + (27 - 14) * ((100vw - 320px) / (1920 - 320)));
              margin-left: auto;

              [dir="rtl"] & {
                margin-left: 0;
                margin-right: auto;
              }
            }

            .plus-minus {
              border-radius: 6px;
              border: 1px solid rgba(var(--theme-color), 1);
              padding: 6px 17px;
              display: inline-flex;
              align-items: center;
              background-color: rgba(var(--box-bg), 1);
              text-align: center;
              position: absolute;
              transform: translateX(-50%);
              left: 50%;
              bottom: -15px;
              display: none;

              input {
                background-color: rgba(var(--white), 1);
                color: rgba(var(--theme-color), 1);
                border: none;
                font-size: 14px;
                outline: none;
                width: 35px;
                text-align: center;
              }

              i {
                color: rgba(var(--theme-color), 1);
              }
            }
          }
        }
      }
    }
  }
}

.photos-section {
  height: 100%;
  margin-top: 30px;
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  background-color: rgba(var(--white), 1);

  h5 {
    font-weight: 600;
    padding-bottom: 5px;
    color: rgba(var(--dark-text), 1);
    border-bottom: 1px solid rgba(var(--dark-text), 0.07);
  }

  .photos-tab {
    margin: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))) 0;

    .nav-item {
      .nav-link {
        padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
          calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }

  .photos-list {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 375px) {
      grid-template-columns: 1fr;
    }

    li {
      .restaurant-photo {
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 7px;

        @media (max-width: 375px) {
          width: 100%;
        }
      }
    }
  }
}

.review-section {
  height: 100%;
  margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  background-color: rgba(var(--white), 1);

  h5 {
    font-weight: 600;
    padding-bottom: 5px;
    color: rgba(var(--dark-text), 1);
    border-bottom: 1px solid rgba(var(--dark-text), 0.07);
  }

  .review-box-list {
    li {
      width: 100%;
      margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
      padding-bottom: 15px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      .review-box {
        .review-head {
          display: flex;
          align-items: center;
          gap: 12px;

          .reviewer-name {
            font-size: calc(
              14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))
            );
            font-weight: 600;
            color: rgba(var(--dark-text), 1);
          }

          h6 {
            font-size: calc(
              14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))
            );
            font-weight: 400;
            color: rgba(var(--content-color), 1);
          }

          .review-image {
            width: 60px;
            height: 60px;

            .img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }

          .rating-star {
            display: flex;
            align-items: center;

            li {
              padding: 0;
              margin: 0;

              .star {
                color: rgba(var(--rate-color), 1);
                font-size: 20px;
              }
            }
          }
        }

        .review-content {
          margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

          h6 {
            font-size: calc(
              14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))
            );
            font-weight: 600;
            color: rgba(var(--dark-text), 1);
          }

          p {
            font-size: calc(
              14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))
            );
            font-weight: 400;
            color: rgba(var(--content-color), 1);
            margin-top: 7px;
          }
        }
      }
    }
  }
}

.overview-section {
  height: 100%;
  margin-top: 30px;
  padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  background-color: rgba(var(--white), 1);

  h5 {
    font-weight: 600;
    padding-bottom: 5px;
    color: rgba(var(--dark-text), 1);
    border-bottom: 1px solid rgba(var(--dark-text), 0.07);
  }

  .overview-content {
    margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));

    .overview-heading {
      font-weight: 600;
      margin-bottom: 4px;
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--dark-text), 1);
    }

    .content-list {
      &.w-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
      }

      li {
        font-weight: 400;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        display: list-item;
        list-style-type: disc;
        margin-left: 20px;
        color: rgba(var(--content-color), 1);
        padding-bottom: 8px;

        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 20px;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &::marker {
          color: rgba(var(--content-color), 1);
        }

        &.content-address {
          @media (max-width: 991px) {
            width: 90%;
          }
        }
      }
    }
  }
}

.fixed-btn {
  @media (max-width: 991px) {
    position: fixed;
    width: 100%;
    max-width: 991px;
    padding-bottom: 10px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    z-index: 1;

    .cart-fixed-bottom {
      width: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      background-color: rgba(var(--theme-color), 1);
      border-radius: 10px;
      z-index: 2;
    }
  }
}
