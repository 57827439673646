/*=====================
  3.9  Ratio Style start
==========================*/
.ratio_30 {
  .bg-size {
    &:before {
      padding-top: 30%;
      content: "";
      display: block;
    }
  }
}

.ratio_40 {
  .bg-size {
    &:before {
      padding-top: 40%;
      content: "";
      display: block;
    }
  }
}

.ratio_45 {
  .bg-size {
    &:before {
      padding-top: 45%;
      content: "";
      display: block;
    }
  }
}

.ratio50 {
  .bg-size {
    &:before {
      padding-top: 50%;
      content: "";
      display: block;
    }
  }
}

.ratio_55 {
  .bg-size {
    &:before {
      padding-top: 55%;
      content: "";
      display: block;
    }
  }
}

.ratio2_3 {
  .bg-size {
    &:before {
      padding-top: 60%;
      content: "";
      display: block;
    }
  }
}

.ratio3_2 {
  .bg-size {
    &:before {
      padding-top: 66.66%;
      content: "";
      display: block;
    }
  }
}

.ratio_landscape {
  .bg-size {
    &:before {
      padding-top: 75%;
      content: "";
      display: block;
    }
  }
}

.ratio_square {
  .bg-size {
    &:before {
      padding-top: 100%;
      content: "";
      display: block;
    }
  }
}

.ratio_110 {
  .bg-size {
    &:before {
      padding-top: 110%;
      content: "";
      display: block;
    }
  }
}

.ratio_asos {
  .bg-size {
    &:before {
      padding-top: 127.7777778%;
      content: "";
      display: block;
    }
  }
}

.ratio_portrait {
  .bg-size {
    &:before {
      padding-top: 150%;
      content: "";
      display: block;
    }
  }
}

.ratio1_2 {
  .bg-size {
    &:before {
      padding-top: 200%;
      content: "";
      display: block;
    }
  }
}

.b-top {
  background-position: top !important;
}

.b-bottom {
  background-position: bottom !important;
}

.b-center {
  background-position: center !important;
}

.b-left {
  background-position: left !important;
}

.b-right {
  background-position: right !important;
}

.b_size_content {
  background-size: contain !important;
}
